import React, { useEffect } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 360
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center"
    },
    chip: {
      margin: 2
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  }));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
        }
    }
};
  
function getStyles(name, tag, theme) {
    return {
    fontWeight:
        tag.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
    };
}


function MultipleSelect(props){
    const classes = useStyles();
    const theme = useTheme();
    const choices = props.choices;
    const defaultTag = props.defaultTag;
    const projectTagStatus = props.app.store.projects.tagStatus;
    const subjectTagStatus = props.app.store.subjects.tagStatus;
    const courseTagStatus = props.app.store.courses.tagStatus;
    const type = props.type;
    const openStatus = props.openStatus;
    const labelId = "mutiple-chip-label" + type;
    const id = "mutiple-chip" + type;
    const selectId = "select-multiple-chip" + type;
    const [tag, setTag] = React.useState(defaultTag);
    
    useEffect(() => {
        if(type.includes('tag')){ setTag(defaultTag); }
        // eslint-disable-next-line
    }, [type, defaultTag]);
    
    const handleChange = (event) => {
        setTag(event.target.value);
        if(type === 'project'){
            props.app.actions.projects.setTags(event.target.value);    
        }
        else if(type === 'subject'){
            props.app.actions.subjects.setTags(event.target.value);
        }
        else if(type === 'course'){
            props.app.actions.courses.setTags(event.target.value);
        }
    };

    return(
        <div>
            <FormControl className={classes.formControl}>
                <Select
                labelId={labelId}
                id={id}
                open={openStatus}
                disableUnderline={props.disableUnderline}
                multiple
                value={type === 'project'? projectTagStatus === 'copy'?props.app.store.subjects.tags: tag:
                    type === 'subject'? subjectTagStatus === 'copy'? props.app.store.subjects.tags: tag: 
                    type === 'course'? courseTagStatus === 'copy'? props.app.store.courses.tags: tag:
                    type === 'school' || type === 'courseSubject' || type.includes('tag')? tag: []}
                onChange={(e, c)=>{handleChange(e); props.onChange(e, c);}}
                input={<Input id={selectId} />}
                renderValue={selected => (
                    <div className={classes.chips}>
                    {selected.map(value => (
                        <Chip key={value} label={value} className={classes.chip} />
                    ))}
                    </div>
                )}
                MenuProps={MenuProps}
                >
                {type === 'courseSubject'? choices.map(obj => {
                    const val = obj.title + ' - ' + obj._id.toString().substr(0, 10);
                    return (
                        <MenuItem
                        key={obj._id}
                        id={obj._id}
                        value={val}
                        style={getStyles(val, tag, theme)}
                        >
                        {val}
                        </MenuItem>
                )}): choices.map(name => (
                    <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, tag, theme)}
                    >
                    {name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>                  
        </div>
    )

}

export default MultipleSelect;