import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import webSocket from 'socket.io-client'


class MemoryGameStart extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.memoryGame = this.store.memoryGames.viewingMemoryGamePublish;
  }

  componentDidMount() {
    window.onbeforeunload = this.disConnectionBattle.bind(this);
    this.connectWebSocket();
  }

  componentWillReceiveProps(newProps){
    this.init(newProps);
    this.initWebSocket();
  }
  
  componentWillUnmount(){
    const subView = this.store.content.subView;
    if(subView !== 'memoryGameBattleRoom'){
      this.disConnectionBattle();
    }
  }

  disConnectionBattle(){
    const ws = this.store.main.webSocket;
    if(ws){
      ws.emit('disConnectionBattle', {type: 'waiting', data: {game: this.store.memoryGames.viewingMemoryGamePublish._id}, sender: this.store.user._id});
    }
  }

  board(){
    const outerStyle = {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const innerStyle = {
      width: this.bs.width * 0.25,
      height:  this.bs.height * 0.2,
      marginBottom: '10%',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      backgroundColor: 'rgba(145, 195, 59, 0.8)',
    }

    return(
      <div style={outerStyle}>
        <div style={innerStyle}>
          {this.title()}
          {this.buttonArea()}
        </div>
      </div>
    )
  }

  title(){
    const style = {
      width: '100%',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '120%',
      color: 'white',
      fontWeight: 'bold',
    }

    return (
      <div style={style}>
        {this.func.multiLang('Select mode', '選擇模式', '选择模式')}
      </div>
    )
  }

  buttonArea(){
    const areaStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 1
    }

    return (
      <div style={areaStyle}>
        {this.button(['Practice','練習','练习'], ()=>{ this.memoryGamePlay('practice', 4) })}
        {this.button(['Test','測驗','测验'], ()=>{ this.memoryGamePlay('test', this.store.memoryGames.viewingMemoryGamePublish.size) })}
        {this.button(['Battle','對戰','对战'], async()=>{  
          //await this.connectWebSocket();
          //await this.initWebSocket();
          await this.sendMessage();
        })}
      </div>
    )
  }

  changeBackground(e){
    e.target.style.background = 'rgba(255, 255, 255, 0.4)';
  }

  removeBackground(e){
    e.target.style.background = '';
  }


  button(text, onClick){
    const style = {
      width: '100%',
      height: '100%',
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      fontSize: '110%',
      color: 'white',
    }
    return(
      <div style={style} onMouseOver={(e)=>this.changeBackground(e)} onMouseLeave={(e)=>this.removeBackground(e)} onClick={onClick}>
        {this.func.multiLang(text[0], text[1], text[2])}
      </div>
    )
  }

  getStudentProfiles(profilesToShow){
    const profilesToGet = [];

    for(var i=0;i<profilesToShow.length;i++){
      if(this.func.getById.profileByUser(profilesToShow[i], this.store) === null){
        profilesToGet.splice(0,0, profilesToShow[i]);
      }
    }
    if(profilesToGet.length > 0){
      this.actions.profiles.getProfiles(profilesToGet);
    }
  }
  
  async connectWebSocket(){
    const path = this.func.isDev()? process.env.REACT_APP_SOCKET_DEV: process.env.REACT_APP_SOCKET;
    var socket = webSocket(path, {transports: ['websocket', 'polling']});
    this.actions.main.setWebSocket(socket);
  }

  async initWebSocket(){
    const ws = this.store.main.webSocket;
    if(!ws){ return null; }

    ws.on('waitingForBattle', message => {
      this.actions.memoryGames.setBattleRoomWaitingUsers(message);
      this.getStudentProfiles(message);
    });

    ws.on('requestForBattle', message => {
      const user = message.user;
      const opponent = message.opponent;
      if(this.store.user._id === opponent){
        const profile = this.func.getById.profileByUser(user, this.store);
        const msg = [profile? profile.name + ' request for battle. Do you accept?': null, profile? profile.name + ' 向你請求對戰。 你接受嗎?': null, profile? profile.name + '向你请求对战。 你接受吗?': null];
        this.actions.modal.confirm(msg, ()=>{
          ws.emit('acceptToBattle', message);
        }, () => {
          ws.emit('denyToBattle', message);
        });
      }
    });

    ws.on('acceptToBattle', message => {
      const user = message.user;
      const opponent = message.opponent;
      if(this.store.user._id === user){
        this.actions.modal.hideModal();
        this.actions.memoryGames.setBattleRoomInfo(message);
        this.memoryGamePlay('battle', this.store.memoryGames.viewingMemoryGamePublish.size);
      }
      else if(this.store.user._id === opponent){
        this.actions.memoryGames.setBattleRoomInfo(message);
        this.memoryGamePlay('battle', this.store.memoryGames.viewingMemoryGamePublish.size);
      }
    });

    ws.on('denyToBattle', message => {
      const user = message.user;
      const opponent = message.opponent;
      if(this.store.user._id === user){
        this.actions.modal.hideModal(); 
        const profile = this.func.getById.profileByUser(opponent, this.store);
        this.actions.modal.message([profile? profile.name + ' deny the battle': null, profile? profile.name + ' 拒絕對戰': null, profile? profile.name + ' 拒绝对战': null]);
      }
    });

    ws.on('failedToBattle', message => {
      if(message === 'quitBattle'){
        this.actions.modal.message(['The opponent leaves', '對手中途離開', '对手中途离开']);
      }
      else if(message === 'noResponse'){
        this.actions.modal.message(['The opponent does not response', '對手沒有回應', '对手没有回应']);
      }
      this.actions.content.setSubView('memoryGameStart');
      this.disConnectionBattle();
      this.actions.memoryGames.setBattleRoomWaitingUsers([]);
      this.actions.memoryGames.setBattleRoomInfo({});
      this.actions.memoryGames.setBattleRoomScore({});
    });

    ws.on('leaveWaitingRoom', message => {
      this.actions.memoryGames.setBattleRoomWaitingUsers(message);
    });

    ws.on('disConnectionBattle', () => {
      ws.close();
    });
  }

  async sendMessage(){
    const ws = this.store.main.webSocket;
    if(!ws){ return null; }
    ws.emit('joinToWaitingBattle', {game: this.memoryGame._id, user: this.store.user._id});
    this.actions.content.setSubView('memoryGameBattleRoom');
  }


  memoryGamePlay(mode, size){
    const outDated = this.func.outDated(this.memoryGame.endDate);
    if(outDated){ this.actions.modal.message(['The game is closed!', '遊戲已經關閉!', '游戏已经关闭!']); return; }

    const cards = this.getCardsByProjects(this.store.memoryGames.viewingMemoryGamePublish.projects);
    if(cards.length < size){ this.actions.modal.message(['No enough featured cards!!! ', '沒有足夠數量的精選卡片!!! ', '没有足够数量的精选卡片!!! ']) }
    else{
      const featuredCards = this.getFeaturedCards(cards);
      const randomCards = this.randomCards(featuredCards, size);
      const [icons, langs] = this.getIconsAndLangsFromCards(randomCards);
      const choices = this.randomChoices([...icons, ...langs]);
      this.actions.memoryGames.viewMemoryGameCards(choices);
      this.actions.memoryGames.setMemoryGameMode(mode);
      this.actions.content.setSubView('memoryGamePlay'); 
    }
  }

  getCardsByProjects(projects){
    var studentProjects = [], cardsId = [], cards = [];
    for(var i=0;i < projects.length;i++){
      const project = this.func.getById.project(projects[i], this.store);
      if(project){
        studentProjects = [...studentProjects, ...project.studentProjects];
      }
    }
    for(var j=0;j < studentProjects.length;j++){
      const studentProject = this.func.getById.studentProject(studentProjects[j], this.store);
      if(studentProject){
        cardsId = [...cardsId, ...studentProject.cards];
      }
    }
    for(var k=0;k < cardsId.length;k++){
      const card = this.func.getById.card(cardsId[k], this.store);
      if(card){
        cards.push(card);
      }
    }
    return cards;
  }

  getFeaturedCards(cards){
    return cards.filter((card, i) => card.grade === 'featured');
  }

  randomCards(cards, max){
    var randomCards = [];
    var numbers = [];
    if(cards.length < max){ max = cards.length; }
    while(max > 0){
      const rand = Math.floor(Math.random() * cards.length);
      if(!numbers.includes(rand)){
        numbers.push(rand);
        randomCards.push(cards[rand]);
        max -= 1;
      }
    }
    return randomCards;
  }

  getIconsAndLangsFromCards(cards){
    var icons = [], langs = [];
    for(var i=0;i<cards.length;i++){
      var temp = [];
      if(cards[i].icon){ icons.push({ cardId: cards[i]._id, icon: cards[i].icon }); }
      if(cards[i].langs && cards[i].langs.length > 0){
        for(var j=0;j<cards[i].langs.length;j++){
          temp.push(cards[i].langs[j]);
        }
        const randNumber = Math.floor(Math.random() * (temp.length - 1 + 1) + 0);
        const lang = this.func.getById.lang(temp[randNumber], this.store);
        langs.push({ cardId: cards[i]._id, lang: lang });
      }
    }
    return [icons, langs];
  }

  randomChoices(choices){
    return choices.map((choice, i) => { choice.flipped = false; return choice; }).sort(() => Math.random() - 0.5 );
  }


  render() {
    this.init(this.props);
    
    return(
      <div style={this.subViewStyle()}>
        {this.board()}
      </div>
    )
  }

}

export default MemoryGameStart;
