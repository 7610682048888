import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setEditComment = (editComment, index) =>{
  return {
    type: 'setEditComment',
    payload: editComment,
    index: index
  }
}

export const setEditComments = (editComments) =>{
  return {
    type: 'setEditComments',
    payload: editComments
  }
}

export const killEditCommentsItem = (index) =>{
  return {
    type: 'killEditCommentsItem',
    payload: index
  }
}

export const removeEditCommentsItem = (index) =>{
  return {
    type: 'removeEditCommentsItem',
    payload: index
  }
}


export const pushEditComments = (lang) =>{
  return {
    type: 'pushEditComments',
    payload: lang
  }
}


export const viewComment = (comment) =>{
  return {
    type: 'viewComment',
    payload: comment
  }
}


export function addComment(comment){

  const that = this;
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, addRes;

    [err, addRes] = await to(axios.post(api + '/comment/add', { data: comment }));
    if(err){actions.connectionError(dispatch); return;}

    if(addRes.data.result === 'success'){
      //dispatch({type: 'message', payload: ['Comment succeed!', '評論成功!', '评论成功!']});
      dispatch({type: 'hideModal'});
      if(that){that.getComment(addRes.data.updatedCard);}
      //dispatch({type: 'pullView'});
      dispatch({type: 'pullPreviousView'});
    }else{
      dispatch({type: 'hideModal'});
      //dispatch({type: 'message', payload: ['Comment failed! Please try again!', '評論失敗! 請再試一次!', '评论失败! 请再试一次!']});
    }

  }
}


export function getComment(card){
  return async function(dispatch){
    actions.connecting(dispatch);

      let err, getRes;

      [err, getRes] = await to(axios.post(api + '/comment/get', { data: card}));
      if(err){actions.connectionError(dispatch); return;}

      if(getRes.data.result === 'success'){
        dispatch({type: 'setEditComment', payload: [getRes.data.comments,getRes.data.users], index: 0});
        //dispatch({type: 'message', payload: ['Comment succeed!', '評論成功!', '评论成功!']});
        dispatch({type: 'hideModal'});
      }
      else{
        dispatch({type: 'hideModal'});
      }
  }
}


export function deleteComment(card){
  const that = this;
  return async function(dispatch){
    actions.connecting(dispatch);

      let err, deleteRes;

      [err, deleteRes] = await to(axios.post(api + '/comment/delete', { data: card}));
      if(err){actions.connectionError(dispatch); return;}

      if(deleteRes.data.result === 'success'){
        dispatch({type: 'hideModal'});
        if(that){that.getComment(deleteRes.data.updatedCard);}
        //dispatch({type: 'pullView'});
        dispatch({type: 'pullPreviousView'});
      }else{
        dispatch({type: 'hideModal'});
      }
  }
}
