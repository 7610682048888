import React from 'react';
import View from 'components/main/pages/home/views/View';

class ImportAccounts extends View {

  constructor(props){
    super(props);
    this.init(props);

    const view = this.store.content.view;
    this.state = {
      file: null,
      termsEng: view === 'createAccounts' ? 'Note:。- The format of upload file must be in ‘MS Excel’ format。-	File extension must be ‘.xlsx’。-	The file should only contain one worksheet and any extra worksheet will be ignored。'+
      '-	1st row of the worksheet is a heading row。-	Eligible columns are as follows:。-	Column A — Nickname of the user, e.g. Ken Chan。'+
      '-  Column B — Last name of the user(in Chinese), e.g. 陳。-	Column C — First name of the user(in Chinese), e.g. 小明。'+
      '-  Column D — Last name of the user(in English), e.g. Chan。-	Column E — First name of the user(in English), e.g. Siu Ming。'+
      '-	Column F — Id of the user, e.g. test123。-	Column G — Password of the user, it must be at least 6 characters long, e.g. 123456。'+
      '-	Column H — Type of the user, ‘course’ for creating a student account, ‘school’ for creating a teacher account, e.g. course。'+
      '-	Column I — Code, if the type is ‘course’, then input course code. if the type is ‘school’, then input school code, e.g. ZSK8T。'+
      'You can download sample Excel file by clicking button below:' :
      view === 'joinCourses' ? 'Note:。- The format of upload file must be in ‘MS Excel’ format。-	File extension must be ‘.xlsx’。-	The file should only contain one worksheet and any extra worksheet will be ignored。'+
      '-	1st row of the worksheet is a heading row。-	Eligible columns are as follows:。-	Column A — Id of the user, e.g. test123。'+
      '-	Column B — Code of the course, e.g. ZSK8T。You can download sample Excel file by clicking button below:' :
      view === 'mLangQRcode' ? 'Note:。- The format of upload file must be in ‘MS Excel’ format。-	File extension must be ‘.xlsx’。-	The file should only contain one worksheet and any extra worksheet will be ignored。'+
      '-	1st row of the worksheet is a heading row。-	Eligible columns are as follows:。-	Column A — Id of the user, e.g. test123。'+
      '-	Column B — Password of the user, it must be at least 6 characters long, e.g. 123456。You can download sample Excel file by clicking button below:' :
      view === 'accessRight'? 'Note:。- The format of upload file must be in ‘MS Excel’ format。-	File extension must be ‘.xlsx’。-	The file should only contain one worksheet and any extra worksheet will be ignored。'+
      '-	1st row of the worksheet is a heading row。-	Eligible columns are as follows:。-	Column A — Id of the user, e.g. test123。'+
      '-	Column B — Password of the user, it must be at least 6 characters long, e.g. 123456。'+
      '-	Column C — Access right of mLang, If it is "Y", access granted for the user. If it is "N", access denied for the user。'+
      '-	Column D — Access right of HomeworkEasy, If it is "Y", access granted for the user. If it is "N", access denied for the user。'+
      'You can download sample Excel file by clicking button below:':
      view === 'homeworkEasyQRcode'? 'Note:。- The format of upload file must be in ‘MS Excel’ format。-	File extension must be ‘.xlsx’。-	The file should only contain one worksheet and any extra worksheet will be ignored。'+
      '-	1st row of the worksheet is a heading row。-	Eligible columns are as follows:。-	Column A — Id of the user, e.g. test123。'+
      '-	Column B — Password of the user, it must be at least 6 characters long, e.g. 123456。You can download sample Excel file by clicking button below:':
      null
    }
    this.terms = this.state.termsEng.split('。');
  }

  board(){
    const boardStyle = {
      width: '100%',
      height: '100%',
      backgroundSize: '100% 100%',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      overflow: 'auto'
    }

    return(
      <div style={boardStyle}>
        {this.topArea()}
        {this.middleArea()}
      </div>
    )
  }

  topArea(){
    const areaStyle = {
      width: '85%',
      height: '15%',
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
      alignItems: 'flex-end',
      flexShrink: 0,
    }

    return (
      <div style={areaStyle}>
        {this.button(['Upload', '上載', '上载'], ()=>{ this.check(); })}
        {this.selectFile()}
      </div>
    )
  }

  middleArea(){
    const areaStyle = {
      width: '85%',
      height: '90%',
    }

    return (
      <div style={areaStyle}>
        {this.displayTerms()}
        {this.middleBottom()}
      </div>
    )
  }

  middleBottom(){
    const areaStyle = {
      height: '15%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexShrink: 0,
    }

    return (
      <div style={areaStyle}>
        {this.button(['Download example', '下載範例', '下载范例'], ()=>{ this.download(); })}
      </div>
    )
  }

  displayTerms(){
    const termStyle = {
      justifyContent: 'center',
      marginTop: '3%',
      fontSize: 18,
    }

    return(
      <div>
        {this.terms.map((term, i) => {
          return(
            <div style={termStyle} key={i}>
              {term}
            </div>
          )
        })}
      </div>
    )
  }

  button(text, onClick){
    const style = {
      width: '14%',
      height: '45%',
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      backgroundColor: '#91c33b',
      fontSize: '90%',
      color: 'white',
      //marginLeft: '8%'
    }
    return(
      <div style={style} onMouseOver={(e)=>this.changeBackground(e)} onMouseLeave={(e)=>this.removeBackground(e)} onClick={onClick}>
        {this.func.multiLang(text[0], text[1], text[2])}
      </div>
    )
  }

  check(){
    if(!this.state.file){ this.actions.modal.message(['Please choose a file to upload', '請選擇檔案上載', '请选择档案上载']); }
    else if(this.state.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') { this.actions.modal.message(['Please choose a correct file format', '請選擇正確檔案格式', '请选择正确档案格式']); }
    else{ this.upload(); }
  }

  upload(){
    const view = this.store.content.view;
    this.actions.modal.confirm(['Confirm to upload?', '確定要上載嗎?', '确定要上载吗?'], ()=>{
      if(view === 'createAccounts') { this.actions.user.registerByExcel(this.state.file); }
      else if(view === 'joinCourses') { this.actions.courses.joinCoursesByExcel(this.state.file); }
      else if(view === 'mLangQRcode') { 
        const now = new Date();
        const dateString = now.getDate()  + '-' + (now.getMonth()+1) + '-' + now.getFullYear();
        const id = now.getTime();
        const filename = 'QRcode_' + dateString + '_' + id + '.zip';
        this.actions.user.genAccessLinksByExcel(this.state.file, filename, this.app.functions); 
      }
      else if(view === 'homeworkEasyQRcode') { 
        const now = new Date();
        const dateString = now.getDate()  + '-' + (now.getMonth()+1) + '-' + now.getFullYear();
        const id = now.getTime();
        const filename = 'QRcode_' + dateString + '_' + id + '.zip';
        this.actions.user.genHomeWorkEasyAccessLinksByExcel(this.state.file, filename, this.app.functions); 
      }
      else if(view === 'accessRight'){ this.actions.access.updateByExcel(this.state.file); }
    }, () => {} )
  }

  download(){
    const view = this.store.content.view;
    if(view === 'createAccounts') { this.app.functions.url('upload-accounts-sample.xlsx', 'sample'); }
    else if(view === 'joinCourses') { this.app.functions.url('upload-joinCourses-sample.xlsx', 'sample'); }
    else if(view === 'mLangQRcode') { this.app.functions.url('upload-genAccessLinks-sample.xlsx', 'sample'); }
    else if(view === 'homeworkEasyQRcode'){ this.app.functions.url('upload-genHomeWorkEasyAccessLinks-sample.xlsx', 'sample'); }
    else if(view === 'accessRight'){ this.app.functions.url('upload-accessRight-sample.xlsx', 'sample'); }
  }

  selectFile(){
    return (
      <input type="file" accept=".xlsx" alt='' onChange={event=>{ this.setState({file: event.target.files[0]}) }}/>
    )
  }

  changeBackground(e){
    e.target.style.background = 'rgba(145,195,59,0.4)';
  }

  removeBackground(e){
    e.target.style.background = 'rgba(145,195,59,1)';
  }

  render() {
    this.init(this.props);

    return(
      <div style={this.viewStyle()}>
          {this.board()}
      </div>
    )
  }

}

export default ImportAccounts;
