import React from 'react';
import View from 'components/main/pages/home/views/View';

class Selection extends View {

  render() {
    this.init(this.props);
    return(
      <div style={this.viewStyle()}>
        {this.gap('10%')}
        {this.sep()}

        {this.buttons.rectGreen(['Join Course','加入班別','加入班别'], ()=>{this.actions.content.pushView('joinCourse')})}
        {this.gap('5%')}
        {this.buttons.rectGreen(['Add Course','創建班別','创建班别'], ()=>{this.actions.content.pushView('addCourse')})}

        {this.gap('10%')}
        {this.sep()}
      </div>
    )
  }



}

export default Selection;
