import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        },
    },
}));

function BasicTextFields(props) {
    const classes = useStyles();
    const type = props.type;
    const defaultValue = props.defaultValue;
    const id = 'textFiled-'+ type;
    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField id={id} defaultValue={defaultValue?defaultValue:''}/>
        </form>
    );
}

export default BasicTextFields;
