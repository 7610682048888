import React from 'react';
import View from 'components/main/pages/home/views/View';

import SubNav from 'components/main/items/SubNav';
import ImportFile from './subviews/ImportFile';
import Setting from './subviews/Setting';

class Management extends View {

  componentDidMount(){
    this.init(this.props);
    //const subView = this.store.content.subView;
    //console.log(subView);
    this.actions.content.setSubView('importFile');
  }

  subView(subView, animatedStyle){
    const app = {...this.app, ...{ animatedStyle: animatedStyle}}

    switch (subView) {
      case 'importFile':
        return <ImportFile app={app}/>;
      case 'setting':
        return <Setting app={app}/>;
      default:
        return null;
    }
  }

  managementSubNav(){
    const options = [
      {
        tag:['Import file','匯入文件','汇入文件'],
        subView: 'importFile'
      },
      {
        tag:['Setting','設定','设定'],
        subView: 'setting'
      }
    ]
    return <SubNav app={this.app} options={options} />
  }

  render(){
    this.init(this.props);
    const deadView = this.state.deadView;
    const view = this.state.view;
    return(
      <div style={this.viewStyle()}>
        {/*this.tabBar(['', '', ''])*/}
        {this.managementSubNav()}
        {this.sep()}
        {this.animatedSubView(this.subView.bind(this), deadView? deadView: view, deadView? false: true)}
      </div>
    )
  }

}

export default Management;
