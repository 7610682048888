import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

import MatchGameRow from 'components/main/items/rows/MatchGameRow';

class MatchGameList extends SubView {

  componentDidMount(){
    this.init(this.props);
    this.setListScroll('matchGameList');
    this.coursesId = this.store.courses.courses.map(course => course._id);
  }

  getCards(projectsId){
    var cardsToGet = [], cardsToShow = [];

    for(var i=0; i<projectsId.length; i++){
      const project = this.func.getById.project(projectsId[i], this.store);
      if(!project){ continue; }

      for(var j=0; j<project.studentProjects.length; j++){
        const studentProject = this.func.getById.studentProject(project.studentProjects[j], this.store);
        if(!studentProject){ continue; }
        
        cardsToShow = [...cardsToShow, ...studentProject.cards];
      }
    }

    for(var k=0; k<cardsToShow.length; k++){
      if(this.func.getById.card(cardsToShow[k], this.store) === null){
        cardsToGet.splice(0,0, cardsToShow[k]);
      }
    }
    if(cardsToGet.length > 0){
      this.actions.cards.getCards(cardsToGet);
    }
  }

  matchGameList(){
    const matchGames = this.store.matchGames.matchGamePublishes;
    const user = this.store.user;
    const view = this.store.content.view;
    if(matchGames.length === 0){
      return this.subTitle(['No Match Games','尚未有配對遊戲','尚未有配对游戏'])
    }
    
    return matchGames.slice(0).reverse().map((matchGame, i)=>{
      if(!matchGame){ return null; }
      if(user.type === 'student' && matchGame.hide){ return null; }
      if(view === 'matchGameV2List'){
        return (
          <MatchGameRow 
            onClick={()=>{
              this.actions.matchGames.viewMatchGamePublish(matchGame); 
              this.actions.content.pushView('matchGameV2');
              this.getCards(matchGame.projects);
            }} 
            app={this.app} matchGame={matchGame} key={i}
          />
        )
      }else{
        return (
          <MatchGameRow 
            onClick={()=>{
              this.actions.matchGames.viewMatchGamePublish(matchGame); 
              this.actions.content.pushView('matchGame');
              this.getCards(matchGame.projects);
            }}
            app={this.app} matchGame={matchGame} key={i}
          />
        )
      }
    })
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        <div id={'matchGameList'} onScroll={()=>{ this.onScroll('matchGameList'); }} style={{...this.bs, ...this.ui.styles.list}}>
          {this.matchGameList()}
        </div>
      </div>
    )
  }

}

export default MatchGameList;
