const mainReducer = (
  state = {
    status: 'init',

    photoUrl: null,
    photoAttr: {rotation: 0},
    photoBlob: null,

    recording: false,
    recordingText: '',
    onRecordStop: null,

    enlarger: 'off',
    enlargeImage: null,
    enlargeImageCard: null,
    enlargeText: '',
    canvas: 'off',
    canvasImage: null,
    canvasCard: null,
    colorPicker: 'off',
    color: null,

    webSocket: null,

    defaultImagePicker: 'off',
    prefabPicker: 'off',
    chosenTag: '',

    language: 'chinese',
    version: '1.5.6' //  'v1.5.6'
  }, action)=>{
  switch (action.type) {
    case 'setPrefabPicker':
      return {...state, prefabPicker: action.payload};
    case 'setDefaultImagePicker':
      return {...state, defaultImagePicker: action.payload};
    case 'setChosenTag':
      return {...state, chosenTag: action.payload};
    case 'enlargeText':
      return {...state, enlarger: 'text', enlargeText: action.payload };
    case 'enlargeImage':
      return {...state, enlarger: 'image', enlargeImage: action.payload.image,  enlargeImageCard: action.payload.card};
    case 'closeEnlarger':
      return {...state, enlarger: 'off'};
    case 'openCanvas':
      return {...state, canvas: 'image', canvasImage: action.payload.image,  canvasCard: action.payload.card};
    case 'closeCanvas':
      return {...state, canvas: 'off', canvasImage: null,  canvasCard: null};
    case 'openColorPicker':
      return {...state, colorPicker: 'on'};
    case 'setColorPicker':
      return {...state, color: action.payload};
    case 'closeColorPicker':
      return {...state, colorPicker: 'off'};
    case 'setAudioRecorder':
      return {...state, recording: action.payload.recording, recordingText: action.payload.recordingText, onRecordStop: action.payload.onRecordStop};
    case 'setStatus':
      return {...state, status: action.payload};
    case 'setLanguage':
      return {...state, language: action.payload};
    case 'setPhoto':
      if(!action.payload){ return state; }
      return {...state, photoUrl: action.payload.url, photoBlob: action.payload.blob};
    case 'setPhotoAttr':
      return {...state, photoAttr: {rotation: action.payload.rotation}};
    case 'setWebSocket':
      return {...state, webSocket: action.payload}
    default:
      return state;
  }
}

export default mainReducer;
