import React from 'react';
import UI from 'components/UI';
//import { browserHistory } from 'react-router';

import topBar from 'resources/images/general/top_bar.png';
import back_arrow from 'resources/images/buttons/buttonIcons/back_arrow.png';
import menu from 'resources/images/buttons/buttonIcons/menu.png';
//import search from 'resources/images/buttons/buttonIcons/search.png';
import floppy from 'resources/images/buttons/buttonIcons/floppy.png';
import edit from 'resources/images/buttons/buttonIcons/edit.png';
import add from 'resources/images/buttons/buttonIcons/add.png';
import exit from 'resources/images/buttons/buttonIcons/exit.png';
import rotate from 'resources/images/buttons/buttonIcons/rotate.png';
import copy from 'resources/images/buttons/buttonIcons/copy.png';
import choose_start from 'resources/images/buttons/buttonIcons/choose_start.png';
import choose_stop from 'resources/images/buttons/buttonIcons/choose_stop.png';
import trash from 'resources/images/buttons/buttonIcons/delete.png';
import recover from 'resources/images/buttons/buttonIcons/recover.png';
import share from 'resources/images/buttons/buttonIcons/share.png';
import icon_report from 'resources/images/buttons/buttonIcons/pdf_files.png';
import athena_space from 'resources/images/buttons/buttonIcons/athena_space.png';

class NavBar extends UI {

  constructor(props){
    super(props);
    this.state = {right2Icon: null, right3Icon: null, right4Icon: null, action: null}
  }

  componentDidMount(){
    this.init(this.props);
    this.initNavBar(this.props);
    for(var i=window.history.length;i<50;i++){ window.history.pushState({},'',''); }
    window.addEventListener('popstate', (event) => {
      if(!this.store.content.view.includes('Home') &&
        !this.store.main.recording &&
        this.store.main.enlarger === 'off'){
        window.history.pushState({},'','');
        this.navBack();
      }
    });
    //browserHistory.listen(location => { console.log(location); if(location.action === "POP") { this.navBack(); } });
  }

  UNSAFE_componentWillReceiveProps(newProp){
    this.init(newProp);
    this.initNavBar(newProp);

    /*
    if(this.state.action === 'updateViewProject'){
      console.log(newProp.app.store);
      const viewingProject = this.store.projects.viewingProject;
      const updatedProject = this.func.getById.project(viewingProject._id, newProp.app.store);
      console.log('viewingProject: ', viewingProject);
      console.log('updatedProject: ', updatedProject);
      this.actions.projects.viewProject(updatedProject);
      this.setState({action: null});
    }
    */

  }

  onNavClick(){
    //console.log(this.store);
    //console.log(window.history);
    //console.log(window.location);
  }

  navBack(){
    this.store.cards.deleteAction = false;
    this.store.cards.selectedDeleteCards = [];
    this.store.groups.createdByTeacher = [];
    this.actions.switches.setAnimation('row', false);
    this.store.allowedComment.editAllowedComments = []
    this.actions.projects.setProjectLangAudio({blob: null});
    this.actions.content.pullView();
    const view = this.store.content.view;
    if(view === 'course' || view === 'subject' || view === 'project'){
      const currentUrl = this.store.content.routeLinks[this.store.content.routeLinks.length - 1];
      this.actions.content.pullRouteLink();
      window.history.replaceState({}, null, currentUrl);
    }
  }

  removeAllDeleteCard(deletedCardList){
    deletedCardList.map((deletedCard, i) =>{
      this.actions.cards.removeDeleteCard(deletedCard);
      return null;
    })
  }

  removeAllRecoverCard(recoveredCardList){
    recoveredCardList.map((recoveredCard, i) =>{
      this.actions.cards.removeRecoverCard(recoveredCard);
      return null;
    })
  }

  getCards(){
    const studentProjects = this.store.studentProjects.studentProjects;
    var cardsToGet = [], cardsToShow = [];

    for(var j=0; j<studentProjects.length; j++){   
      cardsToShow = [...cardsToShow, ...studentProjects[j].cards];
    }

    for(var k=0; k<cardsToShow.length; k++){
      if(this.func.getById.card(cardsToShow[k], this.store) === null){
        cardsToGet.splice(0,0, cardsToShow[k]);
      }
    }
    if(cardsToGet.length > 0){
      this.actions.cards.getCards(cardsToGet);
    }
  }

  initNavBar(newProp){
    const view = this.store.content.view;
    const subView = this.store.content.subView;
    const user = this.store.user;
    const viewingCard = this.store.cards.viewingCard;
    //const viewingProject = this.store.projects.viewingProject;
    const viewingStudentProject = this.store.studentProjects.viewingStudentProject;
    const viewingCourse = this.store.courses.viewingCourse;
    const viewingQuestionnaire = this.store.survey.viewingQuestionnaire;
    const viewingPublish = this.store.survey.viewingPublish;


    var leftOnClick, rightOnClick, right2OnClick, right3OnClick, right4OnClick, leftIcon, rightIcon, right2Icon, right3Icon, right4Icon, title;

    leftOnClick = ()=>{ this.navBack(); }

    rightOnClick = ()=>{ this.actions.user.login(user.id, user.pw); }
    rightIcon = rotate;

    //rightOnClick = ()=>{};
    //rightOnClick = ()=>{ this.actions.modal.message(['No effect!', '沒有效果!', '没有效果!']); }

    leftIcon = back_arrow;

    /*if(view === 'account' ||  view === 'profile' ||  view === 'credit'){
      leftOnClick = ()=>{ this.actions.content.pullView(); this.actions.content.toggleMenu(); }
    }*/

    if(view.includes('Home')){
      leftOnClick = this.actions.content.toggleMenu;
      leftIcon = menu;
      rightOnClick = ()=>{ this.actions.user.login(user.id, user.pw);}
      rightIcon = rotate;
      title = ['HOME','主頁','主页'];
    }else{
      this.state.right2Icon = false;
      this.state.right3Icon = false;
      switch (view) {
        case 'account':
          title = ['ACCOUNT','帳號資訊','帐号资讯'];
          break;
        case 'forceAccount':
          title = ['WELCOME!','歡迎!','欢迎!'];
          leftOnClick = ()=>{};
          leftIcon = null;
          break;
        case 'forceAgreement':
          title = ['Terms & Conditions', '條款及細則', '条款及细则'];
          leftOnClick = ()=>{};
          leftIcon = null;
          break;
        case 'profile':
          title = ['PROFILE','個人檔案','个人档案'];
          break;
        case 'forceProfile':
          title = ['WELCOME!','歡迎!','欢迎!'];
          leftOnClick = ()=>{};
          leftIcon = null;
          break;
        case 'setting':
          title = ['SETTING','設定','设定'];
          break;
        case 'credit':
          title = ['CREDIT','鳴謝','鸣谢'];
          break;
        case 'selectActionCourse':
          title = ['SELECT', '選擇', '选择'];
          break;
        case 'addCourse':
          title = ['ADD CLASS','創建班別','创建班别'];
          break;
        case 'joinCourse':
          title = ['JOIN CLASS', '加入班別','加入班别'];
          break;
        case 'course':
          title = ['CLASS', '班別','班别'];
          if((user.type === 'developer' || user.type === 'admin') && subView === 'courseDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editCourse');
            }        
          }
          if(user.type === 'teacher' && subView === 'courseDetail' && !this.inSchool){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editCourse');
            }
          }
          if((user.type === 'teacher' || user.type === 'developer' || user.type === 'admin') && subView === 'courseSubjects' && !this.func.outDated(viewingCourse.endDate)){
            rightIcon = add;
            rightOnClick = ()=>{
              this.actions.subjects.copy(null);
              this.actions.content.pushView('addSubject');
            }
          }
          if(user.type === 'student' && subView === 'courseDetail'){
            rightIcon = exit;
            rightOnClick = ()=>{
              this.actions.modal.confirm(['Confirm to lease class?','確定退出班別?','确定退出班别?'], ()=>{
              this.actions.courses.leaveCourse({
                userId: this.store.user._id,
                code: this.store.courses.viewingCourse.code
              }); })
            }
          }
          break;
        case 'addSubject':
          title = ['ADD UNIT', '創建單元','创建单元'];
          rightIcon = copy;
          rightOnClick = ()=>{this.actions.subjects.getAllSubjectsOfSchool(this.store.schools.schools[0]);  this.actions.content.pushView('subjectList');}
          break;
        case 'subject':
          title = ['UNIT', '單元','单元'];
          if((user.type === 'developer' || user.type === 'admin') && subView === 'subjectProjects' && !this.func.outDated(viewingCourse.endDate)){
            rightIcon = add;
            rightOnClick = ()=>{this.actions.content.pushView('addProject')}
          }
          if(!this.inSchool && user.type === 'teacher' && subView === 'subjectProjects' && !this.func.outDated(viewingCourse.endDate)){
            rightIcon = add;
            rightOnClick = ()=>{
              this.actions.projects.copy(null);
              this.actions.content.pushView('addProject');
            }
          }
          if((user.type === 'developer' || user.type === 'admin') && subView === 'subjectDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editSubject');
            }
          }
          if(!this.inSchool && user.type === 'teacher' && subView === 'subjectDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editSubject');
            }
          }
          break;
        case 'addProject':
          title = ['ADD PROJECT', '創建專題研習','创建专题研习'];
          rightIcon = copy;
          rightOnClick = ()=>{this.actions.projects.getAllProjectsOfSchool(this.store.schools.schools[0]); this.actions.content.pushView('projectList'); }
          break;
        case 'project':
          title = ['PROJECT', '專題研習','专题研习'];

          if((user.type === 'developer' || user.type === 'admin') && subView === 'projectDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editProject');
            }
          }
          if(!this.inSchool && user.type === 'teacher' && subView === 'projectDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editProject');
            }
          }
          if(user.type === 'teacher' && subView === 'projectGroup'){
            rightOnClick = ()=>{ this.actions.user.login(user.id, user.pw); }
            rightIcon = rotate;
            this.state.right2Icon = true;
            right2Icon = add;
            right2OnClick = ()=>{
              this.actions.content.pushView('addGroup');
            }            
          }
  
          if(!this.inSchool && subView === 'projectGroup'){
            rightOnClick = ()=>{ this.actions.user.login(user.id, user.pw); }
            rightIcon = rotate;
          }

          if(!this.inSchool && subView === 'projectRanking'){
            rightOnClick = ()=> { this.actions.projects.getRanking(this.store.projects.viewingProject._id); };
            rightIcon = rotate;
          }
          /*if(user.type === 'teacher' && subView === 'projectFeatured' && !this.func.outDated(viewingProject.endDate)){
            rightIcon = add;
            rightOnClick = ()=>{this.actions.content.pushView('addCard')}
          }*/
          if(user.type === 'teacher' && subView === 'projectSubmitted'){
            rightOnClick = ()=>{
              this.actions.user.login(user.id, user.pw, {action: 'updateViewProject', itemId: this.store.projects.viewingProject._id});
            }
          }

          if(user.type === 'student' && subView === 'projectSubmitted'){
            this.removeAllRecoverCard(this.store.cards.selectedRecoverCards);

            if(this.store.cards.selectedDeleteCards.length > 0){
              this.state.right3Icon = true;
              right3Icon = trash;
              right3OnClick = ()=>{this.actions.modal.confirm(['Confirm to delete cards?','確定刪除卡片?','确定删除卡片?'], ()=>{
                console.log(this.store.cards.selectedDeleteCards);
                this.actions.cards.deleteCard(this.store.cards.selectedDeleteCards);
                this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);
              })}
              if(this.store.courses.viewingCourse.tags.includes('athena')){
                this.state.right4Icon = true;
                right4Icon = athena_space;
                right4OnClick = ()=>{this.actions.modal.confirm(['Confirm to submit on Athena Space?','確認提交到雅典娜e學園?','确认提交到雅典娜e学园?'], async ()=>{
                  const selectedCards = this.store.cards.selectedDeleteCards;
                  for(let i = 0; i < selectedCards.length; i++){
                    selectedCards[i].text = '';
                    selectedCards[i].audio = '';
                    for(let j = 0; j < selectedCards[i].langs.length; j++){
                    const lang = this.func.getById.lang(selectedCards[i].langs[j], this.store);
                      if(selectedCards[i].text !== '')
                        selectedCards[i].text += "\n";
                      selectedCards[i].text += lang.text;
                      if(selectedCards[i].audio === '' && lang.audio){
                        selectedCards[i].audio = lang.audio;
                      }
                  }
                }
                let outputList = [];
                let output, haveImageError = false, haveAudioError = false;
                for(let i = 0; i < selectedCards.length; i++){
                  const res = await fetch(this.store.content.cachedUrl[selectedCards[i].icon]).catch(err=>{ return null; });
                  const imageBlob = await res.blob().catch(err=>{ return null; });
                  if(i === 0){
                    if(imageBlob.size > 5242880){
                      this.actions.modal.message(["The file size of the image exceeds 5MB","圖片超過5MB" , "图片超过5MB"]);
                      this.actions.modal.showModalButton();
                      haveImageError = true;
                    }
                    if(selectedCards[i].audio !== ""){
                      const url = await this.func.url(selectedCards[i].audio, 'langAudio');
                      if(url){
                        const res = await fetch(url).catch(err=>{ return null; });
                        const blob = await res.blob().catch(err=>{ return null; });
                        if(blob.size > 5242880){
                          this.actions.modal.message(["The file size of the audio exceeds 5MB","音頻超過5MB" , "音频超过5MB"]);
                          this.actions.modal.showModalButton();
                          haveAudioError = true;
                        }
                      }
                      else
                        haveAudioError = true;
                      output = {"cardID": selectedCards[i]._id ,"projectID": this.store.projects.viewingProject._id, "subjectID": this.store.projects.viewingProject.subject, "authorID": selectedCards[i].author, "teacherID": this.store.courses.viewingCourse.teacher
                    , "text":  selectedCards[i].text, "image": selectedCards[i].icon, "audio" :  selectedCards[i].audio.slice(0, -3) + "mp3", "projectTitle": this.store.projects.viewingProject.title, "subjectTitle": this.store.subjects.viewingSubject.title , "startDate": this.store.projects.viewingProject.createdAt, "endDate": this.store.projects.viewingProject.endDate};
                    }
                    else
                      output = {"cardID": selectedCards[i]._id ,"projectID": this.store.projects.viewingProject._id, "subjectID": this.store.projects.viewingProject.subject, "authorID": selectedCards[i].author, "teacherID": this.store.courses.viewingCourse.teacher, "text":  selectedCards[i].text
                    ,"projectTitle": this.store.projects.viewingProject.title, "subjectTitle": this.store.subjects.viewingSubject.title , "startDate": this.store.projects.viewingProject.createdAt, "endDate": this.store.projects.viewingProject.endDate, "image": selectedCards[i].icon};
                    outputList.push(output);
                  }
                  else{
                    //console.log(outputList[0], " ", outputList[0].text);
                    outputList[0].text = outputList[0].text + '\n' + selectedCards[i].text;
                  }
                }
                if(new Date() > new Date(this.store.projects.viewingProject.endDate)){
                  this.actions.modal.message(["The deadline has passed","已過截止日期" , "已过截止日期"]);
                  this.actions.modal.showModalButton();
                }
                else if(haveImageError){
                  this.actions.modal.message(["The file size of the image exceeds 5MB","圖片超過5MB" , "图片超过5MB"]);
                  this.actions.modal.showModalButton();
                }
                else if(haveAudioError){
                  this.actions.modal.message(["The file size of the audio exceeds 5MB","音頻超過5MB" , "音频超过5MB"]);
                  this.actions.modal.showModalButton();
                }
                else if(!outputList[0].text || !outputList[0].text.trim()){
                  this.actions.modal.message(["There is no text", "沒有文字" , "没有文字"]);
                  this.actions.modal.showModalButton();
                }
                else
                {
                  console.log(outputList);
                  this.actions.cards.exportToAthena(outputList);
                } 
                })}
              }
            }
            else{this.state.right3Icon = false;}

            const deleteAction = this.store.cards.deleteAction;
            this.state.right2Icon = true;
            !deleteAction ? right2Icon = choose_start : right2Icon = choose_stop;
            right2OnClick = ()=>{ this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);this.actions.cards.deleteAction(!deleteAction); }
          }

          if(user.type === 'student' && subView === 'projectDeleted'){
            this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);

            if(this.store.cards.selectedRecoverCards.length > 0){
              this.state.right3Icon = true;
              right3Icon = recover;
              right3OnClick = ()=>{this.actions.modal.confirm(['Confirm to recover cards?','確定修復卡片?','确定修復卡片?'], ()=>{
                this.actions.cards.recoverCard(this.store.cards.selectedRecoverCards);
                this.removeAllRecoverCard(this.store.cards.selectedRecoverCards);
              })}
            }
            else{this.state.right3Icon = false;}

            const recoverAction = this.store.cards.recoverAction;
            this.state.right2Icon = true;
            !recoverAction ? right2Icon = choose_start : right2Icon = choose_stop;
            right2OnClick = ()=>{ this.removeAllRecoverCard(this.store.cards.selectedRecoverCards); this.actions.cards.recoverAction(!recoverAction); }
          }

          if(user.type === 'student' && subView === 'projectGroup'){
            this.removeAllRecoverCard(this.store.cards.selectedRecoverCards);
            this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);
          }

          if(user.type === 'student' && subView === 'projectFeatured'){
            this.removeAllRecoverCard(this.store.cards.selectedRecoverCards);
            this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);
          }

          if((user.type === 'teacher' || user.type === 'developer' ) && subView === 'projectFeatured'){
            this.removeAllRecoverCard(this.store.cards.selectedRecoverCards);
            if(this.store.cards.selectedDeleteCards.length > 0){
              var selected = false;
              for(var i = 0; i < this.store.cards.selectedDeleteCards.length; i++){
                if(this.store.cards.selectedDeleteCards[i].author === this.store.user._id) selected = true;
              }
              if(selected)
              {
                this.state.right3Icon = true;
                right3Icon = trash;
              }
              right3OnClick = ()=>{this.actions.modal.confirm(['Confirm to delete cards?','確定刪除卡片?','确定删除卡片?'], ()=>
              {
                var removeList = [];
                for(var i = 0; i < this.store.cards.selectedDeleteCards.length; i++){
                 if(this.store.cards.selectedDeleteCards[i].author !== this.store.user._id){
                   removeList.push(i);
                  }
                }
                for(i = 0; i < removeList.length; i++){
                  this.store.cards.selectedDeleteCards.splice(removeList[i], 1);
                  for(var j = 0; j < removeList.length; j++)
                    removeList[j] = removeList[j] - 1;
                }
                if(this.store.cards.selectedDeleteCards.length > 0){
                  this.actions.cards.deleteCard(this.store.cards.selectedDeleteCards);
                  this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);
                }
              })}
            }
            else{this.state.right3Icon = false;}

            const deleteAction = this.store.cards.deleteAction;
            this.state.right2Icon = true;
            !deleteAction ? right2Icon = choose_start : right2Icon = choose_stop;
            right2OnClick = ()=>{ this.removeAllDeleteCard(this.store.cards.selectedDeleteCards);this.actions.cards.deleteAction(!deleteAction); }
          }

          break;
        case 'addCard':
          title = ['ADD CARD', '製作卡片','制作卡片'];
          break;
        case 'viewCards':
          title = ['VIEW CARDS', '檢視卡片','检视卡片'];
          const index = viewingCard.shareToProjects? viewingCard.shareToProjects.indexOf(this.store.projects.viewingProject._id): -1;
          if(viewingCard.author === user._id && viewingCard.grade === 'featured' && user.type === 'teacher' && index < 0){
            this.state.right2Icon = true;
            right2Icon = share;
            right2OnClick = ()=>{ this.actions.content.pushView('shareCard'); }
          }
          if(this.store.user.type === 'student' && this.store.courses.viewingCourse.tags.includes('athena')){
              rightIcon = edit;
              rightOnClick = ()=>{
                this.actions.main.setPhoto({url: null, blob: null});
                this.actions.langs.setEditLangs([]);
                this.actions.content.pushView('editCard');
              }
              this.state.right2Icon = true;
              right2Icon = athena_space;
              right2OnClick = ()=>{this.actions.modal.confirm(['Confirm to submit on Athena Space?','確認提交到雅典娜e學園?','确认提交到雅典娜e学园?'], async ()=>{
                viewingCard.text = '';
                viewingCard.audio= '';

                for(let i = 0; i < viewingCard.langs.length; i++){
                  const lang = this.func.getById.lang(viewingCard.langs[i], this.store);
                  if(viewingCard.text !== '')
                    viewingCard.text += "\n";
                  viewingCard.text += lang.text;
                  if(viewingCard.audio === '' && lang.audio)
                    viewingCard.audio= lang.audio;
                }
                var output;
                let audioLarger = false, noText = false;
                const res = await fetch(this.store.content.cachedUrl[viewingCard.icon]).catch(err=>{ return null; });
                const imageBlob = await res.blob().catch(err=>{ return null; });
                console.log(imageBlob);
                if(imageBlob.size > 5242880){
                  this.actions.modal.message(["The file size of the image exceeds 5MB","圖片超過5MB" , "图片超过5MB"]);
                  this.actions.modal.showModalButton();
                }
                if(!viewingCard.text || !viewingCard.text.trim()){
                  this.actions.modal.message(["This card does not have text","沒有文字" , "没有文字"]);
                  this.actions.modal.showModalButton();
                  noText = true;
                } 
                else if(viewingCard.audio === "" ){
                  output = {"cardID": viewingCard._id ,"projectID": this.store.projects.viewingProject._id,  "subjectID": this.store.projects.viewingProject.subject, "authorID": viewingCard.author, "teacherID": this.store.courses.viewingCourse.teacher
                  , "text":  viewingCard.text, "image": viewingCard.icon, "projectTitle": this.store.projects.viewingProject.title , "subjectTitle": this.store.subjects.viewingSubject.title ,"startDate": this.store.projects.viewingProject.createdAt, "endDate": this.store.projects.viewingProject.endDate}; 
                }
                else{
                  const url = await this.func.url(viewingCard.audio, 'langAudio');
                  if(url){
                    const res = await fetch(url).catch(err=>{ return null; });
                    console.log(res);
                    const blob = await res.blob().catch(err=>{ return null; });
                    if(blob.size > 5242880){
                      this.actions.modal.message(["The file size of the audio file exceeds 5MB","音頻超過5MB" , "音频超过5MB"]);
                      this.actions.modal.showModalButton();
                      audioLarger = true;
                    }
                  }
                  output = {"cardID": viewingCard._id ,"projectID": this.store.projects.viewingProject._id, "subjectID": this.store.projects.viewingProject.subject, "authorID": viewingCard.author, "teacherID": this.store.courses.viewingCourse.teacher
                  , "text":  viewingCard.text, "image": viewingCard.icon, "audio": viewingCard.audio.slice(0, -3) + "mp3", "projectTitle": this.store.projects.viewingProject.title , "subjectTitle": this.store.subjects.viewingSubject.title , "startDate": this.store.projects.viewingProject.createdAt, "endDate": this.store.projects.viewingProject.endDate}; 
                }
                console.log(audioLarger);
                if(new Date() > new Date(this.store.projects.viewingProject.endDate))
                {
                    this.actions.modal.message(["The deadline has passed","已過截止日期" , "已过截止日期"]);
                    this.actions.modal.showModalButton();
                }
                else if(noText){
                  this.actions.modal.message(["This card does not have text","沒有文字" , "没有文字"]);
                  this.actions.modal.showModalButton();
                }
                else if(!audioLarger){
                  console.log(output);
                  this.actions.cards.exportToAthena([output]);
                }
                /*
                if(viewingCard.text !== null && viewingCard.text !== undefined && viewingCard.text !== "")
                  this.actions.cards.testApi(viewingCard._id, 234, viewingCard.audio, viewingCard.author, viewingCard.icon);
                else{
                  this.actions.modal.message(["This card does not have Chinese text","這張卡沒有中文字" , "这张卡没有中文字"]);
                  this.actions.modal.showModalButton();
                }*/
              })}
          }
          else if(viewingCard.author === user._id && (viewingCard.grade === 'notGraded' || user.type === 'teacher')){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.langs.setEditLangs([]);
              this.actions.content.pushView('editCard');
            }
          }else if(viewingCard.author === user._id && viewingCard.grade === 'failed'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.langs.setEditLangs([]);
              this.actions.content.pushView('resubmitCard');
            }
          }
          break;
        case 'gradingCards':
          title = ['GRADING CARDS', '評核卡片','评核卡片'];
          rightIcon = floppy;
          rightOnClick = this.saveGradeCard.bind(this);
          this.state.right2Icon = true;
          right2Icon = icon_report;
          console.log(this.store);
          right2OnClick = ()=>{  this.actions.content.pushView('personalPdf')};
          leftOnClick = ()=>{
            if(this.store.cards.gradingCardsEditted[viewingStudentProject._id]){
              this.actions.modal.confirm(['Save before exit?','儲存變更?','储存变更?'], ()=>{
              this.saveGradeCard();
              }, ()=>{ this.navBack(); });
            }else{
              this.navBack();
            }
          }
          break;
        case 'shareCard':
          title = ['SHARE CARD', '分享卡片', '分享卡片'];
          break;
        case 'editCourse':
          title = ['EDIT CLASS', '修改班別','修改班别'];
          break;
        case 'editSubject':
          title = ['EDIT UNIT', '修改單元','修改单元'];
          break;
        case 'editProject':
          title = ['EDIT PROJECT', '修改專題研習','修改专题研习'];
          break;
        case 'editCard':
          title = ['EDIT CARD', '修改卡片','修改卡片'];
          break;
        case 'resubmitCard':
          title = ['RESUBMIT CARD', '重新提交卡片','重新提交卡片'];
          break;
        case 'slideShow':
          title = ['SLIDESHOW', '投影片','投影片'];
          break;
        case 'student':
          title = ['STUDENT', '學生','学生'];
          break;
        case 'studentSubject':
          title = ['STUDENT(UNIT)', '學生(單元)','学生(单元)'];
          break;
        case 'studentProject':
          title = ['STUDENT(PROJECT)', '學生(專題研習)','学生(专题研习)'];
          break;
        case 'addSchool':
          title = ['ADD SCHOOL', '創建學校','创建学校'];
          break;
        case 'editSchool':
          title = ['EDIT SCHOOL', '修改學校','修改学校'];
          break;
        case 'joinSchool':
          title = ['JOIN SCHOOL', '加入學校','加入学校'];
          break;
        case 'school':
          title = ['SCHOOL', '學校','学校'];
          if(user.type === 'admin' && subView === 'schoolDetail' && this.store.schools.viewingSchool.admin === this.store.user._id){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.main.setPhoto({url: null, blob: null});
              this.actions.content.pushView('editSchool');
            }
          }
          /*if(user.type !== 'developer' && user.type !== 'admin' && subView === 'schoolDetail'){
            rightIcon = exit;
            rightOnClick = ()=>{
              this.actions.modal.confirm(['Confirm to lease school?','確定退出學校?','确定退出学校?'], ()=>{
              this.actions.schools.leaveSchool({
                userId: this.store.user._id,
                code: this.store.schools.viewingSchool.code
              }); })
            }
          }*/
          break;
        case 'teacher':
          title = ['TEACHER', '老師','老师'];
          if((user.type === 'developer' || user.type === 'admin') && subView === 'teacherCourses'){
            rightIcon = add;
            rightOnClick = ()=>{this.actions.content.pushView('selectActionCourse')}
          }
          break;
        case 'admin':
          title = ['ADMIN', '管理員','管理员'];
          break;
        case 'addAdmin':
          title = ['ADD ADMIN', '增加管理員','增加管理员'];
          break;
        case 'group':
          title = ['PROJECT - GROUP', '專題研習 - 小組','专题研习 - 小组'];
          rightOnClick = ()=>{ this.actions.user.login(user.id, user.pw); }
          rightIcon = rotate;
          if(user.type === 'teacher'){
            this.state.right2Icon = true;
            right2Icon = add;
            right2OnClick = ()=>{
              this.actions.content.pushView('addStudentToGroup');
            }
          } 
          break;
        case 'survey':
          title = ['SURVEY', '調查','调查'];
          break;
        case 'addQuestionnaire':
          title = ['ADD QUESTIONNAIRE', '製作問卷','制作问卷'];
          break;
        case 'viewQuestionnaire':
          title = ['QUESTIONNAIRE', '問卷','问卷'];
          if(viewingQuestionnaire.author === user._id){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.survey.setEditQuestions([]);
              this.actions.content.pushView('editQuestionnaire');
            }
          }
          break;
        case 'editQuestionnaire':
          title = ['EDIT QUESTIONNAIRE', '修改問卷','修改问卷'];
          break;
        case 'addPublish':
          title = ['ADD PUBLISH', '新增發佈','新增发布'];
          break;
        case 'viewPublish':
          title = ['PUBLISH', '發佈','发布'];
          if(viewingPublish.author === user._id){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.actions.content.pushView('editPublish');
            }
          }
          break;
        case 'editPublish':
          title = ['EDIT PUBLISH', '修改發佈','修改发布'];
          break;
        case 'viewSubmit':
          title = ['SUBMITTED', '提交','提交'];
          break;          
        case 'matchGameList':
          title = ['MATCH GAME', '配對遊戲', '配对游戏'];
          if(user.type === 'teacher'){
            rightIcon = add;
            rightOnClick = ()=>{
              this.getCards();
              this.actions.content.pushView('addMatchGame');
            }
          }
          break;         
        case 'matchGame':
          title = ['MATCH', '配對', '配对'];
          if(user.type === 'teacher' && subView === 'matchGameDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{ 
              this.getCards();
              this.actions.content.pushView('editMatchGame');
            }
          }
          break;
        case 'addMatchGame':
          title = ['ADD GAME', '增加遊戲', '增加游戏'];
          break;
        case 'editMatchGame':
          title = ['EDIT GAME', '修改遊戲', '修改游戏'];
          break;
        case 'memoryGameList':
          title = ['MEMORY MATCH GAME', '記憶配對遊戲', '记忆配对游戏'];
          if(user.type === 'teacher'){
            rightIcon = add;
            rightOnClick = ()=>{
              this.getCards();
              this.actions.content.pushView('addMemoryGame');
            }
          }
          break;
        case 'addMemoryGame':
          title = ['ADD GAME', '增加遊戲', '增加游戏'];
          break;
        case 'memoryGame':
          title = ['MEMORY MATCH', '記憶配對', '记忆配对'];
          if(user.type === 'teacher' && subView === 'memoryGameDetail'){
            rightIcon = edit;
            rightOnClick = ()=>{
              this.getCards();
              this.actions.content.pushView('editMemoryGame');
            }
          }
          break; 
        case 'editMemoryGame':
          title = ['EDIT GAME', '修改遊戲', '修改游戏'];
          break;
        case 'management':
          title = ['MANAGEMENT', '管理', '管理'];
          break;
        case 'createAccounts':
          title = ['CREATE ACCOUNTS', '新增帳戶', '新增帐户'];
          break;
        case 'joinCourses':
          title = ['JOIN COURSES', '加入班別', '加入班别'];
          break;
        case 'homeworkEasyQRcode':
          title = ['HomeworkEasy QR code', 'HomeworkEasy QR code', 'HomeworkEasy QR code'];
          break;  
        case 'mLangQRcode':
          title = ['mLang QR code', 'mLang QR code', 'mLang QR code'];
          break;
        case 'accessRight':
          title = ['Access right', '存取權限', '存取权限'];
          break;
        case 'projectIcons':
          title = ['DEFAULT PICTURES','預設照片','预设照片'];

          if(this.store.projects.deletedIcons.length > 0){
            this.state.right3Icon = true;
            right3Icon = trash;
            right3OnClick = ()=>{this.actions.modal.confirm(['Confirm to delete icons?','確定刪除照片?','确定删除照片?'], ()=>{
              this.actions.projects.deleteDefaultIcons({project:this.store.projects.viewingProject, icons:this.store.projects.deletedIcons});
              this.actions.projects.setDeletedIcon([]);
            })}
          }
          else{this.state.right3Icon = false;}

          const projectIconDeleteAction = this.store.projects.deleteIconAction;
          this.state.right2Icon = true;
          !projectIconDeleteAction ? right2Icon = choose_start : right2Icon = choose_stop;
          right2OnClick = ()=>{ this.actions.projects.setDeletedIcon([]); this.actions.projects.deleteIconAction(!projectIconDeleteAction); }

          break;
        case 'subjectIcons':
          title = ['DEFAULT PICTURES','預設照片','预设照片'];

          if(this.store.subjects.deletedIcons.length > 0){
            this.state.right3Icon = true;
            right3Icon = trash;
            right3OnClick = ()=>{this.actions.modal.confirm(['Confirm to delete icons?','確定刪除照片?','确定删除照片?'], ()=>{
              this.actions.subjects.deleteDefaultIcons({subject:this.store.subjects.viewingSubject, icons:this.store.subjects.deletedIcons});
              this.actions.subjects.setDeletedIcon([]);
            })}
          }
          else{this.state.right3Icon = false;}

          const subjectIconDeleteAction = this.store.subjects.deleteIconAction;
          this.state.right2Icon = true;
          !subjectIconDeleteAction ? right2Icon = choose_start : right2Icon = choose_stop;
          right2OnClick = ()=>{ this.actions.subjects.setDeletedIcon([]); this.actions.subjects.deleteIconAction(!subjectIconDeleteAction); }

          break;
        case 'addProjectIcon':
          title = ['ADD ICON', '新增圖標', '新增图标'];
          break;
        case 'addSubjectIcon':
          title = ['ADD ICON', '新增圖標', '新增图标'];
          break;
        case 'addTagsToSchools':
          title = ['ADD TAGS TO SCHOOLS', '為學校添加標籤', '为学校添加标签'];
          break;
        case 'addTag':
          title = ['ADD TAGS', '添加標籤', '添加标签'];
          break;
        case 'editTag':
          title = ['EDIT TAGS', '修改標籤', '修改标签'];
          break;
        case 'exportReport':
          title = ['EXPORT REPORT', '匯出報告', '汇出报告'];
          break;
        case 'changeSetting':
          title = ['CHANGE SETTINGS', '變更設定', '变更设定'];
          break;
        case 'subjectList':
          title = ['COPY UNIT', '複製單元', '复制单元'];
          break;
        case 'projectList': 
          title = ['COPY PROJECT', '複製專題研習', '复制专题研习'];
          break;
        default:
          title = ['','']
          break;
      }
    }
    if(this.store.courses.viewingCourse.mlanghku){
      rightIcon = null;
      rightOnClick = ()=>{};
    }
    this.setState({
      leftNav: ()=>{ return this.buttons.nav(leftIcon, ()=>{ leftOnClick() })},
      right4Nav: ()=>{ return this.buttons.nav(right4Icon, ()=> {right4OnClick() })},
      right3Nav: ()=>{ return this.buttons.nav(right3Icon, ()=> {right3OnClick() })},
      right2Nav: ()=>{ return this.buttons.nav(right2Icon, ()=>{ right2OnClick() })},
      rightNav: ()=>{ return this.buttons.nav(rightIcon, ()=>{ rightOnClick() })},
      titleArea: ()=>{ return this.titleArea(this.func.multiLang(title[0], title[1], title[2]));},
      init: true
    });
  }

  titleArea(title){
    const titleAreaStyle = {
      flexGrow: 5,
      textAlign: 'center',
      color: 'white',
      fontSize: this.bs.height * 0.055,
      fontWeight: 'bold'
    }
    return <div style={titleAreaStyle} onClick={()=>{ this.onNavClick();}}>{title}</div>
  }

  render() {
    this.init(this.props);
    const view = this.store.content.view;
    if(view === '' || !this.state.init){
      return null;
    }

    const navBarStyle = {
      width: '100%',
      height: this.bs.height * 0.08,
      backgroundImage: 'url(' + topBar + ')',
      backgroundSize: '100% 100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center'
    }
    return(
      <div style={navBarStyle}>
        {this.state.leftNav()}
        {this.state.titleArea()}
        {this.state.right4Icon && this.state.right4Nav()}
        {this.state.right3Icon && this.state.right3Nav()}
        {this.state.right2Icon && this.state.right2Nav()}
        {this.state.rightNav()}
      </div>
    )
  }

  saveGradeCard(){
    const project = this.store.projects.viewingProject;
    const studentProject = this.store.studentProjects.viewingStudentProject;
    const gradingCards = this.store.cards.gradingCards[studentProject._id]; 
    const cards = this.func.getAllFeaturedCardsIdInViewingProject();
    var maxDate = cards.length;
    for(var i = 0; i < cards.length; i++){
      const card = this.func.getById.card(cards[i], this.store);
      const date = Number(card.createdAt.substring(20,23));
      maxDate = (date >= maxDate)? date +  1 : maxDate;
    }
    this.actions.cards.saveGradingCards(project._id, studentProject._id, gradingCards, maxDate);
  }


}

export default NavBar;
