import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import * as SchoolTagHelper from 'components/main/pages/home/helpers/SchoolTagHelper.js';

class Addsubject extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.subject = this.store.subjects.viewingSubject;
    this.state = {
      modified: false,
      filename: this.subject.icon,
      type: 'subjectIcon',
      tags: [],
      subjectHide: this.subject.hide,
    }
    this.checkUrl();
  }

  componentWillUnmount(){
    this.actions.main.setPhotoAttr(0);
    this.actions.subjects.setTags([]);
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    if(this.store.subjects.subjectToCopy != null &&  !this.props.editMode)
    {
      document.getElementById('title').value = this.store.subjects.subjectToCopy.title;
      document.getElementById('desc').value = this.store.subjects.subjectToCopy.description;
    }
  }
  setHideSubject(){
    const fontSize = 0.03;
    const checkBoxSize = 0.035;
    const optionsStyle = {
      width: this.bs.width * 0.65,
      height: '',
    }
    const hide = this.state.subjectHide? 'true': 'false';

    return(
      <div style={optionsStyle}>
        {this.checkBox(this.func.multiLang('Yes','是','是'), hide === 'true', (e)=>{this.setState({ subjectHide: true, modified: true })}, 'black', fontSize, checkBoxSize, true)}
        {this.gap(this.bs.height * 0.02)}
        {this.checkBox(this.func.multiLang('No','否','否'), hide === 'false', (e)=>{this.setState({ subjectHide: false, modified: true })}, 'black', fontSize, checkBoxSize, true)}
      </div>
    )
  }

  multipleCheckBox(onChange){
    const choices = SchoolTagHelper.filter(this.store.schoolTags.schoolTags, this.store.courses.viewingCourse.tags, 'subject');
    const defaultTag = this.subject.tags?this.subject.tags:[];
    return(
      <MultipleSelect app={this.app} choices={choices} defaultTag={defaultTag} openStatus={null} onChange={onChange} type={'subject'} />
    )
  }

  render() {
    this.init(this.props);
    var defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() + 1);
    return(
      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        <ImagePicker type={'subject'} defaultUrl={this.url.url} app={this.app} />
        {this.sep()}
        {this.gap('2%')}

        {this.subTitle(['Title','標題','标题'])}
        {this.sep()}
        {this.inputs.inputField('title','text', '', this.props.editMode? this.subject.title:'' , ()=>{ this.setState({modified: true})})}
        {this.gap('2%')}

        {this.subTitle(['Description','描述','描述'])}
        {this.sep()}
        {this.gap('2%')}
        {this.inputs.textArea('desc', '', this.props.editMode? this.subject.description:'', ()=>{ this.setState({modified: true})})}
        {this.gap('4%')}

        {this.subTitle(['Hide','隱藏','隐藏'])}
        {this.sep()}
        {this.gap('1%')}
        {this.setHideSubject()}
        {this.gap('5%')}

        {this.subTitle(['Tag', '標籤', '标籤'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.multipleCheckBox(()=>{ this.setState({modified: true}) })}
        {this.gap('4%')}

        {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.addSubject()})}
        {this.gap('4%')}
      </div>
    )
  }

  addSubject(){
    const editMode = this.props.editMode;
    const newIconBlob = this.store.main.photoBlob;
    const title = document.getElementById('title').value;
    const description = document.getElementById('desc').value;
    const hide = this.state.subjectHide ? this.state.subjectHide : false;
    const tags = this.store.subjects.tags;

    if(!editMode && newIconBlob === null){
      return this.failedMessage(['Failed to add! Icon is missing!', '創建失敗! 未有照片!','创建失败! 未有照片!'])
    }
    if(title.length === 0){
      return this.failedMessage(['Failed to add! Title is missing!', '創建失敗! 未填標題!','创建失败! 未填标题!'])
    }

    if(!editMode){
      this.actions.subjects.addSubject({
        course: this.store.courses.viewingCourse._id,
        icon: newIconBlob,
        title: title,
        description: description,
        hide: hide,
        tags: tags,
        subjectToCopy: this.store.subjects.subjectToCopy
      });
    }else if(newIconBlob || this.state.modified){
      this.actions.subjects.editSubject({...this.subject, ...{
        newIcon: newIconBlob,
        title: title,
        description: description,
        hide: hide,
        tags: tags
      }})
    }else{
      return this.failedMessage(['Failed to add! Nothing is modified!', '提交失敗!未作出更改!', '提交失败!未作出更改!'])
    }
  }

}

export default Addsubject;
