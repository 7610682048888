//import * as reducer from '../reducer';
import reducer from '../reducer';

const schoolTagsReducer = (
  state = {
    schoolTags: [],
    viewingSchoolTag: {},
  }, action)=>{
  switch (action.type) {
    case 'updateSchoolTags':
        return {...state, schoolTags: reducer.updateElements(state.schoolTags, action.payload)};
    case 'viewSchoolTag':
      return {...state, viewingSchoolTag: action.payload};
    default:
      return state;
  }
}

export default schoolTagsReducer;
