import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import ReactPieChart from 'components/main/items/graph/ReactPieChart';

class MatchGameResult extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.matchGamePublish = this.store.matchGames.viewingMatchGamePublish;
    this.correct = 0;
    this.incorrect = 0;
    this.state = {
      width: this.store.ui.minWidth + 300 < this.store.ui.windowWidth ? this.store.ui.minWidth + 300 : this.store.ui.windowWidth,
      height: this.store.ui.minHeight - 100 < this.store.ui.windowHeight ? this.store.ui.minHeight - 100 : this.store.ui.windowHeight
    }
  }

  componentDidMount(){
    this.getMatchGameResult();
    if(this.store.matchGames.mode === 'test'){
      this.submitMatchGame();
    }
  }

  submitMatchGame(){
    this.actions.matchGames.addSubmit({
      matchGamePublish: this.store.matchGames.viewingMatchGamePublish._id,
      submittedBy: this.store.user._id,
      questions: this.getQuestions(),
      answers: this.store.matchGames.answers,
      correct: this.correct,
      incorrect: this.incorrect,
    });
  }

  getMatchGameResult(){
    const questions = this.store.matchGames.viewingCards;
    for(var i=0;i<questions.length;i++){
      if(questions[i] !== 'submit'){
        const answer = this.store.matchGames.answers[i];
        const type = this.matchGamePublish.type;
        if(type === 'audio' && questions[i].langs.includes(answer.audio)){ this.correct++; }
        else if(type === 'text' && questions[i].langs.includes(answer.text)){ this.correct++; }
        else if(type === 'both' && questions[i].langs.includes(answer.audio) && questions[i].langs.includes(answer.text)){ this.correct++; }
        else{ this.incorrect++; }
      }
    }
  }

  getQuestions(){
    var questions = [];
    for(var i=0; i < this.store.matchGames.viewingCards.length; i++){
      if(this.store.matchGames.viewingCards[i] !== 'submit'){ questions.push(this.store.matchGames.viewingCards[i]._id); }
    }
    return questions;
  }

  render() {
    this.init(this.props);
    const data = {type: 'matchGame', correct: this.correct, incorrect: this.incorrect}

    return(
      <div style={this.subViewStyle()}>
        {this.gap('4%')}
        {this.subTitle(['Result','結果','結果'])}
        {this.sep()}
        {<ReactPieChart app={this.app} data={data}  width={this.state.width} height={this.state.height} /> }
        {this.gap('8%')}
      </div>
    )
  }

}

export default MatchGameResult;
