import React from 'react';
import View from 'components/main/pages/home/views/View';

class Agreement extends View {

  constructor(props){
    super(props);
    this.init(props);
    this.state = {
      agree: false,
      termsEng: 'I shall be responsible for the content I created on this platform。The data I provided on this application, including my name and e-mail address, are correct。I agree to let m-Chinese Solution Limited analyse the contents I created for promotional purposes。I shall inform m-Chinese Solution Limited if I do not want to give such consent (email: info@mlang.com.hk)',
      termsChi: '我對我所製作的內容負全責。我登記時所提交的個人資料（包括我的姓名及電郵地址）完全正確。我同意動中文方案有限公司使用我在這平台創作的內容作分析及宣傳之用。如我不同意的話，我會將我的意願通知動中文方案有限公司 (電郵: info@mlang.com.hk)',
      termsSimChi: '我对我所製作的内容负全责。我登记时所提交的个人资料（包括我的姓名及电邮地址）完全正确。我同意动中文方案有限公司使用我在这平台创作的内容作分析及宣传之用。如我不同意的话，我会将我的意愿通知动中文方案有限公司 (电邮: info@mlang.com.hk)',
    }
    this.splitTerms();
  }

  componentDidMount(){
    window.onpopstate  = (e) => {
      this.actions.main.setStatus('init');
      this.actions.user.logout();
    }
  }


  splitTerms(){
    const language = this.store.main.language;
    if(language === 'chinese'){ this.terms = this.state.termsChi.split('。'); }
    else if(language === 'simplified_chinese'){ this.terms = this.state.termsSimChi.split('。'); }
    else if(language === 'english'){ this.terms = this.state.termsEng.split('。'); }
  }

  termsSelected(){
    const fontSize = 0.02;
    const checkBoxSize = 0.025;
    const optionsStyle = {
      width: this.bs.width * 0.65,
      height: '',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    }
    return(
      <div style={optionsStyle}>
        {this.checkBox(this.func.multiLang('By ticking this box, I declare that:','在此方格上打剔號，我聲明：','在此方格上打剔号，我声明：'), this.state.agree === true, (e)=>{this.setState({ agree: !this.state.agree })}, 'black', fontSize, checkBoxSize, true)}
        {this.gap(this.bs.height * 0.015)}
      </div>
    )
  }

  displayTerms(){
    const style = {
      width: this.bs.width * 0.70,
      height: this.bs.height * 0.55,
      overflow: 'auto',
      backgroundColor: 'white'
    }
    const termStyle = {
      justifyContent: 'center',
      margin: '4%',
      fontWeight: 'bold',
      fontSize: 18,
    }

    return(
      <div style={style}>
        {this.terms.map((term, i) => {
          return(
            <div style={termStyle} key={i}>
              {(i+1) + '. ' + term}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    this.init(this.props);
    //const profile = this.store.profile;
    //const forceAgreement = this.store.content.view === 'forceAgreement';

    return(
      <div style={this.viewStyle()}>
        <div style={this.viewContentStyle()}>

          {this.gap(this.bs.height * 0.04)}
          {this.sep()}
          {this.gap(this.bs.height * 0.03)}

          {this.termsSelected()}
          {this.displayTerms()}

          {this.gap(this.bs.height * 0.03)}
          {this.sep()}
          {this.gap(this.bs.height * 0.02)}

          {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.changing()})}

          {this.gap(this.bs.height * 0.04)}

        </div>
      </div>
    )
  }

  changing(){
    const terms = this.store.main.language === 'chinese' ? this.state.termsChi : this.store.main.language === 'simplified_chinese' ? this.state.termsSimChi : this.state.termsEng;
    const name = this.store.profile.name;
    const data = {
      belongTo: this.store.user._id,
      key: this.store.main.language,
      terms: terms
    };

    if(!this.state.agree){
      return this.failedMessage(['Please read and agree to our Terms & Conditions', '請閱讀並同意我們的條款及細則', '请阅读并同意我们的条款及细则'])
    }

    if(!name || name === ''){ this.actions.agreement.setAgreement(data); this.actions.content.pushView('forceProfile'); }
    else{ this.actions.agreement.add(data); }

    //this.actions.agreement.add(data);
  }

}

export default Agreement;
