import React from "react";
import Konva from "react-konva";

const Star = ({ editMode, shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected && editMode) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    // eslint-disable-next-line
  }, [isSelected]);
  return (
    <React.Fragment>
      <Konva.Star
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={editMode}
        {...shapeProps}
        onDragStart={e => {
          onChange({
            ...shapeProps,
            isDragging: true
          })
        }}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            isDragging: false
          });
        }}
        onTransformEnd={e => {
          // transformer is changing scale
          const node = shapeRef.current;

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY(),
          });
        }}
      />
      {isSelected && editMode && <Konva.Transformer ref={trRef} />}
    </React.Fragment>
  );
};
export default Star;