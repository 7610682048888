import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Image from 'components/main/items/ui/Image';
//import Sound from 'react-sound';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

//import icon_play from 'resources/images/buttons/buttonIcons/play_grey.png';
//import icon_stop from 'resources/images/buttons/buttonIcons/stop_grey.png';

class ProjectDetail extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    const project = this.store.projects.viewingProject;
    this.state = {
      filename: project.audioDesc?project.audioDesc:null,
      type: 'projectAudioDesc',
      isPlaying: false
    }
    this.checkUrl();
  }
  /*

  playback(){
    if(this.state.isPlaying){ return; }
    this.setState({
      isPlaying: true
    })
  }

  onPlaybackEnd(){
    this.setState({
      isPlaying: false
    })
  }

  stopPlayback(){
    if(!this.state.isPlaying){ return; }
    this.onPlaybackEnd();
  }

  audio(lang){
    if(this.state.isPlaying && this.url.url){
      console.log('playing')
      return(
        <Sound
        url={this.url.url}
        playStatus={Sound.status.PLAYING}
        onFinishedPlaying={this.onPlaybackEnd.bind(this)}/>
      )
    }
    return null;
  }
  */

  audioPlayer(){
    const containerStyle = {...this.ui.styles.area, ...{
      width: '70%',
      height: this.bs.height * 0.065,
      minWidth: '85px',
      minHeight: '60px',
      display: 'flex',
      justifyContent: 'center',
      flexShrink: 0,
    }}

    const audioPlayerStyle = { 
      height: this.bs.height * 0.060,
      minWidth: '85px',
      minHeight: '60px'
    }

    return (  
      <div style={containerStyle}>
          <AudioPlayer 
            style={audioPlayerStyle}
            atuoPlay={false}
            autoPlayAfterSrcChange={false}
            src={this.url.url} 
            layout="horizontal-reverse"
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
          />
      </div>
    )
  }

  render() {
    this.init(this.props);
    const project = this.store.projects.viewingProject;
    const subject = this.func.getById.subject(project.subject, this.store);

    //const sizeSmall = [this.bs.height * 0.04,this.bs.height * 0.04];

    return(
      <div style={this.subViewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        {this.gap('2%')}
        <Image app={this.app} filename={project.icon} type={'projectIcon'} size={this.bs.height * 0.22}/>
        {this.gap('2%')}

        {this.subTitle(['Subject','議題','议题'])}
        {this.sep()}
        {this.detailText(subject.title, 'projectSubject')}
        {this.gap('2%')}

        {this.subTitle(['Title','標題','标题'])}
        {this.sep()}
        {this.detailText(project.title, 'projectTitle')}
        {this.gap('2%')}

        {this.subTitle(['Description','描述','描述'])}
        {this.sep()}
        {/*this.audio()*/}
        {this.gap('1%')}
        {this.url.url && this.audioPlayer()}
        {this.gap(this.bs.height * 0.025)}
        {/*this.url.url && !this.state.isPlaying && this.buttons.langBar(icon_play , 0.25, sizeSmall,()=>{this.playback()})*/}
        {/*this.url.url && this.state.isPlaying && this.buttons.langBar(icon_stop, 0.25, sizeSmall,()=>{this.stopPlayback()})*/}
        {project.description.split('\n').map((desc, i) =>{return this.detailText(desc, 'projectDesc-' + i, ['30%', ''], 'left')})}
        {this.gap('2%')}

        {this.subTitle(['Allowed Comment','允許評論','允许的评论'])}
        {this.sep()}
        {project.allowedComments ? project.allowedComments.map((allowedComment,index)=>{return this.detailText(allowedComment, 'projectAllowedComment' + index)}) : ''}
        {this.gap('2%')}

        {this.subTitle(['Hide','隱藏','隐藏'])}
        {this.sep()}
        {project.hide ? this.detailText(this.func.multiLang('Yes', '是', '是', 'projectHideTrue')) : this.detailText(this.func.multiLang('No', '否', '否', 'projectHideFalse'))}
        {this.gap('2%')}

        {this.subTitle(['Tag', '標籤', '标籤'])}
        {this.sep()}
        {this.multipleCheckBox()}
        {this.gap('2%')}

        {this.subTitle(['Start date','創建於','创建于'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(project.createdAt)), 'projectCreatedAt')}
        {this.gap('2%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(project.endDate)), 'projectEndDate')}
        {this.gap('6%')}
      </div>
    )
  }

  multipleCheckBox(){
    const project = this.store.projects.viewingProject;
    const defaultTag = project.tags?project.tags:[];
    return(
      <MultipleSelect app={this.app} choices={[]} defaultTag={defaultTag} openStatus={false} onChange={null} type={'project'} disableUnderline={true} />
    )
  }

}

export default ProjectDetail;
