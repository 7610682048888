import React from 'react';
import View from 'components/main/pages/home/views/View';
import StudentInfoRow from 'components/main/items/rows/StudentInfoRow';

class AddStudentToGroup extends View {

  constructor(props){
    super(props);
    this.init(props);
  }

  componentDidMount(){
    this.setListScroll('studentList');
  }

  studentsList(){
    var students = this.store.courses.viewingCourse.joinedStudents;
    for(let i = 0; i < this.store.courses.courses.length; i++){
      if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
        students = this.store.courses.courses[i].joinedStudents;
        const profilesToGet = [];
        const profilesToShow = students;

        for(let j = 0;j < profilesToShow.length; j++){
          if(this.func.getById.profileByUser(profilesToShow[j], this.store) === null){
            profilesToGet.splice(0,0, profilesToShow[j]);
          }
        }
        if(profilesToGet.length > 0){
          this.actions.profiles.getProfiles(profilesToGet);
        }
      }
    }
    var availableStudents = [], selectedStudents = [], available = true;
    for(var i = 0; i < students.length; i++){
      const student = this.func.getById.profileByUser(students[i], this.store);
      available = true;
      for(var j = 0; j < this.store.groups.groups.length; j++){
        if(this.store.projects.viewingProject._id === this.store.groups.groups[j].project && this.store.groups.groups[j].members.indexOf(students[i]) > -1){
          available = false;
        }
      }
        if(available)
          availableStudents.push(student);
    }
    if(availableStudents.length > 1)
    availableStudents.sort((a, b) => {
      if(a !== null && b !== null)
        return a.name > b.name ? 1 : -1;
      else
        return true;
    });
    return availableStudents.map((profile, i)=>{
      if(!profile){ return null; }
      return (
      <StudentInfoRow
      app={this.app}
      profile={profile}
      student={profile.belongTo}
      key={i}
      creatingGroup = {false}
      handleCheck = {(selectedStudent, creatingGroup) =>{
        if(selectedStudents.indexOf(selectedStudent) > -1){
            if(creatingGroup){
                const checkbox = document.getElementById("leadercheckbox" + selectedStudent);
                checkbox.disabled = false;
            }
            selectedStudents.pop(selectedStudent);
        }
        else{
            if(creatingGroup){
                const checkbox = document.getElementById("leadercheckbox" + selectedStudent);
                checkbox.disabled = true;
            }
            selectedStudents.push(selectedStudent);
        }
        this.selectedStudentList = selectedStudents;
      }
      }
      handleLeaderCheck = {(selectedStudent) =>{
        if(this.groupLeader === null){
            this.groupLeader = selectedStudent;
            const memberCheckbox = document.getElementById("membercheckbox" + this.groupLeader);
            memberCheckbox.disabled = true;
            for(let i = 0; i < this.store.courses.courses.length; i++){
              if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
                for(let j = 0; j < this.store.courses.courses[i].joinedStudents.length; j++){
                  if(selectedStudent !== this.store.courses.courses[i].joinedStudents[j]){
                    const checkbox = document.getElementById("leadercheckbox" + this.store.courses.courses[i].joinedStudents[i]);
                    checkbox.disabled = true;
                  }
                }
            }
          }
        }
          else{
            const memberCheckbox = document.getElementById("membercheckbox" + this.groupLeader);
            memberCheckbox.disabled = false;
            for(let i = 0; i < this.store.courses.courses.length; i++){
              if(this.store.courses.viewingCourse.code === this.store.courses.courses[i].code){
                for(let j = 0; j < this.store.courses.courses[i].joinedStudents.length; j++){
                  if(selectedStudent !== this.store.courses.courses[i].joinedStudents[j]){
                    const checkbox = document.getElementById("leadercheckbox" + this.store.courses.courses[i].joinedStudents[j]);
                    checkbox.disabled = false;
                  }
                }
            }
          }
            this.groupLeader = null;
          }

      }
    }

      />)
    })
  }

  


  render(){

    this.init(this.props);
    return(
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", overflow: "auto"}}>
        {this.subTitle(['Students','學生','学生'], "2em")}
        <div id={'studentList'} onScroll={() => { this.onScroll('studentList');}} style={{ ...this.bs, ...this.ui.styles.list}}>
          {this.studentsList()}
        </div>
        {this.buttons.rectGreen(['Add to group','加入小組','加入小组'], ()=>{ this.joinGroup(); } )}
      </div>
    )
  }



  joinGroup(){
    const groupCode = this.store.groups.viewingGroup.code;
    if(groupCode !== null){
        for(var i = 0; i < this.selectedStudentList.length; i++){
            this.actions.groups.joinGroup(this.selectedStudentList[i], groupCode);
        }
    }
    this.actions.content.pullView();
    this.actions.content.pullView();
  }
}
export default AddStudentToGroup;