import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Files from 'components/main/pages/home/views/home/contents/Files';
import icon_account from 'resources/images/icons/account.png';
import icon_course from 'resources/images/icons/course.png';
import icon_link from 'resources/images/icons/link.png';
import icon_access from 'resources/images/icons/access.png';

class ImportFile extends SubView {

  render(){
    this.init(this.props);
    const data = this.getData();
    if(!data){ return null; }

    return(
      <Files app={this.app} data={data}/>
    )
  }

  getData(){
    const subView = this.store.content.subView;
    var data = null;
    if(subView === 'importFile'){
      data = [{
        icon: icon_account,
        title: this.func.multiLang('Create Accounts', '新增帳戶', '新增帐户'),
        type: 'createAccounts',
        onClick: ()=>{this.actions.content.pushView('createAccounts')}
      },{
        icon: icon_course,
        title: this.func.multiLang('Join Courses', '加入班別', '加入班别'),
        type: 'joinCourses',
        onClick: ()=>{this.actions.content.pushView('joinCourses')}
      },{
        icon: icon_link,
        title: this.func.multiLang('mLang', 'mLang', 'mLang'),
        type: 'mLangQRcode',
        onClick: ()=>{this.actions.content.pushView('mLangQRcode')}
      },{
        icon: icon_link,
        title: this.func.multiLang('HomeworkEasy', 'HomeworkEasy', 'HomeworkEasy'),
        type: 'homeworkEasyQRcode',
        onClick: ()=>{this.actions.content.pushView('homeworkEasyQRcode')}
      },{
        icon: icon_access,
        title: this.func.multiLang('Access right', '存取權限', '存取权限'),
        type: 'accessRight',
        onClick: ()=>{this.actions.content.pushView('accessRight')}
      }]
    }
    return data;
  }

}

export default ImportFile;
