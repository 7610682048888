import React from 'react';
import UI from 'components/UI';
import CardCell from 'components/main/items/cells/CardCell';

class Cards extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.handleLeft = this.props.handleLeft;
    this.handleRight = this.props.handleRight;
    this.horizontal = (this.props.horizontal !== null)? this.props.horizontal : false;
    this.state = {
      viewCardIsSet: false
    }
  }

  setViewCards(props){
    const cardsId = this.props.cardsId;
    var cardsToView = [];
    cardsId.map(cardId=>{
      const card = this.func.getById.card(cardId, this.store);
      if(!card){ return null; }
      if(this.props.featuredOnly && card.grade !== 'featured'){ return null; }
      cardsToView.push(cardId);
      return null;
    })
    this.actions.cards.viewCards(cardsToView);
    this.setState({
      viewCardIsSet: true
    })
  }

  componentDidMount(){
    this.init(this.props);
    this.getCards(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(!this.props.recover){ this.getCards(newProps); }
  }

  getCards(props){
    const cardsToGet = [];
    const cardsToShow = props.cardsId;
    if(!cardsToShow){ console.log('no card to show!'); return;}

    for(var i=0;i<cardsToShow.length;i++){
      if(this.func.getById.card(cardsToShow[i], this.store) === null){
        cardsToGet.splice(0,0, cardsToShow[i]);
      }
    }

    if(cardsToGet.length > 0){
      this.actions.cards.getCards(cardsToGet);
    }else if(!this.state.viewCardIsSet || (!this.props.featuredOnly && cardsToShow.length !== 0 && this.store.cards.viewingCards.length === 0)){
      this.setViewCards(props);
    }
  }

  onCellClick(){
    if(this.props.onCellClick){ this.props.onCellClick(); }
  }


  isSelectedToDel(deletedCardList, card){
    var isSelectedDel = false;
    deletedCardList.map((deletedCard, i) =>{
      if(deletedCard._id === card._id && this.store.user._id === card.author){isSelectedDel = true;}
      return null;
    })
    return isSelectedDel;
  }

  isSelectedToRecover(recoveredCardList, card){
    var isSelectedRecover = false;
    recoveredCardList.map((recoveredCard, i) =>{
      if(recoveredCard._id === card._id){isSelectedRecover = true;}
      return null;
    })
    return isSelectedRecover;
  }

  changeLeftBackground(e, card){
    e.target.style.background = 'rgba(0, 255, 0, 0.4)';
    let div = document.getElementById("div" + card._id);
    div.style.backgroundColor = "grey";
  }

  removeLeftBackground(e, card){
    e.target.style.background = "green";
    let div = document.getElementById("div" + card._id);
    div.style.backgroundColor = "white";
  }

  changeRightBackground(e, card){
    e.target.style.background = 'rgba(255, 0, 0, 0.4)';
    let div = document.getElementById("div" + card._id);
    div.style.backgroundColor = "grey";
  }

  removeRightBackground(e, card){
    e.target.style.background = "red";
    let div = document.getElementById("div" + card._id);
    div.style.backgroundColor = "white";
  }

  leftButton(card){
    const style ={
      borderRadius: this.bs.height * 0.005,
      backgroundColor: "green", 
      color:"white",
      textAlign : "center",
      padding: 0,
      cursor: "pointer", height: this.bs.height * 0.06, width: this.bs.height * 0.085, marginBottom:  this.bs.height * 0.08, fontSize:  this.bs.height * 0.04
    }
    return <button style={style}  onMouseOver={(e)=>this.changeLeftBackground(e, card)} onMouseLeave={(e)=>this.removeLeftBackground(e, card)} onClick = {() => this.handleLeft(card)} > ⇦ </button>;
  }

  rightButton(card){
    const style ={
      borderRadius:  this.bs.height * 0.005,
      backgroundColor: "red", 
      color:"white",
      textAlign : "center",
      padding: 0,
      cursor: "pointer", height: this.bs.height * 0.06, width: this.bs.height * 0.085, marginTop:  this.bs.height * 0.08, fontSize:  this.bs.height * 0.04
    }
    return   <button style={style}  onMouseOver={(e)=>this.changeRightBackground(e, card)} onMouseLeave={(e)=>this.removeRightBackground(e, card)} onClick = {() => this.handleRight(card)}> ⇨ </button>
  }

  midLeftButton(card){
    const style ={
      borderRadius: this.bs.height * 0.005,
      backgroundColor: "green", 
      color:"white",
      textAlign : "center",
      padding: 0,
      cursor: "pointer", height: this.bs.height * 0.06, width: this.bs.height * 0.085, fontSize: this.bs.height * 0.04
    }
    return <button style={style}  onMouseOver={(e)=>this.changeLeftBackground(e, card)} onMouseLeave={(e)=>this.removeLeftBackground(e, card)} onClick = {() => this.handleLeft(card)} > ⇦ </button>;
  }

  midRightButton(card){
    const style ={
      borderRadius: this.bs.height * 0.005,
      backgroundColor: "red", 
      color:"white",
      textAlign : "center",
      padding: 0,
      cursor: "pointer", height: this.bs.height * 0.06, width: this.bs.height * 0.085, fontSize: this.bs.height * 0.04
    }
    return   <button style={style}  onMouseOver={(e)=>this.changeRightBackground(e, card)} onMouseLeave={(e)=>this.removeRightBackground(e, card)} onClick = {() => this.handleRight(card)}> ⇨ </button>
  }

  findIndex (card){
    var index = -1;
    for(var i = 0; i < this.props.cardsId.length; i++)
      if(card._id === this.props.cardsId[i])
        index = i;
    return index;
  }

  render(){
    this.init(this.props);
    if(!this.props.cardsId){ return null;}
    const cardsToShow = this.props.cardsId.slice(0);
    //console.log(cardsToShow)
    const cardsStyle = {
      width: '95%',
      height: '100%',
      overflowY: (cardsToShow.length < 1)? 'clip' : 'auto',
      display: 'flex',
      flexFlow: this.horizontal? 'row': 'row wrap',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      paddingLeft: this.horizontal? '0%' : '2%',
      paddingRight: this.horizontal? this.bs.width * 0.65: "0%",
    }
    const cardContainerStyle = {...this.ui.styles.container, ...{
      width: this.bs.height * 0.275,
      height: this.bs.height * 0.375,
      marginLeft: this.horizontal? "0%" : this.bs.height * 0.03,
      marginRight: this.horizontal? "0%" : this.bs.height * 0.07,
      marginBottom: this.horizontal? "0%" : this.bs.height * 0.06,
      marginTop: this.horizontal? this.bs.height * 0.01: '0%',
    }}
    cardsToShow.push('add');

    const chooseCardToDel = this.store.cards.deleteAction && (this.store.content.subView === 'projectSubmitted' || this.store.content.subView === 'projectFeatured' );
    const chooseCardToRecover = this.store.cards.recoverAction && this.store.content.subView === 'projectDeleted';

    return(
      <div style={cardsStyle}>
        {this.gap('3%')}
        {!this.horizontal && cardsToShow.length <= 1 &&
        this.subTitle(['No cards to show','沒有卡片','没有卡片'])}
        {/*this.horizontal && cardsToShow.length <= 1 && <div style={{width: '100%',color: this.ui.colors.mlangGreen, fontSize:'110%',fontWeight: 'bold', textAlign: 'center'}}>{this.func.multiLang('No cards to show','沒有卡片','没有卡片')}</div>*/}
        {cardsToShow.map((cardId,i)=>{
          if(cardId === 'add' && !this.store.projects.viewingProject.mlanghku){
            return(
              <div key={i} style={cardContainerStyle}>
                {this.props.onAdd && this.props.onAdd()}
              </div>
            )
          }
          const card = this.func.getById.card(cardId, this.store);
          if(!card){ return null;}
          if(this.props.featuredOnly && card.grade !== 'featured'){ return null; }
          //console.log(card);

          //if(card.hide){return null;};   /* hide the student's card*/

          var isSelectedDel = false;
          if(this.store.cards.selectedDeleteCards.length > 0){
            isSelectedDel = this.isSelectedToDel(this.store.cards.selectedDeleteCards, card);
          }

          var isSelectedRecover = false;
          if(this.store.cards.selectedRecoverCards.length > 0){
            isSelectedRecover = this.isSelectedToRecover(this.store.cards.selectedRecoverCards, card);
          }

          return(
            <div key={i} style={cardContainerStyle}>
              <div style={{boxShadow: this.store.cards.deleteAction && card.author === this.store.user._id && (this.store.user.type === "teacher" || this.store.user.type === "developer") ? '4px 8px 48px -6px  red': ''}}>
                {isSelectedDel && <div style={{height: "4vh", width: "4vh", fontSize: "3vh" ,borderRadius: "50%", backgroundColor: "rgb(155, 155, 155)", opacity: 0.75, fontWeight: "bold", color: "white", backgroundSize: "100% 100%", flexShrink: 0, display: "flex", justifyContent: "center", alignItems: "center"}}>{this.store.cards.selectedDeleteCards.indexOf(card) + 1}</div>}
                <CardCell
                key={card._id}
                id={card._id}
                app={this.app} data={card}
                isSelectedDel={isSelectedDel}
                isSelectedRecover={isSelectedRecover}
                onClick={()=>{
                  if(chooseCardToDel){
                    if(isSelectedDel){ this.actions.cards.removeDeleteCard(card); }
                    else{
                      this.actions.cards.viewCard(card);
                      this.actions.cards.pushDeleteCards(card);
                    }
                  }
                  else if(chooseCardToRecover){
                    if(isSelectedRecover){ this.actions.cards.removeRecoverCard(card); }
                    else{
                      this.actions.cards.viewCard(card);
                      this.actions.cards.pushRecoverCards(card);
                    }
                  }
                  else{
                    this.onCellClick();
                    this.actions.cards.setAction('init');
                    this.actions.cards.viewCard(card);
                    this.actions.content.pushView('viewCards');
                }}}/>
              </div>
              { !this.horizontal &&
              <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column'}}> 
                {this.store.user.type === "teacher" && this.store.cards.deleteAction && this.findIndex(card) === 0 && this.props.cardsId.length !== 1 && this.midRightButton(card)}
                {this.store.user.type === "teacher" && this.store.cards.deleteAction&& this.findIndex(card) === this.props.cardsId.length - 1 && this.props.cardsId.length !== 1 && this.midLeftButton(card)}
                {this.store.user.type === "teacher" && this.store.cards.deleteAction && this.findIndex(card) !== 0 && this.findIndex(card) !== this.props.cardsId.length -1  && this.leftButton(card)}
                {this.store.user.type === "teacher" && this.store.cards.deleteAction && this.findIndex(card) !== 0 && this.findIndex(card) !== this.props.cardsId.length - 1 && this.rightButton(card)}
              </div> }
            </div>
          )
        })}
      </div>
    )
  }

}

export default Cards;