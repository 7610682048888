import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Files from 'components/main/pages/home/views/home/contents/Files';
import icon_report from 'resources/images/buttons/icon_report.png';

class CourseManagement extends SubView {

  render(){
    this.init(this.props);
    const data = this.getData();
    if(!data){ return null; }

    return(
      <Files app={this.app} data={data}/>
    )
  }

  getData(){
    const subView = this.store.content.subView;
    var data = null;
    if(subView === 'courseManagement'){
      data = [{
        icon: icon_report,
        title: this.func.multiLang('EXPORT REPORT','匯出報告','汇出报告'),
        type: null,
        onClick: ()=>{this.actions.content.pushView('exportReport')}
      }]
    }
    return data;
  }

}

export default CourseManagement;