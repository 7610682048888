import React from 'react';
import UI from 'components/UI';
import {Motion, spring}  from 'react-motion';
import MaterialDrawer from 'components/main/items/ui/MaterialDrawer';
import CanvasGraphic from 'components/main/items/ui/CanvasGraphic';
import * as AnnotationHelper from 'components/main/pages/home/helpers/AnnotationHelper.js';


class Canvas extends UI {

  constructor(props){
    super(props);
    this.init(props);
    this.state={
      fontSizes: [this.bs.height * 0.05, this.bs.height * 0.1, this.bs.height * 0.15, this.bs.height * 0.3],
      sizeIndex: 0,
      image: null
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    const main = this.store.main;
    if(!main.canvasImage){ return null; }
    this.loadImage(main.canvasImage);
  }

  
  componentWillUnmount() {
    if(this.image){
      this.image.removeEventListener('load', this.handleLoad);
    }
  }
  

  handleLoad = (e) => {
    const width = this.image.width > this.bs.width * 0.85 * 0.85? this.bs.width * 0.85 * 0.85: this.image.width;
    const height = this.image.height> this.bs.height? this.bs.height: this.image.height;
    this.image.width = width;
    this.image.height = height;

    this.setState({
      image: this.image
    });
  }

  loadImage(url){
    this.image = new window.Image();
    this.image.src = url;
    this.image.addEventListener('load', (e) => this.handleLoad(e));
  }

  onItemClick(value){
    this.setState({selectedItem: value});
  }


  render() {
    this.init(this.props);
    const main = this.store.main;
    if(!main.canvasImage || !main.canvasCard || !this.state.image){ return null; }
    const status = main.canvas;
    const isOpen = status !== 'off';

    const canvasStyle = {...this.bs, ...{
      position: 'absolute',
      minHeight: this.bs.minHeight,
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255,1)',
      pointerEvents: isOpen? 'auto': 'none',
    }}

    const newWidth = this.state.image.width;
    const newHeight = this.state.image.height;

    const [texts, lines, ellipses, stars, ticks, crosses] = AnnotationHelper.splitAnnotations(newWidth, newHeight, main.canvasCard? main.canvasCard.annotations: []);

    return(
      <Motion defaultStyle={{opacity: isOpen?0:1.5}}
      style={{opacity: isOpen?spring(1.5):spring(0)}}>
        {style=>(
          <div style={{...canvasStyle, ...{opacity: style.opacity}}}>
            {<MaterialDrawer app={this.app} minWidth={this.store.ui.minWidth} width={this.bs.width} height={this.bs.height} 
              onBack={async()=>{this.onItemClick('back'); await this.setState({image: null}); await this.actions.main.closeCanvas(); await this.actions.main.closeColorPicker(); await this.actions.main.setColorPicker(null);}} 
              onItemClick={(e, value)=>this.onItemClick(value)} 
              onSave={()=>{this.onItemClick('save')}}/>}
            <div style={{width: '85%', display: 'flex', justifyContent: 'center'}}>
              {status === 'image' && 
              <CanvasGraphic app={this.app} defaultImage={{image: this.state.image, rotation: this.store.main.photoAttr.rotation}} maxWidth={this.bs.width * 0.85 * 0.85} maxHeight={this.bs.height}
              selectedItem={this.state.selectedItem} project={this.store.projects.viewingProject._id} card={main.canvasCard} user={this.store.user._id} 
              defaultAnnotions={{texts: texts, lines: lines, ellipses: ellipses, stars: stars, ticks: ticks, crosses: crosses}} editMode={true}/>}
            </div>
          </div>
        )}
      </Motion>
    )
  }

}

export default Canvas;

