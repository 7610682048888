import React from 'react';
import View from 'components/main/pages/home/views/View';
import Files from 'components/main/pages/home/views/home/contents/Files';

class ImportIcons extends View {

  componentWillUnmount(){
    this.actions.projects.deleteIconAction(false);
    this.actions.projects.setDeletedIcon([]);
    this.actions.subjects.deleteIconAction(false);
    this.actions.subjects.setDeletedIcon([]);
  }

  subjectIconCell(){
    const viewingSubject = this.store.subjects.viewingSubject;
    var data = [];
    for(var i=0;i < viewingSubject.defaultIcons.length;i++){
      const defaultIcon = viewingSubject.defaultIcons[i];
      data.push({
        filename: defaultIcon,
        title: null,
        type: 'subjectDefaultIcon',
        onClick: async ()=>{this.actions.main.enlargeImage(await this.func.url(defaultIcon, 'subjectDefaultIcon'))}
      });
    }
    data.push('add');
    return <Files app={this.app} data={data} onAdd={this.onAdd.bind(this)}/>
  }

  projectIconCell(){
    const outDated = this.func.outDated(this.store.projects.viewingProject.endDate);
    const viewingProject = this.store.projects.viewingProject;
    var data = [];
    for(var i=0;i < viewingProject.defaultIcons.length;i++){
      const defaultIcon = viewingProject.defaultIcons[i];
      data.push({
        filename: defaultIcon,
        title: null,
        type: 'projectDefaultIcon',
        onClick: async ()=>{this.actions.main.enlargeImage(await this.func.url(defaultIcon, 'projectDefaultIcon'))}
      });
    }
    data.push('add');
    return <Files app={this.app} data={data} onAdd={outDated? null: this.onAdd.bind(this)}/>
  }

  onAdd(){
    return this.buttons.cellAdd(()=>{
      const view = this.store.content.view;
      if(view === 'projectIcons'){ this.actions.content.pushView('addProjectIcon'); }
      else if(view === 'subjectIcons'){ this.actions.content.pushView('addSubjectIcon'); }
    })
  }


  render() {
    this.init(this.props);
    const view = this.store.content.view;
    return(
      <div style={this.viewStyle()}>
          {view === 'projectIcons'? this.projectIconCell():
           view === 'subjectIcons'? this.subjectIconCell(): null}
      </div>
    )
  }

}

export default ImportIcons;
