import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const StatisticsCardWordCount = props => {
  const { className, data, ...rest } = props;
  const classes = useStyles();

  const langCharFreqText = (langCharFreq) => {
    var finalText = '';
    Object.keys(langCharFreq).slice(0,10).map(key=>{
      const value = langCharFreq[key];
      finalText += key + '(' + value + ') ';
      return key;
    })
    return finalText;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
        <CardContent>
            <Grid
            container
            justify="space-between"
            >
                <Grid item>
                    <Typography
                        component={'div'}
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        {props.title}
                    </Typography>
                    <Typography component={'div'} variant="h3">
                        {langCharFreqText(data)}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
  );
};


export default StatisticsCardWordCount;