import React from 'react';
import View from 'components/main/pages/home/views/View';
import no_image from 'resources/images/general/no_image.png';

class SubjectList extends View {

  constructor(props){
      super(props);
      this.init(props);
      this.subjects = this.props.subjects;
      console.log(this.props.subjects);
   }

  componentDidMount(){
    this.init(this.props);
    this.setListScroll('courseSubjectsList');
  }

  changeBackground(id){
    let div = document.getElementById("div1" + id);
    div.style.backgroundColor = "grey";
  }

  removeBackground(id){
    let div = document.getElementById("div1" + id);
    div.style.backgroundColor = "white";
  }

   subjectsList(){
    console.log(this.store);
    const subjects = this.store.subjects.schoolSubjects;
    this.subjects = subjects;
    if(subjects.length === 0){
      return this.subTitle(['No subjects','此班別未有議題','此班别未有议题'])
    }
    if(subjects.length > 1)
    subjects.sort((a, b) => {
      const aDate = new Date(a.createdAt), bDate = new Date (b.createdAt);
      if(bDate !== aDate)
       return bDate - aDate;
      else
        return b.title - a.title;
  });
  console.log(subjects);
    return subjects.slice(0).map((subject, i)=>{
      if(this.store.user.type === 'student' && subject.hide){ return null;}
      return   <div key={"div1" + subject._id} id={"div1" + subject._id} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", position: 'relative', cursor: 'pointer'}}
      onClick={ async ()=>{  this.actions.subjects.copy(subject); let url = this.store.content.cachedUrl[subject.icon];
        if(url == null){
          url = await this.func.url(subject.icon, 'subjectIcon');
        }
        const blob = await this.url.urlToBlob(url);
        this.actions.main.setPhoto({url: url, blob: blob});
   this.actions.content.pullView();}} onMouseOver={()=>this.changeBackground(subject._id)} onMouseLeave={()=>this.removeBackground(subject._id)}>
        {/*this.verGap('3%')*/}
        {/*this.hiddenRowIcon(this.subject.hide, this.props.subject.mlanghku? mlanghku: null)*/}
        {this.rowIcon( this.func.outDated(subject.endDate), this.store.content.cachedUrl[subject.icon])  }
        {/*this.rowIcon(false, this.props.subject.mlanghku? mlanghku: null)*/}
        {this.rowContent(subject.title, subject.description)}
    </div>
   });
  }

  rowContent(title, description){
    const infoStyle = {...this.bs, ...{
      width: '80%',
      height: this.bs.height * 0.12,
      marginLeft: this.bs.height * 0.02
    }};
    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'flex-end'
    }};
    return(
      <div style={infoStyle}>
        {this.rowTitle(title)}
        <div style={rowStyle}>
          {this.textDisplay(description, ['',''], '150%')}
        </div>
      </div>
    )
  }

  rowTitle(title){
    //console.log("title: ", title);
    if(!title){
      return null;
    }
    const nameStyle = {
      width: '100%',
      height: this.bs.height * 0.06,
      fontWeight: 'bold',
      fontSize: this.bs.height * 0.035,
      textAlign: 'left'
    }
    return <div style={nameStyle}>{title}</div>
  }

  rowIcon(passed, defaultUrl){
    const size = this.bs.height * 0.12;
    const iconSize = this.bs.height * 0.11;
    const containerStyle = {...this.ui.styles.border , ...this.ui.styles.container, ...{
      width: size,
      height: size,
      backgroundColor: 'white',
      position: 'relative'
    }}
    const iconStyle = {...this.ui.styles.border, ...{
      maxWidth: iconSize,
      maxHeight: iconSize,
      backgroundColor: 'white'
    }}
    const url = defaultUrl? defaultUrl: this.url.url? this.url.url: no_image;
    return(
      <div style={containerStyle}>
        <img key={url} style={iconStyle} src={url} alt=''/>
      </div>
    )
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.viewStyle()}>
        <div id={'courseSubjectsList'} onScroll={()=>{ this.onScroll('courseSubjectsList'); }} style={{...this.bs, ...this.ui.styles.list}}>
          {this.subjectsList()}
        </div>
      </div>
    )
  }

}

export default SubjectList;
