import React from "react";
import UI from "components/UI";
import Sound from "react-sound";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import cloudDownload from "resources/images/buttons/buttonIcons/cloud_download.png";
import CircularProgress from "@material-ui/core/CircularProgress";

//import icon_play from 'resources/images/buttons/buttonIcons/play_grey.png';
//import icon_stop from 'resources/images/buttons/buttonIcons/stop_grey.png';

class LangRow extends UI {
  constructor(props) {
    super(props);
    this.init(props);
    this.state = {
      filename: props.lang.audio,
      type: "langAudio",
      isPlaying: false,
    };
    this.checkUrl();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.init(newProps);
    const newFilename = newProps.lang.audio;
    if (this.state.filename !== newFilename) {
      this.setState({ filename: newFilename, type: "langAudio" });
      this.checkUrl();
    }
    if (!this.props.autoPlay && newProps.autoPlay && !this.state.isPlaying) {
      this.setState({ isPlaying: true });
    }
  }

  playback() {
    if (this.state.isPlaying) {
      return;
    }
    this.setState({
      isPlaying: true,
    });
  }

  onPlaybackEnd() {
    this.setState({
      isPlaying: false,
    });
    /*
    if(this.props.autoPlay && this.props.onAutoPlayEnd){
      this.props.onAutoPlayEnd();
    }*/
  }

  stopPlayback() {
    if (!this.state.isPlaying) {
      return;
    }
    this.onPlaybackEnd();
  }

  langRow() {
    const lang = this.props.lang;

    const rowStyle = {
      ...this.bs,
      ...{
        width: "100%",
        //height: this.props.single? this.bs.height * 0.37: this.bs.height * 0.185,
        height: !this.props.isGrading
          ? this.props.single
            ? this.bs.height * 0.37
            : this.bs.height * 0.185
          : this.props.single
          ? this.bs.height * 0.47
          : this.bs.height * 0.285,
        backgroundColor: "white",
        borderBottom: "4px solid " + this.ui.colors.ultraLightGrey,
        flexShrink: 0,
      },
    };

    return (
      <div style={rowStyle}>
        {/*this.audio(lang)*/}
        {this.langBar(lang)}
        {this.props.lang.audio && !this.url.url ? (
          <CircularProgress
            style={{ color: this.store.ui.colors.mlangGreen }}
          />
        ) : (
          this.url.url && this.audioPlayer()
        )}
        {this.langText(lang)}
        {this.gap("1%")}
      </div>
    );
  }

  audio(lang) {
    if (this.state.isPlaying && this.url.url) {
      return (
        <Sound
          url={this.url.url}
          playStatus={Sound.status.PLAYING}
          onFinishedPlaying={this.onPlaybackEnd.bind(this)}
        />
      );
    }
    return null;
  }

  downloadAudio(url, name) {
    var link = document.createElement("a");
    link.href = url;
    link.download = name ? name : "true";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  audioPlayer() {
    const containerStyle = {
      ...this.ui.styles.area,
      ...{
        width: !this.props.isGrading ? "97%" : "95%",
        height: !this.props.isGrading
          ? this.bs.height * 0.065
          : this.bs.height * 0.095,
        minHeight: !this.props.isGrading ? "50px" : "80px",
        flexShrink: 0,
      },
    };

    const audioPlayerStyle = {
      height: !this.props.isGrading
        ? this.bs.height * 0.06
        : this.bs.height * 0.095,
      minHeight: !this.props.isGrading ? "50px" : "80px",
    };

    const sizeSmall = [this.bs.height * 0.05, this.bs.height * 0.05];

    return (
      <div style={containerStyle}>
        {!this.props.isGrading ? (
          <AudioPlayer
            style={audioPlayerStyle}
            atuoPlay={false}
            autoPlayAfterSrcChange={false}
            src={this.url.url}
            layout="horizontal-reverse"
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
          />
        ) : (
          <AudioPlayer
            style={audioPlayerStyle}
            atuoPlay={false}
            autoPlayAfterSrcChange={false}
            src={this.url.url}
            customControlsSection={[
              RHAP_UI.ADDITIONAL_CONTROLS,
              RHAP_UI.MAIN_CONTROLS,
            ]}
            customAdditionalControls={[
              this.buttons.langBar(cloudDownload, 0.5, sizeSmall, () => {
                this.downloadAudio(this.url.url, this.props.lang.audio);
              }),
            ]}
          />
        )}
      </div>
    );
  }

  langBar(lang) {
    const barStyle = {
      ...this.ui.styles.area,
      ...{
        width: "100%",
        //height: this.bs.height * 0.065,
        height: this.bs.height * 0.04,
        alignItems: "center",
        justifyContent: "flex-start",
        flexShrink: 0,
      },
    };

    //const sizeSmall = [this.bs.width * 0.04,this.bs.width * 0.04];

    return (
      <div style={barStyle}>
        {this.verGap("1%")}
        {this.textDisplay(
          this.func.langKeyToLangName(lang.key),
          ["60%", ""],
          "75%",
          "left",
          this.ui.colors.deepDarkGrey
        )}
        {/*this.verGap('1%')*/}
        {/*this.url.url && !this.state.isPlaying && this.buttons.langBar(icon_play , 0.25, sizeSmall,()=>{this.playback()})*/}
        {/*this.url.url && this.state.isPlaying && this.buttons.langBar(icon_stop, 0.25, sizeSmall,()=>{this.stopPlayback()})*/}
      </div>
    );
  }

  langText(lang) {
    const style = {
      ...this.ui.styles.button,
      ...{
        width: "95%",
        height: "",
        fontSize: "200%",
        overflow: "auto",
        overflowWrap: "break-word",
        textAlign: "left",
        whiteSpace: "pre-wrap",
      },
    };
    return lang.key === "hyperLink" ? (
      <button style={style}>
        <a href={lang.text} target="_blank" rel="noopener noreferrer">
          {lang.text}
        </a>
      </button>
    ) : (
      <button
        style={style}
        onClick={() => {
          this.actions.main.enlargeText(lang.text);
        }}
      >
        {lang.text}
      </button>
    );
  }

  render() {
    this.init(this.props);
    return this.langRow();
  }
}

export default LangRow;
