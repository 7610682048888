import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import MemoryGameWaitingRow from 'components/main/items/rows/MemoryGameWaitingRow';

class MemoryGameBattleRoom extends SubView{

  constructor(props){
    super(props);
    this.init(props);
  }

  componentWillUnmount(){
    const subView = this.store.content.subView;
    if(subView !== 'memoryGamePlay'){
      this.disConnectionBattle();
    }
  }

  disConnectionBattle(){
    const ws = this.store.main.webSocket;
    if(ws){
      ws.emit('disConnectionBattle', {type: 'waiting', data: {game: this.store.memoryGames.viewingMemoryGamePublish._id}, sender: this.store.user._id});
    }
  }

  sendMessage(key, data){
    const ws = this.store.main.webSocket;
    if(!ws){ return null; }
    ws.emit(key, data);
  }
  
  waittingForBattleList(users){
    return users.map((user, i) => {
      const profile = this.func.getById.profileByUser(user, this.store);
      if(!profile || this.store.user._id === profile.belongTo){ return null; }
      return (
        <MemoryGameWaitingRow
          app={this.app}
          profile={profile}
          key={i}
          onClick={()=>{ 
            const data = {game: this.store.memoryGames.viewingMemoryGamePublish._id, user: this.store.user._id, opponent: profile.belongTo};
            this.sendMessage('requestForBattle', data); 
            this.actions.modal.loadingMessage(['Waiting for opponent response...', '正在等待對手回覆...', '正在等待对手回覆...']);
          }}/>
      )
    })
  }

  render(){
    const users = this.store.memoryGames.battleRoomWaitingUsers;

    if(users.length <= 0 || (users.length === 1 && users.indexOf(this.store.user._id) > - 1)){
      return(
        <div style={this.subViewStyle()}>
          {this.subTitle(['Waiting for the opponent...','正在等待對手...','正在等待对手...'])}
        </div>
      );
    }

    return(
      <div style={this.subViewStyle()}>
        {users && this.waittingForBattleList(users)}
      </div>
    )
  }

}


export default MemoryGameBattleRoom;
