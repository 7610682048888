import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const viewSchoolTag = (schoolTag) =>{
  return {
    type: 'viewSchoolTag',
    payload: schoolTag
  }
}

export function get(){
    //console.log(newSchool)
    return async function (dispatch) {
      actions.connecting(dispatch);
  
      let err, res;
  
      [err, res] = await to(axios.post(api + '/schoolTag/get'));
      if(err){ actions.connectionError(dispatch); return; }
  
      if(res.data.result === 'success'){
        dispatch({type: 'updateSchoolTags', payload: res.data.schoolTags});
        dispatch({type: 'hideModal'});
      }else{
        dispatch({type: 'hideModal'});
      }
  
    }
  }

export function add(schoolTag){
  //console.log(newSchool)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, addRes;

    [err, addRes] = await to(axios.post(api + '/schoolTag/add', { data: schoolTag }));
    if(err){ actions.connectionError(dispatch); return; }


    if(addRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Add schoolTag succeed !', '成功創建學校標籤 !', '成功创建学校标签 !']});
      dispatch({type: 'updateSchoolTags', payload: [addRes.data.newSchoolTag]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Add schoolTag failed! Please try again!', '創建學校標籤! 請再試一次!', '创建学校标签! 请再试一次!']});
    }

  }
}

export function edit(data){
  //console.log(newSchool)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, addRes;

    [err, addRes] = await to(axios.post(api + '/schoolTag/edit', { data: data }));
    if(err){ actions.connectionError(dispatch); return; }


    if(addRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Edit schoolTag succeed !', '成功修改學校標籤 !', '成功修改学校标签 !']});
      dispatch({type: 'updateSchoolTags', payload: [addRes.data.updatedSchoolTag]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Edit schoolTag failed! Please try again!', '修改學校標籤失敗! 請再試一次!', '修改学校标签失敗! 请再试一次!']});
    }

  }
}
