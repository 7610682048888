import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setAccess = (access) =>{
  return {
    type: 'setAccess',
    payload: access
  }
}
  
export function updateByExcel(data){
  return async function (dispatch) {
      let err, res;

      const config = {
        onUploadProgress: progressEvent => {
           var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
           percentCompleted = percentCompleted > 89? 90: percentCompleted;
           dispatch({type: 'loadingPercent', payload: percentCompleted});
           dispatch({type: 'loadingStatus', payload: 'on'});
        },
        headers: { token: 'mLangWelcomeA108' }
     };
 
     var excelFile = new FormData();
     excelFile.append('file', data);

      [err, res] = await to(axios.post(api + '/access/excel/update', excelFile, config));

      if(err){actions.connectionError(dispatch); return;}
      if(res.data){
        dispatch({type: 'message', payload: ['Upload succeed!', '上載成功!', '上载成功!']});
        dispatch({type: 'loadingPercent', payload: 0});
        dispatch({type: 'loadingStatus', payload: 'off'});
      }else {
        dispatch({type: 'message', payload: ['Upload failed!', '上載失敗!', '上載失敗!']});
        dispatch({type: 'loadingPercent', payload: 0});
        dispatch({type: 'loadingStatus', payload: 'off'});
      }
  }
}