export function filter(schoolTags, viewingCourseTags, type){
    var data = [];
    for(var i=0;i<schoolTags.length;i++){
        const found = schoolTags[i].courseTags.some(tag => viewingCourseTags.indexOf(tag) >= 0);
        if(found){
            if(type === 'subject'){ data = [...data, ...schoolTags[i].subjectTags]; }
            else if(type === 'project'){ data = [...data, ...schoolTags[i].projectTags]; }
        }
    }
    return data;
}