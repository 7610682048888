import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();


export const viewMemoryGameCards = (cards) =>{
  return {
    type: 'viewMemoryGameCards',
    payload: cards
  }
}

export const viewMemoryGamePublish = (memoryGamePublish) =>{
  return {
    type: 'viewMemoryGamePublish',
    payload: memoryGamePublish
  }
}

export const viewMemoryGameSubmit = (memoryGameSubmit) =>{
  return {
    type: 'viewMemoryGameSubmit',
    payload: memoryGameSubmit
  }
}

export const viewMemoryGameBattleSubmit = (memoryGameBattleSubmit) =>{
  return {
    type: 'viewMemoryGameBattleSubmit',
    payload: memoryGameBattleSubmit
  }
}

export const setBattleRoomWaitingUsers = (users) =>{
  return {
    type: 'setBattleRoomWaitingUsers',
    payload: users
  }
}

export const setBattleRoomInfo = (data) =>{
  return {
    type: 'setBattleRoomInfo',
    payload: data
  }
}

export const setBattleRoomScore = (data) =>{
  return {
    type: 'setBattleRoomScore',
    payload: data
  }
}

export const setMemoryGameMode = (mode) =>{
  return {
    type: 'setMemoryGameMode',
    payload: mode
  }
}

export function addPublish(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/publish/add', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit publish succeed!', '成功提交發佈!', '成功提交发布!']});
      dispatch({type: 'updateMemoryGamePublishes', payload: [publishRes.data.updatedMemoryGamePublish]});
      dispatch({type: 'viewMemoryGamePublish', payload: publishRes.data.updatedMemoryGamePublish});
      dispatch({type: 'updateCourses', payload: [publishRes.data.editedCourse]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit publish! Please make sure the school code is valid!', '發佈提交失敗! 請確保學校代碼輸入正確!', '发布提交失败! 请确保学校代码输入正确!']});
    }
  }
}


export function editPublish(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/publish/edit', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit publish succeed!', '成功提交發佈!', '成功提交发布!']});
      dispatch({type: 'updateMemoryGamePublishes', payload: [publishRes.data.updatedMemoryGamePublish]});
      dispatch({type: 'viewMemoryGamePublish', payload: publishRes.data.updatedMemoryGamePublish});
      dispatch({type: 'updateCourses', payload: [publishRes.data.editedCourse]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit publish! Please make sure the school code is valid!', '發佈提交失敗! 請確保學校代碼輸入正確!', '发布提交失败! 请确保学校代码输入正确!']});
    }
  }
}

export function addSubmit(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/submit/add', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit succeed!', '成功提交!', '成功提交!']});
      dispatch({type: 'updateMemoryGameSubmits', payload: [publishRes.data.updatedMemoryGameSubmit]});
      dispatch({type: 'viewMemoryGameSubmit', payload: publishRes.data.updatedMemoryGameSubmit});
      dispatch({type: 'updateMemoryGamePublishes', payload: [publishRes.data.updatedMemoryGamePublish]});
      dispatch({type: 'viewMemoryGamePublish', payload: publishRes.data.updatedMemoryGamePublish});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit!', '提交失敗!', '提交失败!']});
    }
  }
}

export function editSubmit(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/submit/edit', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit succeed!', '成功提交!', '成功提交!']});
      dispatch({type: 'updateMemoryGameSubmits', payload: [publishRes.data.updatedMemoryGameSubmit]});
      dispatch({type: 'viewMemoryGameSubmit', payload: publishRes.data.updatedMemoryGameSubmit});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit!', '提交失敗!', '提交失败!']});
    }
  }
}

export function addBattleSubmit(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/battleSubmit/add', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      dispatch({type: 'updateMemoryGameBattleSubmits', payload: [publishRes.data.updatedMemoryGameBattleSubmit]});
      dispatch({type: 'viewMemoryGameBattleSubmit', payload: publishRes.data.updatedMemoryGameBattleSubmit});
      dispatch({type: 'updateMemoryGamePublishes', payload: [publishRes.data.updatedMemoryGamePublish]});
      dispatch({type: 'viewMemoryGamePublish', payload: publishRes.data.updatedMemoryGamePublish});
    }else{
    }
  }
}

export function editBattleSubmit(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/memoryGame/battleSubmit/edit', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      dispatch({type: 'updateMemoryGameBattleSubmits', payload: [publishRes.data.updatedMemoryGameBattleSubmit]});
      dispatch({type: 'viewMemoryGameBattleSubmit', payload: publishRes.data.updatedMemoryGameBattleSubmit});
    }else{
    }
  }
}


export function getRanking(memoryGameId){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/memoryGame/getRanking', { data: memoryGameId }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'setMemoryGameRanking', payload: {memoryGameId: memoryGameId, ranking: res.data.ranking}});
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get memory game ranking!', '無法查閱遊戲排行榜!', '无法查阅游戏排行榜!']});
    }
  }
}

export function getBattleRanking(memoryGameId){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/memoryGame/getBattleRanking', { data: memoryGameId }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'setMemoryGameBattleRanking', payload: {memoryGameId: memoryGameId, battleRanking: res.data.ranking}});
      dispatch({type: 'updateProfiles', payload: res.data.profiles});
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get memory game ranking!', '無法查閱遊戲排行榜!', '无法查阅游戏排行榜!']});
    }
  }
}
