import React, { useState, useEffect } from 'react';
import FlipCard from './FlipCard';
import { useTrail, useTransition, animated as a } from "react-spring";
import CircularProgress from '@material-ui/core/CircularProgress';
import cardIcon from 'resources/images/icons/cards_lightgrey.png';
import trophyIcon from 'resources/images/icons/trophy.png';
import timerIcon from 'resources/images/icons/timer.png';
import battleIcon from 'resources/images/icons/battle.png'

const PlayMemoryGame = ({cards, app, that}) => {
    const [game, setGame] = useState(cards);
    const [gameTrail, setGameTrail] = useTrail(cards.length, () => ({ cardBackOpacity: 1, cardFrontOpacity: 0 }));
    const [display, setDisplay] = useState({cardFront: true, cardBack: true});
    const [play, setPlay] = useState(false);
    const [flippedCount, setFlippedCount] = useState(0);
    const [flippedIndexes, setFlippedIndexes] = useState([]);
    const [attempt, setAttempt] = useState(0);
    const [timer, setTimer] = useState({hours: 0, minutes: 0, seconds: 0});
    const [score, setScore] = useState(0);
    const [combo, setCombo] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const [icons, setIcons] = useState({});
    const mode = app.store.memoryGames.mode;
    const comboTrans = useTransition(combo, null , {
        from: {  opacity: 0, transform: 'translate3d(0, -40px, 0)' },
        enter: { opacity: 1,  transform: 'translate3d(0, 0, 0)' },
        leave: { opacity: 0,  transform: 'translate3d(0, -40px, 0)' },
    });
    const isMountedRef = React.useRef(false);

    const statusStyle = {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
    }

    const cardsStyle = {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '5%',
    }

    const cardStyle = {
        width: '150px',
        height: '150px',
        marginBottom: '20px',
        marginLeft: '5%'
    }

    function displayCombo(){
        const scale = [that.bs.height * 0.10, ''];
        const fontSize = that.bs.height * 0.035;

        const container = {
            width: '18%',
            position: 'absolute',
            right: 0
        }

        const comboStyle = {
            width: scale[0],
            height: scale[1],
            margin: '1%',
            fontSize: fontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            color: '#91c33b',
            flexShrink: 0,
        }

  
        return (
            <div style={container}>
                {comboTrans.map(({ item, key, props }) => {
                    const comboStr = 'x ' + item;
                    return (
                        <a.div key={key} style={{...props, ...comboStyle}}>{comboStr}</a.div>
                    )
                })}
            </div>
        )
    }

    function rowInfo(){
        const rowStyle = {...that.ui.styles.area, ...{
          width: '100%',
          height: '',
          alignItems: 'flex-end',
          justifyContent: 'center'
        }}
        const iconSize = that.bs.height * 0.05;
        const textScale = [that.bs.height * 0.10, ''];
        const fontSize = that.bs.height * 0.035;
        const time = timer.hours + ':' + timer.minutes + ':' + timer.seconds;

        return(
          <div style={rowStyle}>
            {that.icon(timerIcon, [iconSize, iconSize])}
            {that.textDisplay(time, [that.bs.height * 0.15, ''], fontSize, 'center')}
            {that.verGap('3%')}
            {that.icon(cardIcon, [iconSize, iconSize])}
            {that.textDisplay(attempt, textScale, fontSize, 'center')}
            {that.verGap('3%')}
            {that.icon(trophyIcon, [iconSize, iconSize], 0.2)}
            {that.textDisplay(score, textScale, fontSize, 'center')}
            {combo > 0? displayCombo(): null}
          </div>
        )
    }

    function battleRowInfo(){
        const rowStyle = {...that.ui.styles.area, ...{
            width: '100%',
            height: '',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        }};
    
        const containerStyle = {
            width: '',
            display: 'flex',
            flexDirection: 'row',
            flexFlow: 'row warp'
        }

        const itemStyle = {
            width: '',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        };

        const iconSize = that.bs.height * 0.05;
        const textScale = [that.bs.height * 0.15, ''];
        const fontSize = that.bs.height * 0.025;

        const battleRoomScore = app.store.memoryGames.battleRoomScore;
        var data = [...profiles];
        for(var i=1; i<profiles.length;i++){ if(i % 2 !== 0){ data.splice(i, 0, 'battleIcon'); } }


        return (
            <div style={rowStyle}>
                {data.map((item, i) => {                  
                    return (
                        <div key={'container-' + i} style={containerStyle}>
                            {item === 'battleIcon'?
                                <div key={'status-' + i} style={{...itemStyle, width: that.bs.height * 0.30, justifyContent: 'center'}}>
                                    {that.icon(battleIcon, [that.bs.height * 0.065, that.bs.height * 0.065], 0.3)}
                                </div>: 
                                <div key={'status-' + i} style={itemStyle}>
                                    {that.icon(icons[item.icon], [iconSize, iconSize], 1)}
                                    {that.textDisplay(item.name, textScale, fontSize, 'left')}
                                    {that.icon(trophyIcon, [that.bs.height * 0.035, that.bs.height * 0.035], 0.3)} 
                                    {that.textDisplay(battleRoomScore[item.belongTo]? battleRoomScore[item.belongTo]: 0, [that.bs.height * 0.035, ''], fontSize, 'left')}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    function convertTimeToNumber(timer){
        const hrs = timer.hours * 3600;
        const mins = timer.minutes * 60;
        const num = hrs + mins + timer.seconds;
        return num; 
    }

    function submitResult(type, extraData){
        if(type === 'self'){
            const memoryGamePublish = app.store.memoryGames.viewingMemoryGamePublish;
            const memoryGameSubmit = app.store.memoryGames.viewingMemoryGameSubmit;
            const questions = app.store.memoryGames.viewingCards;
            const user = app.store.user;

            const data = {
                memoryGamePublish: memoryGamePublish._id,
                submittedBy: user._id,
                questions: questions,
                score: score,
                attempt: attempt,
                duration: convertTimeToNumber(timer),
                createdAt: new Date()
            }

            if(Object.keys(memoryGameSubmit).length === 0){ app.actions.memoryGames.addSubmit(data); }
            else{ app.actions.memoryGames.editSubmit(data); }
        }
        else if(type === 'battle'){
            const memoryGamePublish = app.store.memoryGames.viewingMemoryGamePublish;
            const memoryGameBattleSubmit = app.store.memoryGames.viewingMemoryGameBattleSubmit;
            const winner = extraData.winner;
            const battleRoomInfo = {...app.store.memoryGames.battleRoomInfo, winner};
            const user = app.store.user;

            const data = {
                memoryGamePublish: memoryGamePublish._id,
                submittedBy: user._id,
                score: Object.keys(memoryGameBattleSubmit).length === 0? 1: memoryGameBattleSubmit.score,
                info: battleRoomInfo,
                createdAt: new Date()
            }

            if(Object.keys(memoryGameBattleSubmit).length === 0){ app.actions.memoryGames.addBattleSubmit(data); }
            else{ app.actions.memoryGames.editBattleSubmit(data); }
        }
    }

    function disConnectionBattle(){
        const ws = app.store.main.webSocket;
        if(ws){
            ws.emit('disConnectionBattle', {type: 'battling', data: app.store.memoryGames.battleRoomInfo, sender: app.store.user._id});
        }
    }

    function initWebSocket(){
        const ws = app.store.main.webSocket;
        if(!ws){ return null; }

        window.onbeforeunload = disConnectionBattle.bind(this);

        ws.on('battleScore', message => {  
            app.actions.memoryGames.setBattleRoomScore(message);
        });
        ws.on('failedToBattle', message => {
            if(message === 'quitBattle'){
                app.actions.modal.message(['The opponent leaves', '對手中途離開', '对手中途离开']);
                app.actions.content.setSubView('memoryGameStart');
                disConnectionBattle();
                app.actions.memoryGames.setBattleRoomWaitingUsers([]);
                app.actions.memoryGames.setBattleRoomInfo({});
                app.actions.memoryGames.setBattleRoomScore({});
            }
        });
        ws.on('finishedBattle', message => {
            ws.off('failedToBattle');
            if(app.store.user._id === message.winner){ submitResult('battle', {winner: message.winner} ); }
            const profile = that.func.getById.profileByUser(message.winner, app.store);
            app.actions.modal.message([profile? profile.name + ' win the game': null, profile? profile.name + ' 勝出遊戲': null, profile? profile.name + ' 胜出游戏': null]);
            app.actions.content.setSubView('memoryGameStart');
            app.actions.memoryGames.setBattleRoomWaitingUsers([]);
            app.actions.memoryGames.setBattleRoomInfo({});
            app.actions.memoryGames.setBattleRoomScore({});
        });
        ws.on('disConnectionBattle', () => {
          ws.close();
        });   
    }

    function sendMessage(key, data){
        const ws = app.store.main.webSocket;
        if(!ws){ return null; }
        if(key === 'battleScore'){
            const battleData = {};
            battleData[app.store.user._id] = data;
            ws.emit('battleScore', {data: app.store.memoryGames.battleRoomInfo, battleData: battleData});
        }
        if(key === 'finishedBattle'){
            ws.emit('finishedBattle', {data: app.store.memoryGames.battleRoomInfo, winner: app.store.user._id});
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        if(isMountedRef.current){
            setGame(cards);
            setGameTrail(cards.length, () => ({}));
            initWebSocket();
            
        }
        return () => { 
            isMountedRef.current = false; 
            if(app.store.main.webSocket){
                disConnectionBattle();
                app.actions.memoryGames.setBattleRoomWaitingUsers([]);
                app.actions.memoryGames.setBattleRoomInfo({});
                app.actions.memoryGames.setBattleRoomScore({});
            }
        };
        // eslint-disable-next-line
    }, [cards]);

    useEffect(() => {
        let isMounted = true;
        var displayCardFrontTimer, displayCardBackTimer, playGameTimer;

        if(!play && display.cardFront){
            displayCardFrontTimer = setTimeout(() => {
                if(isMounted){
                    setGameTrail({cardBackOpacity: 0, cardFrontOpacity: 1});
                    setDisplay({cardFront: false, cardBack: true});
                }
            }, 1000);
        }

        if(!play && !display.cardFront && display.cardBack){
            displayCardBackTimer = setTimeout(() => {
                if(isMounted){
                    setGameTrail({cardBackOpacity: 1, cardFrontOpacity: 0});
                    setDisplay({cardFront: false, cardBack: false});
                }
            }, 2000);
        }

        if(!play && !display.cardFront && !display.cardBack){
            playGameTimer = setTimeout(() => {
                if(isMounted){ 
                    setPlay(true); 
                }
            }, cards.length * 150);
        }

        return () => {
            clearTimeout(displayCardFrontTimer);
            clearTimeout(displayCardBackTimer);
            clearTimeout(playGameTimer);   
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [display, play]);
  
    useEffect(() => {
      let isMounted = true;
      var gameTimer = null;
      if(isMounted && play && mode !== 'battle'){
        gameTimer = setTimeout(() => {
            var secs = timer.seconds + 1;
            var mins = timer.minutes;
            var hrs = timer.hours;
            if(secs > 59){
              mins++;
              secs = 0;
            }
            if(mins > 59){
              hrs++;
              mins = 0;
            }
            if(isMounted){
                setTimer({ hours: hrs, minutes: mins, seconds: secs });
            }
          }, 1000);
      }

      const finished = !game.some(card => !card.flipped);
      if(isMounted && finished && mode !== 'battle'){
        if(gameTimer){ clearTimeout(gameTimer); }
        app.actions.modal.message(['Game is finished', '遊戲結束', '游戏结束']);
        if(mode === 'test'){ submitResult('self'); }
      }

      if(isMounted && finished && mode === 'battle'){
        if(gameTimer){ clearTimeout(gameTimer); }
        sendMessage('finishedBattle');
      }

      return () => { clearTimeout(gameTimer); isMounted = false; }
      // eslint-disable-next-line
    }, [timer, game, play, mode]);

    useEffect(() => {
        let isMounted = true;
        const battleRoomInfo = app.store.memoryGames.battleRoomInfo;
        const users = [battleRoomInfo.user, battleRoomInfo.opponent];
        var newProfiles = [];
        
        function getProfiles(data){
            for(var i=0; i<data.length; i++){
                const profile = that.func.getById.profileByUser(data[i], app.store);
                if(profile){ newProfiles.push(profile); }
            }
            newProfiles.sort((a, b) =>{ if(a.belongTo === app.store.user._id){ return -1;} return 0; })
            setProfiles(newProfiles);
        }

        async function getIcons(data){
            var newIcons = {};
            for(var i=0; i<data.length; i++){
                const icon = await that.func.url(data[i].icon, 'profileIcon');
                if(icon){ newIcons[data[i].icon] = icon; }
            }
            setIcons(newIcons);
        }

        if(isMounted && mode === 'battle'){
            getProfiles(users);
            getIcons(newProfiles);
        }

        return () => { isMounted = false; }
        // eslint-disable-next-line
    }, [game])
  
 
    if (flippedIndexes.length === 2) {
        const match = game[flippedIndexes[0]].cardId === game[flippedIndexes[1]].cardId;
        setAttempt(attempt + 1);

        if(match){
            const newGame = [...game];
            newGame[flippedIndexes[0]].flipped = true;
            newGame[flippedIndexes[1]].flipped = true;
            setGame(newGame);
            if(mode === 'battle'){ setScore(score + 1); sendMessage('battleScore', score + 1); }
            else{ setScore(combo > 0 ? score + combo + 1: score + 1); }
            setCombo(combo + 1);

            const newIndexes = [...flippedIndexes];
            newIndexes.push(false);
            setFlippedIndexes(newIndexes);

        }else{
            setCombo(0);

            const newIndexes = [...flippedIndexes];
            newIndexes.push(true);
            setFlippedIndexes(newIndexes);
        }
    }

    if (game.length === 0) return <div><CircularProgress /></div>
    else {
      return (
        <div>
            <div style={statusStyle}>
                {mode === 'battle'? battleRowInfo(): rowInfo()}
            </div>
            <div id="cards" style={cardsStyle}>
            {gameTrail.map((trail, index) => 
                <div style={cardStyle} key={index}>
                    <FlipCard
                        id={index}
                        game={game}
                        play={play}
                        style={trail}
                        flippedCount={flippedCount}
                        setFlippedCount={setFlippedCount}
                        flippedIndexes={flippedIndexes}
                        setFlippedIndexes={setFlippedIndexes}
                        isMountedRef={isMountedRef}
                        app={app}
                    />
                </div>
            )}
            </div>
        </div>
      )
    }
  }

  export default PlayMemoryGame;