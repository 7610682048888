import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setAgreement = (agreement) =>{
  return {
    type: 'setAgreement',
    payload: agreement
  }
}


export function add(agreement){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;

    [err, res] = await to(axios.post(api + '/agreement/add', { data: agreement}));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      actions.updateSuccess(dispatch);
      dispatch({type: 'setAgreement', payload: res.data.agreement});
      dispatch({type: 'backToHome'});
    }else{
      actions.updateFailed(dispatch);
    }
  }
}
