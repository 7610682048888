import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import MemoryGameBattleRankingRow from 'components/main/items/rows/MemoryGameBattleRankingRow';

class MemoryGameBattleRanking extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.getMemoryGameRanking();
  }

  getMemoryGameRanking(){
    this.memoryGameId = this.store.memoryGames.viewingMemoryGamePublish._id;
    this.ranking = this.store.memoryGames.battleRankings[this.memoryGameId];
    this.actions.memoryGames.getBattleRanking(this.memoryGameId);
  }

  rankingList(ranking){
    return ranking.map((data,i)=>{
      return <MemoryGameBattleRankingRow key={i} app={this.app} data={data} index={i}/>
    })
  }

  render(){
    this.memoryGameId = this.store.memoryGames.viewingMemoryGamePublish._id;
    this.ranking = this.store.memoryGames.battleRankings[this.memoryGameId];

    return(
      <div style={this.subViewStyle()}>
        {this.ranking && this.rankingList(this.ranking)}
      </div>
    )
  }

}

export default MemoryGameBattleRanking;
