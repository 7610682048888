import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';
import Files from 'components/main/pages/home/views/home/contents/Files';
import icon_add from 'resources/images/buttons/icon_add.png';

class ProjectManagement extends SubView {

  render(){
    this.init(this.props);
    const data = this.getData();
    if(!data){ return null; }

    return(
      <Files app={this.app} data={data}/>
    )
  }

  getData(){
    const subView = this.store.content.subView;
    var data = null;
    if(subView === 'projectManagement'){
      data = [{
        icon: icon_add,
        title: this.func.multiLang('Default Pictures','預設照片','预设照片'),
        type: null,
        onClick: ()=>{this.actions.content.pushView('projectIcons')}
      }]
    }
    return data;
  }

}

export default ProjectManagement;
