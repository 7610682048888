import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setAction = (action) =>{
  return {
    type: 'setAction',
    payload: action
  }
}

export const selectGradeCard = (gradeCard) =>{
  return {
    type: 'selectGradeCard',
    payload: gradeCard
  }
}

export const gradeCard = (id, index, gradeCard) =>{
  return {
    type: 'gradeCard',
    payload: { studentProjectId: id, index: index, gradeCard: gradeCard}
  }
}

export const viewCards = (cards) =>{
  return {
    type: 'viewCards',
    payload: cards
  }
}

export const viewCard = (card) =>{
  return {
    type: 'viewCard',
    payload: card
  }
}

export const gradeCards = (id, cards) =>{
  return {
    type: 'gradeCards',
    payload: { studentProjectId: id, cards: cards}
  }
}

export const deleteAction = (action) =>{
  return {
    type: 'deleteAction',
    payload: action
  }
}

export const recoverAction = (action) =>{
  return {
    type: 'recoverAction',
    payload: action
  }
}

export const pushDeleteCards = (card) =>{
  return {
    type: 'pushDeleteCards',
    payload: card
  }
}

export const pushRecoverCards = (card) =>{
  return {
    type: 'pushRecoverCards',
    payload: card
  }
}

export const removeDeleteCard = (card) =>{
  return {
    type: 'removeDeleteCard',
    payload: card
  }
}

export const removeRecoverCard = (card) =>{
  return {
    type: 'removeRecoverCard',
    payload: card
  }
}

export const updateDeletedCards = (card) =>{
  return {
    type: 'updateDeletedCards',
    payload: card
  }
}

/*
export function addAnnotation(data){
  return async function(dispatch){
    actions.connecting(dispatch);

    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/card/annotation/add', { data: data } ))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Add annotation succeed!', '成功添加註釋!', '成功添加注释!']});
      dispatch({type: 'updateCards', payload: [updateRes.data.updatedCard]});
      dispatch({type: 'viewCard', payload: updateRes.data.updatedCard});
      //dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Add annotation failed! Please try again!', '添加註釋失敗! 請再試一次!', '添加注释失败! 请再试一次!']});
    }
  }
}
*/
export function shareToProjects(data){
  return async function(dispatch){
    actions.connecting(dispatch);

    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/card/shareToProjects', { data: data } ))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['share the card succeed!', '成功分享卡片!', '成功分享卡片!']});
      dispatch({type: 'updateProjects', payload: updateRes.data.updatedProjects});
      dispatch({type: 'updateCards', payload: [updateRes.data.updatedCard]});
      dispatch({type: 'viewCard', payload: updateRes.data.updatedCard});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['get delete cards failed! Please try again!', '取得卡片失敗! 請再試一次!', '取得卡片失败! 请再试一次!']});
    }
  }
}

export function getDeletedCards(dataUser, dataStudentProject){
  return async function(dispatch){
    actions.connecting(dispatch);

    const user = dataUser;
    const studentProject = dataStudentProject;

    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/card/getDeletedCard', { data: { user: user, studentProject: studentProject }}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'updateDeletedCards', payload: updateRes.data.deletedCards});
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['get delete cards failed! Please try again!', '取得卡片失敗! 請再試一次!', '取得卡片失败! 请再试一次!']});
    }
  }
}

export function recoverCard(data){
  return async function(dispatch){
    actions.connecting(dispatch);

    const card = data;
    let err, updateRes;

    [err, updateRes] = await to(axios.post(api + '/card/status', { data: { card: card, hide: false }}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Recover card succeed!', '成功修復卡片!', '成功修復卡片!']});
      dispatch({type: 'updateCards', payload: [updateRes.data.updatedCard]});
      dispatch({type: 'updateProfiles', payload: [updateRes.data.updatedProfile]});
      dispatch({type: 'updateStudentProjects', payload: [updateRes.data.updatedStudentProject]});
      dispatch({type: 'viewStudentProject', payload: updateRes.data.updatedStudentProject});
      dispatch({type: 'updateDeletedCards', payload: updateRes.data.deletedCard});
      dispatch({type: 'hideModal'});
      //dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Recover card failed! Please try again!', '修復失敗! 請再試一次!', '修復失败! 请再试一次!']});
    }
  }
}

export function deleteCard(data){
  return async function(dispatch){
    actions.connecting(dispatch);
    const card = data;
    let err, updateRes;
    [err, updateRes] = await to(axios.post(api + '/card/status', { data: { card: card, hide: true }}))
    if(err){actions.connectionError(dispatch); return;}

    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Delete card succeed!', '成功刪除卡片!', '成功删除卡片!']});
      dispatch({type: 'updateCards', payload: [updateRes.data.updatedCard]});
      dispatch({type: 'updateProfiles', payload: [updateRes.data.updatedProfile]});
      dispatch({type: 'updateStudentProjects', payload: [updateRes.data.updatedStudentProject]});
      dispatch({type: 'viewStudentProject', payload: updateRes.data.updatedStudentProject});
      dispatch({type: 'hideModal'});
      //dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Delete card failed! Please try again!', '刪除失敗! 請再試一次!', '删除失败! 请再试一次!']});
    }
  }
}

export function like(cardId, userId){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, cardRes;
    [err, cardRes] = await to(axios.post(api + '/card/like', {data: { cardId: cardId, userId: userId }}));
    if(err){actions.connectionError(dispatch); return;}

    if(cardRes.data.result === 'success'){
      dispatch({type: 'updateCards', payload: [cardRes.data.updatedCard]});
      dispatch({type: 'viewCard', payload: cardRes.data.updatedCard});
      dispatch({type: 'hideModal'});
    }
  }
}

export function studentReadCard(cardId){
  return async function (dispatch) {
    let err, cardRes;
    [err, cardRes] = await to(axios.post(api + '/card/studentRead', {data: {cardId: cardId}}));
    if(err){actions.connectionError(dispatch); return;}

    if(cardRes.data.result === 'success'){
      dispatch({type: 'updateCards', payload: [cardRes.data.updatedCard]});
    }
  }
}

export function saveGradingCards(projectId, studentProjectId, gradingCards, featuredCardsLength){
  //console.log(gradingCards)
  return async function (dispatch) {
    actions.connecting(dispatch);

    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'audioComment'}
    };

    var cardFile = new FormData();
    for(var i=0;i<gradingCards.length;i++){
      if(gradingCards[i].audioCommentEdited && gradingCards[i].audioCommentBlob){
        cardFile.append('files', gradingCards[i].audioCommentBlob, 'audioComment_' + i + '.wav');
      }
    }

    let err, uploadRes, cardRes;
    [err, uploadRes] = await to(axios.post(api + '/upload', cardFile, config))
    if(err){ actions.connectionError(dispatch); return; }
    dispatch({type: 'loadingPercent', payload: 100});

    const filenames = uploadRes.data.filenames;
    var cardsToUpdate = [];
    for(var j=0;j<gradingCards.length;j++){
      if(gradingCards[j].audioCommentEdited || gradingCards[j].edited){
        gradingCards[j].edited = false;
        var cardToUpdate = {...gradingCards[j]};
        if(gradingCards[j].audioCommentEdited){
          cardToUpdate = {...cardToUpdate, ...{
            audioComment: getFile(filenames, j)
          }}
        }
        cardsToUpdate.splice(0,0,cardToUpdate);
      }
    }
    [err, cardRes] = await to(axios.post(api + '/card/grade', { data: { projectId: projectId, studentProjectId: studentProjectId, cards: cardsToUpdate, len: featuredCardsLength}}))
    if(err){actions.connectionError(dispatch); return;}

    if(cardRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Grading card succeed!', '成功評核卡片!', '成功评核卡片!']});
      dispatch({type: 'pullView'});
      dispatch({type: 'updateCards', payload: cardRes.data.updatedCards});
      dispatch({type: 'updateProfiles', payload: [cardRes.data.updatedProfile]});
      dispatch({type: 'saveGradeCard', payload: { studentProjectId: studentProjectId}});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }else{
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Grading card failed! Please try again!', '評核失敗! 請再試一次!', '评核失败! 请再试一次!']});
    }
  }
}

export function getCards(cardsId){
  //console.log(cardsId);
  return async function (dispatch) {
    let err, cardsRes;
    [err, cardsRes] = await to(axios.post(api + '/card/getMultiple', { data: { cards: cardsId}}))
    if(err){actions.connectionError(dispatch); return;}

    if(cardsRes.data.result === 'success'){
      dispatch({type: 'updateLangs', payload: cardsRes.data.langs});
      dispatch({type: 'updateCards', payload: cardsRes.data.cards});
      dispatch({type: 'updateProfiles', payload: cardsRes.data.profiles});
    }else{
      console.log('get cards failed!')
    }
  }
}

export function editCard(data, functions){
  return async function (dispatch) {
    actions.connecting(dispatch);
    const card = data.card;
    const newIcon = data.newIcon;
    const iconAttr = data.iconAttr;
    const editLangs = data.editLangs;
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'card', rotation: iconAttr.rotation}
    };

    var cardFile = new FormData();

    let err, uploadRes, updateRes, editPhotoRes;
    if(newIcon){
      cardFile.append('files', newIcon, 'cardIcon.png');
    }

    for(var i=0;i<editLangs.length;i++){
      if(editLangs[i].audioBlob){
        cardFile.append('files', editLangs[i].audioBlob, 'langAudio_' + i + '.wav');
      }
    }
    //{ headers: { type: 'card'}}

    [err, uploadRes] = await to(axios.post(api + '/upload', cardFile, config))
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});

    const filenames = uploadRes.data.filenames;
    var cardIcon;
    var langAudios = [];
    for(var j=0;j<filenames.length;j++){
      const splted = filenames[j].split('-');
      if(splted[1] === 'cardIcon.png'){
        cardIcon = filenames[j];
      }else{
        langAudios.splice(0,0, filenames[j]);
      }
    }

    if(newIcon){ card.icon = cardIcon; }

    if(!newIcon && iconAttr.rotation !== 0){
      [err, editPhotoRes] = await to(axios.post(api + '/card/photo/edit', { data: { fileName: card.icon, type: 'cardIcon', iconAttr: iconAttr}}));
      if(err){actions.connectionError(dispatch); return;}
      if(editPhotoRes.data.result === 'success'){ card.icon = editPhotoRes.data.icon; }
    }

    const langs = [];
    for(var k=0;k<editLangs.length;k++){
      const lang = {
        key: editLangs[k].key,
        text: editLangs[k].text,
        audio: editLangs[k].audioBlob? getFile(langAudios, k): editLangs[k].defaultAudio? editLangs[k].defaultAudio: '',
        _id: editLangs[k]._id
      }
      langs.splice(0,0,lang);
    }

    [err, updateRes] = await to(axios.post(api + '/card/edit', { data: { card: card, langs: langs }}))
    if(err){actions.connectionError(dispatch); return;}


    if(updateRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Edit card succeed!', '成功修改卡片!', '成功修改卡片!']});
      dispatch({type: 'updateCards', payload: [updateRes.data.updatedCard]});
      dispatch({type: 'updateLangs', payload: updateRes.data.updatedLangs});

      dispatch({type: 'setEditLangs', payload: []});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Edit card failed! Please try again!', '修改失敗! 請再試一次!', '修改失败! 请再试一次!']});
    }

  }
}

export function addCard(data, len){
  //console.log(data.resubmitCard);
  return async function (dispatch) {
    actions.connecting(dispatch);
    const iconAttr = data.iconAttr;
    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: {type: 'card', rotation: iconAttr.rotation}
    };

    var cardFile = new FormData();
    if(!data.resubmit){
      cardFile.append('files', data.icon, 'cardIcon.png');
    }else if(data.newIcon){
      cardFile.append('files', data.newIcon, 'cardIcon.png');
    }
    const editLangs = data.editLangs;
    for(var i=0;i<editLangs.length;i++){
      if(editLangs[i].audioBlob){
        cardFile.append('files', editLangs[i].audioBlob, 'langAudio_' + i + '.wav');
      }
    }
    let err, uploadRes, cardRes;
    [err, uploadRes] = await to(axios.post(api + '/upload', cardFile, config))
    if(err){actions.connectionError(dispatch); return;}
    dispatch({type: 'loadingPercent', payload: 100});

    const filenames = uploadRes.data.filenames;
    var cardIcon = null, date = new Date();
    var langAudios = [];
    for(var j=0;j<filenames.length;j++){
      const splted = filenames[j].split('-');
      if(splted[1] === 'cardIcon.png'){
        cardIcon = filenames[j];
      }else{
        langAudios.splice(0,0, filenames[j]);
      }
    }
    if(len !== null)
      date = new Date().setMilliseconds(len + 1);

    const card = {
      icon: cardIcon? cardIcon: data.icon,
      author: data.author,
      studentProject: data.isTeacher? null: data.studentProject,
      grade: data.isTeacher? 'featured': 'notGraded',
      createdAt: date
    }
    const langs = [];
    for(var k=0;k<editLangs.length;k++){
      const lang = {
        key: editLangs[k].key,
        text: editLangs[k].text,
        audio: getFile(langAudios, k)? getFile(langAudios, k): editLangs[k].defaultAudio? editLangs[k].defaultAudio: ''
      }
      langs.splice(0,0,lang);
    }
    [err, cardRes] = await to(axios.post(api + '/card/add', { data: { project: data.project, card: card, langs: langs, isTeacher: data.isTeacher, resubmitCard: data.resubmitCard}}))
    if(err){actions.connectionError(dispatch); return;}

    if(cardRes.data.result === 'success'){
      dispatch({type: 'message', payload: ['Submit card succeed!', '成功提交卡片!', '成功提交卡片!']});
      //console.log(cardRes.data);
      dispatch({type: 'updateCards', payload: [cardRes.data.card]});
      dispatch({type: 'updateCards', payload: [cardRes.data.updatedCard]});
      dispatch({type: 'updateLangs', payload: cardRes.data.langs});

      dispatch({type: 'updateProjects', payload: [cardRes.data.updatedProject]});
      dispatch({type: 'viewProject', payload: cardRes.data.updatedProject});

      dispatch({type: 'updateStudentProjects', payload: [cardRes.data.updatedStudentProject]});
      dispatch({type: 'viewStudentProject', payload: cardRes.data.updatedStudentProject});

      dispatch({type: 'setEditLangs', payload: []});
      //dispatch({type: 'setPhoto', payload: {photoUrl: null, photoBlob: null}});
      dispatch({type: 'setProfile', payload: cardRes.data.updatedProfile});

      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'pullView'});
    }else{
      //console.log(cardRes.data.result);
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      dispatch({type: 'message', payload: ['Submit card failed! Please try again!', '提交失敗! 請再試一次!', '提交失败! 请再试一次!']});
    }

  }
}

export function swapCards(card1, card2){
    return async function (dispatch) {
      //  actions.connecting(dispatch);
        let err, res;
        [err, res] = await to(axios.post(api + '/card/swap', { data: { card1: card1, card2: card2 }}));
      //  return new Promise( (resolve, reject) => {
        if(err){actions.connectionError(dispatch); return;}
        if(res.data.result === 'success'){
          //dispatch({type: 'message', payload: ['Sucess'] });
        }else{
          dispatch({type: 'message', payload: ['Failed to get card data!', '無法查閱專題研習資料!', '无法查阅专题研习资料!']});
        }
     // })
    }
  }

export function textToSpeech(text, lang=undefined){
  return async function (dispatch){
    actions.connecting(dispatch);
    let err, res;
    [err, res] = await to(axios.post(api + '/card/textToSpeech', { data: {text: text, lang: lang }}));
    if(err){actions.connectionError(dispatch); return;}
    console.log(`TTS`, res.data);
    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Successfully generate audio file!', '成功生成音頻!', '成功生成音频!']});
      dispatch({type: 'updateAudioBuffer', payload: res.data.audioBuffer});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }
    else{
      dispatch({type: 'message', payload: ['Failed to get card data!', '無法查閱專題研習資料!', '无法查阅专题研习资料!']});
    }
  }
}

export function exportToAthena(output){
  return async function (dispatch) {
    actions.connecting(dispatch);
    let err, res;
    console.log(output);
    [err, res] = await to(axios.post(api + '/card/athena', { data: { output: output }}));
    console.log(res);
    if(err){actions.connectionError(dispatch); return;}
    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Sucessfully sumbit to Athena Space', '成功提交到雅典娜e學園 ', '成功提交到雅典娜e学园'] });
    }else{
      dispatch({type: 'message', payload: ['Failed to submit to Athena Space!', '提交失敗', '提交失败']});
    }
  }
}

function getFile(files, index){
  for(var i=0;i<files.length;i++){
    const splited = files[i].split('.');
    const fileIndex = splited[0].slice(-1);
    if(fileIndex === '' + index){
      return files[i]
    }
  }
  return '';
}
