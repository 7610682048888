//import axios from 'axios';
//import * as actions from '../actions';
//import to from '../to';
//var api = actions.api();

export const setEditAllowedComment = (editAllowedComment) =>{
  return {
    type: 'setEditAllowedComment',
    payload: editAllowedComment
  }
}

export const setEditAllowedComments = (editAllowedComments) =>{
  return {
    type: 'setEditAllowedComments',
    payload: editAllowedComments
  }
}

export const killEditAllowedCommentsItem = (index) =>{
  return {
    type: 'killEditAllowedCommentsItem',
    payload: index
  }
}

export const removeEditAllowedCommentsItem = (index) =>{
  return {
    type: 'removeEditAllowedCommentsItem',
    payload: index
  }
}


export const pushEditAllowedComments = (lang) =>{
  return {
    type: 'pushEditAllowedComments',
    payload: lang
  }
}


export const viewAllowedComment = (comment) =>{
  return {
    type: 'viewAllowedComment',
    payload: comment
  }
}
