import axios from 'axios';
import * as actions from '../actions';
import to from '../to';

var api = actions.api();

export function addAdmin(userId){
  //console.log(newUser)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/user/addAdmin',{ data: userId }));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Update succeed!', '更改成功!', '更改成功!']});
      dispatch({type: 'updateAdmins', payload: res.data.admins});
      dispatch({type: 'updateProfiles', payload: res.data.profiles});
      dispatch({type: 'pullView'});

    }else{
      dispatch({type: 'message', payload: ['Update failed! Please try again!', '更改失敗! 請再試一次!', '更改失败! 请再试一次']});
    }
  }
}

export function genAccessLinksByExcel(data, filename, functions){
  return async function (dispatch) {
    actions.connecting(dispatch);
    let err, res;

    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: { token: 'mLangWelcomeA108', filename: filename }
    };

    var excelFile = new FormData();
    excelFile.append('file', data);

    [err, res] = await to(axios.post(api + '/user/excel/genAccessLinks', excelFile, config));
    if(err){ actions.connectionError(dispatch); return; }
    dispatch({type: 'loadingPercent', payload: 100});

    if(res.data){
      dispatch({type: 'message', payload: ['Upload succeed!', '上載成功!', '上载成功!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      functions.url(filename, 'temp');
    }else{
      dispatch({type: 'message', payload: ['Upload failed!', '上載失敗!', '上載失敗!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }
  }
}


export function genHomeWorkEasyAccessLinksByExcel(data, filename, functions){
  return async function (dispatch) {
    actions.connecting(dispatch);
    let err, res;

    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: { token: 'mLangWelcomeA108', filename: filename }
    };

    var excelFile = new FormData();
    excelFile.append('file', data);

    [err, res] = await to(axios.post(api + '/user/excel/genHomeWorkEasyAccessLinks', excelFile, config));
    if(err){ actions.connectionError(dispatch); return; }
    dispatch({type: 'loadingPercent', payload: 100});

    if(res.data){
      dispatch({type: 'message', payload: ['Upload succeed!', '上載成功!', '上载成功!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
      functions.url(filename, 'temp');
    }else{
      dispatch({type: 'message', payload: ['Upload failed!', '上載失敗!', '上載失敗!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }
  }
}


export function registerByExcel(data){
  return async function (dispatch) {
    actions.connecting(dispatch);
    let err, res;

    const config = {
       onUploadProgress: progressEvent => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          percentCompleted = percentCompleted > 89? 90: percentCompleted;
          dispatch({type: 'loadingPercent', payload: percentCompleted});
          dispatch({type: 'loadingStatus', payload: 'on'});
       },
       headers: { token: 'mLangWelcomeA108' }
    };

    var excelFile = new FormData();
    excelFile.append('file', data);

    [err, res] = await to(axios.post(api + '/user/excel/register', excelFile, config));
    if(err){ actions.connectionError(dispatch); return; }
    dispatch({type: 'loadingPercent', payload: 100});

    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Upload succeed!', '上載成功!', '上载成功!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }else{
      dispatch({type: 'message', payload: ['Upload failed!', '上載失敗!', '上載失敗!']});
      dispatch({type: 'loadingPercent', payload: 0});
      dispatch({type: 'loadingStatus', payload: 'off'});
    }
  }
}

export function register(code, codeType, newUser, newProfile, newAgreement){
  return async function (dispatch) {
    actions.connecting(dispatch);
    let err, res, uploadRes;

    var iconFile = new FormData();
    if(newProfile.newIconBlob){ iconFile.append('files', newProfile.newIconBlob, 'profileIcon.png'); }

    [err, uploadRes] = await to(axios.post(api + '/upload', iconFile, { headers: { type: 'profileIcon'}}));
    if(err){actions.connectionError(dispatch); return;}

    if(uploadRes.data.result === 'success'){ newProfile['newIcon'] = uploadRes.data.filenames[0]; }
    else{ actions.connectionError(dispatch); return; }

    [err, res] = await to(axios.post(api + '/user/register',{ data: {code: code, type: codeType , user: newUser, profile: newProfile, agreement: newAgreement}}));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Register succeed!', '註冊成功!', '註冊成功!']});
      dispatch({type: 'setStatus', payload: 'init'});
      login(res.data.id, res.data.pw)(dispatch);
    }else{
      dispatch({type: 'message', payload: ['Login name is already used! Please use another login name!', '登入名稱已經被使用! 請使用其他的登入名稱!', '登入名称已经被使用! 请使用其他的登入名称!']});
    }

  }
}

export function changeUserInfo(newUser){
  //console.log(newUser)
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/user/update',{ data: newUser }));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Update succeed!', '更改成功!', '更改成功!']});
      dispatch({type: 'setUser', payload: res.data.updatedUser});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Update failed! Please try again!', '更改失敗! 請再試一次!', '更改失败! 请再试一次']});
    }
  }

}

export function resetPassword (_email) {
  //console.log('login: ' + _id + ' ' + _pw);
  return async function (dispatch) {
    dispatch({type: 'loadingMessage', payload: ['Reseting password...', '密碼重置中...', '密码重置中...']});

    let err, res;
    [err, res] = await to(axios.get(api + '/user/resetPassword',{ headers: { email: _email }}));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Reset password succeed! Please check email for new password!', '密碼重置成功! 請查看電子郵件!', '密码重置成功! 请查看电子邮件!']});
    }else{
      dispatch({type: 'message', payload: ['Failed to reset password! Please make sure to enter a correct email address!', '密碼重置失敗! 請確定電郵地址正確!', '密码重置失败! 请确定电邮地址正确!']});
    }

  }
}

export function checkCode(code, codeType){
  return async function (dispatch) {
    if(code.length < 5){

      dispatch({type: 'message', payload: ['Invalid code!', '代碼不正確!', '代码不正确!']});
      return;
    }
    dispatch({type: 'loadingMessage', payload: ['Acquiring for new account...', '申請進行中...', '申请进行中...']});

    let err, res;
    [err, res] = await to(axios.get(api + '/user/checkCode',{ headers: { code: code, type: codeType }}));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){

      dispatch({type: 'setUser', payload: ''});
      dispatch({type: 'setProfile', payload: ''});
      dispatch({type: 'setAgreement', payload: ''});
      dispatch({type: 'updateProfiles', payload: []});
      dispatch({type: 'updateStudentProjects', payload: []});
      dispatch({type: 'updateCards', payload: []});
      dispatch({type: 'updateLangs', payload: []});

      dispatch({type: 'updateAdmins', payload: []});
      dispatch({type: 'updateSupervisingSchools', payload: []});

      dispatch({type: 'updateSchools', payload: []});
      dispatch({type: 'updateCourses', payload: []});
      dispatch({type: 'updateSubjects', payload: []});
      dispatch({type: 'updateProjects', payload: []});
      dispatch({type: 'updateStudentProjects', payload: []});

      dispatch({type: 'updateTeachingCourses', payload: []});
      dispatch({type: 'updateJoinedCourses', payload: []});

      dispatch({type: 'updateTeachingSubjects', payload: []});
      dispatch({type: 'updateJoinedSubjects', payload: []});

      dispatch({type: 'updateTeachingProjects', payload: []});
      dispatch({type: 'updateJoinedProjects', payload: []});

      dispatch({type: 'updateTeachingStudentProjects', payload: []});
      dispatch({type: 'updateJoinedStudentProjects', payload: []});

      dispatch({type: 'updateTeachingCards', payload: []});
      dispatch({type: 'updateJoinedStudentCards', payload: []});

      dispatch({type: 'updateCreatedQuestionnaires', payload: []});
      dispatch({type: 'updateCreatedSubmits', payload: []});
      dispatch({type: 'updateAssignedPublishes', payload: []});

      dispatch({type: 'updateSubmits', payload: []});
      dispatch({type: 'updateAnswers', payload: []});

      dispatch({type: 'updateQuestionnaires', payload: []});
      dispatch({type: 'updateQuestions', payload: []});

      dispatch({type: 'updateCreatedPublishes', payload: []});
      dispatch({type: 'updatePublishes', payload: []});

      dispatch({type: 'updateGroups', payload: []});

      dispatch({type: 'updateMatchGameCards', payload: []});

      dispatch({type: 'setCode', payload: {code: code, codeType: codeType}})
      dispatch({type: 'setStatus', payload: 'ready'});
      dispatch({type: 'hideModal'});

    }else{
      dispatch({type: 'message', payload: ['Failed to acquire new account! Please check if account type and code are correct!', '申請失敗! 請檢查帳號類別和代碼!', '申请失败! 请检查帐号类别和代码!']});
    }
  }
}

/*
export function getNewAccountByCode (code, codeType) {
  //console.log(code + ' ' + codeType);
  return async function (dispatch) {
    if(code.length < 5){

      dispatch({type: 'message', payload: ['Invalid code!', '代碼不正確!', '代码不正确!']});
      return;
    }
    dispatch({type: 'loadingMessage', payload: ['Acquiring for new account...', '申請進行中...', '申请进行中...']});

    let err, res;
    [err, res] = await to(axios.get(api + '/user/getNewAccountByCode',{ headers: { code: code, type: codeType }}));
    if(err){ actions.connectionError(dispatch); return; }

    if(res.data.result === 'success'){
      login(res.data.id, res.data.pw)(dispatch);
    }else{
      dispatch({type: 'message', payload: ['Failed to acquire new account! Please check if account type and code are correct!', '申請失敗! 請檢查帳號類別和代碼!', '申请失败! 请检查帐号类别和代码!']});
    }
  }
}
*/

export function getNewAccount (_email) {
  //console.log('login: ' + _id + ' ' + _pw);
  return async function (dispatch) {
    if(_email.indexOf('@') < 0){

      dispatch({type: 'message', payload: ['Invalid email!', '電郵地址不正確!', '电邮地址不正确!']});
      return;
    }
    dispatch({type: 'loadingMessage', payload: ['Acquiring for new account...', '申請進行中...', '申请进行中...']});

    let err, res;
    [err, res] = await to(axios.get(api + '/user/getNewAccount',{ headers: { email: _email }}));
    if(err){ actions.connectionError(dispatch); return; }


    if(res.data.result === 'success'){
      dispatch({type: 'message', payload: ['Acquire succeed! Please check your email for login infomation!', '申請成功! 請查看電子郵件!', '申请成功! 请查看电子邮件!']});
    }else{
      dispatch({type: 'message', payload: ['Failed to acquire new account! The email address is either invalid or already used!', '申請失敗! 電郵地址不正確或已被使用!', '申请失败! 电邮地址不正确或已被使用!']});
    }

  }
}

export function getTrialAccount(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/user/getTrialAccount',{ data: data }));
    if(err){ actions.connectionError(dispatch); return; }
    if(res.data.result === 'blocked'){
      dispatch({type: 'message', payload: ['This function is not available now', '此功能暫未能使用', '此功能暂未能使用']});
    }
    else{ 
      await dispatch(setLoginData(res)); 
    }
  }
}

export function loginById(_id){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/user/loginById',{ data: { id: _id,}}));
    if(err){ actions.connectionError(dispatch); return; }

    dispatch(setLoginData(res));
  }
}

export function login (_id, _pw, extra) {
  console.log(api);
  //console.log(_id + ' ' + _pw);
  return async function (dispatch) {
    actions.connecting(dispatch);
    
    let err, res;
    [err, res] = await to(axios.get(api + '/user/login', {headers: { id: _id, pw: _pw }}));
    if(err){ actions.connectionError(dispatch); return; }

    dispatch(setLoginData(res, extra));
  }
}

export function setLoginData(res, extra){
  return async function (dispatch) {
    //console.log(res.data);
    if(res.data.result === 'success'){
      //console.log(res.data);

      dispatch({type: 'setUser', payload: res.data.user});
      dispatch({type: 'setProfile', payload: res.data.profile});
      dispatch({type: 'setAgreement', payload: res.data.agreement});
      dispatch({type: 'setAccess', payload: res.data.access});
      dispatch({type: 'updateProfiles', payload: res.data.profiles});
      dispatch({type: 'updateStudentProjects', payload: res.data.studentProjects});
      dispatch({type: 'updateCards', payload: res.data.cards});
      dispatch({type: 'updateLangs', payload: res.data.langs});

      dispatch({type: 'updateAdmins', payload: res.data.admins});
      dispatch({type: 'updateSupervisingSchools', payload: res.data.supervisingSchools});

      dispatch({type: 'updateSchools', payload: res.data.schools});
      dispatch({type: 'updateSchoolTags', payload: res.data.schoolTags});
      dispatch({type: 'updateCourses', payload: res.data.courses});
      dispatch({type: 'updateSubjects', payload: res.data.subjects});
      dispatch({type: 'updateProjects', payload: res.data.projects});

      dispatch({type: 'updateStudentProjects', payload: res.data.studentProjects});

      dispatch({type: 'updateTeachingCourses', payload: res.data.teachingCourses});
      dispatch({type: 'updateJoinedCourses', payload: res.data.joinedCourses});

      dispatch({type: 'updateTeachingSubjects', payload: res.data.teachingSubjects});
      dispatch({type: 'updateJoinedSubjects', payload: res.data.joinedSubjects});

      dispatch({type: 'updateTeachingProjects', payload: res.data.teachingProjects});
      dispatch({type: 'updateJoinedProjects', payload: res.data.joinedProjects});

      dispatch({type: 'updateTeachingStudentProjects', payload: res.data.teachingStudentProjects});
      dispatch({type: 'updateJoinedStudentProjects', payload: res.data.joinedStudentProjects});

      dispatch({type: 'updateTeachingCards', payload: res.data.teachingCards});
      dispatch({type: 'updateJoinedStudentCards', payload: res.data.joinedCards});

      dispatch({type: 'updateCreatedQuestionnaires', payload: res.data.createdQuestionnaires});
      dispatch({type: 'updateCreatedSubmits', payload: res.data.createdSubmits});
      dispatch({type: 'updateAssignedPublishes', payload: res.data.assignedPublishes});

      dispatch({type: 'updateSubmits', payload: res.data.submits});
      dispatch({type: 'updateAnswers', payload: res.data.answers});

      dispatch({type: 'updateQuestionnaires', payload: res.data.questionnaires});
      dispatch({type: 'updateQuestions', payload: res.data.questions});

      dispatch({type: 'updateCreatedPublishes', payload: res.data.createdPublishes});
      dispatch({type: 'updatePublishes', payload: res.data.publishes});

      dispatch({type: 'updateGroups', payload: res.data.groups});
      dispatch({type: 'updateMatchGamePublishes', payload: res.data.matchGamePublishes});
      dispatch({type: 'updateMemoryGamePublishes', payload: res.data.memoryGamePublishes});
      dispatch({type: 'updateMemoryGameSubmits', payload: res.data.memoryGameSubmits});
      dispatch({type: 'updateMemoryGameBattleSubmits', payload: res.data.memoryGameBattleSubmits});

      actions.handleExtra(dispatch, res, extra);

      /*dispatch({type: 'updateMatchGameCards', payload: res.data.featuredCards});*/

      dispatch({type: 'setStatus', payload: 'ready'});
      dispatch({type: 'hideModal'});
    }else if(res.data.result === 'blocked'){
      dispatch({type: 'message', payload: ['Access denied!', '拒絕訪問!', '拒绝访问!']});
    }else{
      dispatch({type: 'message', payload: ['Login failed! Invalid id or password!', '登入失敗! 名稱或密碼不正確!', '登入失败! 名称或密码不正确!']});
    }
  }
}

export function logout() {
  return function (dispatch) {
    //dispatch({type: 'setStatus', payload: 'waitForLogin'});
    window.location.reload();
  }
}
