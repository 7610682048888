import React from 'react';
import Row from './Row';
import Cards from 'components/main/pages/home/views/home/contents/Cards';
import ProfileCell from 'components/main/items/cells/ProfileCell';
import Button  from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/Star'
import RemoveIcon from '@material-ui/icons/Remove'

class GroupMemberRow extends Row {

  cards(){
    const studentProject = this.func.getById.studentProjectByPair(this.props.userId, this.store.projects.viewingProject._id, this.store);
    if(!studentProject){ return null; }
    return(
        <Cards
        app={this.app}
        cardsId={studentProject.cards}
        horizontal={true}
        onCellClick={()=>{ this.actions.cards.viewCards(studentProject.cards); }}/>
    )
  }

  removeGroupMember(userId, code){
    this.actions.groups.leaveGroup(userId, code);
    this.actions.content.pullView();
  }

  changeGroupLeader(userId, code){
    this.actions.groups.changeGroupLeader(userId, code);
    this.actions.content.pullView();
  }
  content = (style)=>(
      <div style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity,
        width: '100%',
        cursor: '',
      }}}>
        {this.verGap('2%')}
        <div style={{display: 'flex', flexDirection: "column"}}>
          <ProfileCell
          app={this.app}
          data={this.profile}
          onClick={()=>{
            this.actions.profiles.viewProfile(this.profile);
            this.actions.content.pushView('student'); }}/>
          <div style={{display: 'flex', justifyContent: "center", marginTop:"20%"}}>
            {this.props.member && <Button style={{backgroundColor: "yellow", marginRight: "5%", border: "0.1px solid black"}} onClick={()=>{this.changeGroupLeader(this.props.userId, this.props.group.code)}}><StarIcon/></Button>  }
            {this.props.member && <Button style={{backgroundColor: "red" , marginLeft: "5%", border: "0.1px solid black"}} onClick={()=>{this.removeGroupMember(this.props.userId, this.props.group.code)}}><RemoveIcon/></Button> }
          </div>
        </div>
        {this.verGap('2%')}
        {this.cards()}
      </div>
  )

  render(){
    this.init(this.props);
    this.profile = this.func.getById.profileByUser(this.props.userId, this.store);
    if(!this.profile){ return null; }
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.425)
  }

}

export default GroupMemberRow;