import React from "react";
//import PropTypes from "prop-types";
import clsx from "clsx";
//import { makeStyles, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import { green } from "@material-ui/core/colors";


const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'rgba(0,0,0,0)',
      width: 100,
      position: "relative",
      minHeight: 90
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(1)
    },
    fabGreen: {
      color: theme.palette.common.white,
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[600]
      }
    },
    menuRoot: {
        display: 'flex',
        marginRight: theme.spacing(2),
        background: 'blue',
        color: 'blue'
    },
}));

function FloatingActionButton(props) {
    const classes = useStyles();
    //const theme = useTheme();
    const app = props.app;
    const tags = props.tags;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, tag) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
        app.actions.main.setChosenTag(tag);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
  
    const fab = {
      color: "inherit",
      className: clsx(classes.fab, classes.fabGreen),
      icon: <UpIcon />,
      label: "Expand"
    };

    const menuListStyle = {
        height: tags && tags.length > 0? tags.length * 30 > 350? '350px': tags.length * 30 + 'px': '0px',
        overflow: 'auto'
    }

    function setMenuItem(tags){
        return(
            <div>
                {tags.map((tag, i) => {
                    return <MenuItem key={i} onClick={(event)=>{handleClose(event, tag);}}>{tag}</MenuItem>
                })}
            </div>
        )
    }
  
    return (
      <div className={classes.root}>
        <Fab
          aria-label={fab.label}
          className={fab.className}
          color={fab.color}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {fab.icon}
        </Fab>
        <div className={classes.menuRoot}>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement={'top-end'} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={menuListStyle}>
                        {setMenuItem(tags)}
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </div>
      </div>
    );
}

export default FloatingActionButton;
  