import React from 'react';
import View from 'components/main/pages/home/views/View';
import MatchGameCard from 'components/main/pages/home/views/home/cards/MatchGameCard';

class MatchGamePlay extends View {


  indexTag(currentIndex){
    const style = {...this.ui.styles.container, ...{
      position: 'absolute',
      top: this.bs.width * 0.01,
      left: this.bs.width * 0.01,
      width: this.bs.width * 0.06,
      height: this.bs.width * 0.06,
      borderRadius: '50%',
      backgroundColor: this.ui.colors.grey,
      opacity: 0.75,
      fontWeight: 'bold',
      color: 'white',
      fontSize: this.bs.width * 0.04
    }}
    return <div style={style}>{currentIndex}</div>
  }

  render(){
    this.init(this.props);
    const style = {...this.ui.styles.container, ...{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      overflowX: 'auto',
      justifyContent: 'flex-start',
      backgroundColor: this.ui.colors.ultraLightGrey
    }}


    const viewingCard = this.store.matchGames.viewingCard;
    const viewingCards = this.store.matchGames.viewingCards;

    //console.log(viewingCard);
    //console.log(viewingCards);

    var currentIndex, previous, next;

    for(var i=0;i<viewingCards.length;i++){
      if(viewingCards[i]._id === viewingCard._id){
        currentIndex = i + 1;
        previous = i === 0 ? null : viewingCards[i - 1];
        next = i === viewingCards.length-1 ? null : viewingCards[i + 1];
      }
    }

    const action = this.store.matchGames.viewAction;
    const lastViewingCard =
    action === 'previous'? next:
    action === 'next'? previous:
    action === 'swapLeft'? next:
    action === 'swapRight'? previous:
    null;

    const langChoices = this.store.matchGames.langChoices[currentIndex - 1];

    return(
      <div style={{...this.viewStyle(), overflow: 'hidden'}}>
        <div style={style}>
          {this.renderCard(viewingCard, action === 'previous'? 'inLeft': action === 'next'? 'inRight': 'far', langChoices, currentIndex - 1)}
          {lastViewingCard && this.renderCard(lastViewingCard, action === 'swapLeft'? 'farToRight': action === 'swapRight'? 'farToLeft': action === 'previous'? 'outRight': action === 'next'? 'outLeft': '', langChoices, currentIndex - 1)}
        </div>
        {previous && this.buttons.previous(()=>{ this.actions.matchGames.viewMatchGameCard(previous); this.actions.matchGames.setAction('previous');})}
        {next !== 'submit' ?
          this.buttons.next(()=>{ this.actions.matchGames.viewMatchGameCard(next); this.actions.matchGames.setAction('next'); }) :
          this.buttons.next(()=>{ return this.submitAnswers(); })
        }
        {this.indexTag(currentIndex)}
      </div>
    )
  }

  submitAnswers(){
    this.actions.modal.confirm(['Confirm to submit answers?','確定提交答案?','确定提交答案?'], ()=>{
      this.actions.content.setSubView('matchGameResult');
    })
  }

  renderCard(card, state, langChoices, index){
    return <MatchGameCard app={this.app} card={card} viewingMatchGamePublish={this.store.matchGames.viewingMatchGamePublish} state={state} langChoices={langChoices} index={index} />
  }

}

export default MatchGamePlay;
