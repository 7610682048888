import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    color: 'white',
  },
  icon:{
      fill: 'white'
  },
  languageIcon:{
    marginTop: '1%',
  }
}));

export default function SelectLanguage(props) {
  const classes = useStyles();

  const handleChange = (event, app) => {
    app.actions.main.setLanguage(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Grid container spacing={4} direction="row">
            <Grid item xs={1} className={classes.languageIcon}>
                <LanguageIcon style={{fill: 'white'}}/>
            </Grid>
            <Grid item xs={8}>
                <Select
                value={props.language}
                onChange={(e) => handleChange(e, props.app)}
                displayEmpty
                disableUnderline 
                className={classes.selectEmpty}
                inputProps={{
                    classes:{icon:classes.icon}
                }}
                >
                <MenuItem value={'english'}>English</MenuItem>
                <MenuItem value={'chinese'}>繁體中文</MenuItem>
                <MenuItem value={'simplified_chinese'}>简体中文</MenuItem>
                </Select>
            </Grid>
        </Grid>
      </FormControl>
    </div>
  );
}
