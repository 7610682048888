import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

class MemoryGameDetail extends SubView {

  convertTypeToDisplay(type){
    const language = this.store.main.language;
    const engType = ['text', 'audio', 'both'];
    const chiType = ['文本', '音頻', '兩者皆可'];
    const simChiType = ['文本', '音频', '两者皆可'];
    const index = engType.indexOf(type);
    return language === 'chinese' ? chiType[index] : language === 'simplified_chinese' ? simChiType[index] : type;
  }

  render() {
    this.init(this.props);
    const memoryGame = this.store.memoryGames.viewingMemoryGamePublish;
    const course = this.func.getById.course(memoryGame.course, this.store);

    return(
      <div style={this.subViewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Title','標題','标题'])}
        {this.sep()}
        {this.detailText(memoryGame? memoryGame.title: '')}
        {this.gap('2%')}

        {this.subTitle(['Description','描述','描述'])}
        {this.sep()}
        {this.gap('0.5%')}
        {this.detailText(memoryGame? memoryGame.description: '')}
        {this.gap('3%')}

        {this.subTitle(['Course','班名','班名'])}
        {this.sep()}
        {this.detailText(course.title)}
        {this.gap('3%')}

        {this.subTitle(['Number of questions','題目數量','题目数量'])}
        {this.sep()}
        {this.detailText(memoryGame? memoryGame.size: '')}
        {this.gap('3%')}

        {this.subTitle(['Type of questions (text or audio or both)','題目類型 (文本或音頻或兩者皆可)','题目类型 (文本或音频或两者皆可)'])}
        {this.sep()}
        {this.detailText(memoryGame? this.convertTypeToDisplay(memoryGame.type): '')}
        {this.gap('3%')}

        {this.subTitle(['Start date','創建於','创建于'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(memoryGame.createdAt)))}
        {this.gap('3%')}

        {this.subTitle(['End date','結束日期','结束日期'])}
        {this.sep()}
        {this.detailText(this.func.dateString(new Date(memoryGame.endDate)))}
        {this.gap('3%')}

      </div>
    )
  }

}

export default MemoryGameDetail;
