import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setAction = (action) =>{
  return {
    type: 'setAction',
    payload: action
  }
}

export const viewMatchGameCards = (cards) =>{
  return {
    type: 'viewMatchGameCards',
    payload: cards
  }
}

export const viewMatchGameCard = (card) =>{
  return {
    type: 'viewMatchGameCard',
    payload: card
  }
}

export const pushLangChoices = (langs) =>{
  return {
    type: 'pushLangChoices',
    payload: langs
  }
}

export const setLangChoices = (langs) =>{
  return {
    type: 'setLangChoices',
    payload: langs
  }
}

export const initAnswers = (answers) =>{
  return {
    type: 'initAnswers',
    payload: answers
  }
}

export const updateAnswers = (answer, index) =>{
  return {
    type: 'updateAnswers',
    payload: answer,
    index: index
  }
}

export const viewMatchGamePublish = (matchGamePublish) =>{
  return {
    type: 'viewMatchGamePublish',
    payload: matchGamePublish
  }
}

export const viewMatchGameSubmit = (matchGameSubmit) =>{
  return {
    type: 'viewMatchGameSubmit',
    payload: matchGameSubmit
  }
}

export const setMatchGameMode = (mode) =>{
  return {
    type: 'setMatchGameMode',
    payload: mode
  }
}

export function addPublish(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/matchGame/publish/add', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit publish succeed!', '成功提交發佈!', '成功提交发布!']});
      dispatch({type: 'updateMatchGamePublishes', payload: [publishRes.data.updatedMatchGamePublish]});
      dispatch({type: 'viewMatchGamePublish', payload: publishRes.data.updatedMatchGamePublish});
      dispatch({type: 'updateCourses', payload: [publishRes.data.editedCourse]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit publish! Please make sure the school code is valid!', '發佈提交失敗! 請確保學校代碼輸入正確!', '发布提交失败! 请确保学校代码输入正确!']});
    }
  }
}


export function editPublish(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/matchGame/publish/edit', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit publish succeed!', '成功提交發佈!', '成功提交发布!']});
      dispatch({type: 'updateMatchGamePublishes', payload: [publishRes.data.updatedMatchGamePublish]});
      dispatch({type: 'viewMatchGamePublish', payload: publishRes.data.updatedMatchGamePublish});
      dispatch({type: 'updateCourses', payload: [publishRes.data.editedCourse]});
      dispatch({type: 'pullView'});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit publish! Please make sure the school code is valid!', '發佈提交失敗! 請確保學校代碼輸入正確!', '发布提交失败! 请确保学校代码输入正确!']});
    }
  }
}

export function addSubmit(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, publishRes;
    [err, publishRes] = await to(axios.post(api + '/matchGame/submit/add', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(publishRes.data.result === 'success'){
      //console.log(publishRes.data);
      dispatch({type: 'message', payload: ['Submit publish succeed!', '成功提交發佈!', '成功提交发布!']});
      dispatch({type: 'updateMatchGameSubmits', payload: [publishRes.data.updatedMatchGameSubmit]});
      dispatch({type: 'viewMatchGameSubmit', payload: publishRes.data.updatedMatchGameSubmit});
      dispatch({type: 'updateMatchGamePublishes', payload: [publishRes.data.updatedMatchGamePublish]});
      dispatch({type: 'viewMatchGamePublish', payload: publishRes.data.updatedMatchGamePublish});
    }else{
      dispatch({type: 'message', payload: ['Failed to submit!', '提交失敗!', '提交失败!']});
    }
  }
}


export function getRanking(matchGameId){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;
    [err, res] = await to(axios.post(api + '/matchGame/getRanking', { data: matchGameId }));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      //dispatch({type: 'updatePRofiles', payload: res.data.profiles});
      //dispatch({type: 'setRanking', payload: {projectId: projectId, ranking: res.data.ranking}});
      dispatch({type: 'setMatchGameRanking', payload: {matchGameId: matchGameId, ranking: res.data.ranking}});
      dispatch({type: 'hideModal'});
    }else{
      dispatch({type: 'message', payload: ['Failed to get match game ranking!', '無法查閱遊戲排行榜!', '无法查阅游戏排行榜!']});
    }
  }
}
