import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '0.5%',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function MultipleList(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const language = props.app.store.main.language;
  const choices = props.choices;

  useEffect(() => {
    props.onClick(checked);
  });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => {
    var id = [];
    for(var i=0;i<items.length;i++){ id.push(items[i]._id); }
    return intersection(checked, id).length;
  }

  const handleToggleAll = (items) => () => {
    var id = [];
    for(var i=0;i<items.length;i++){ id.push(items[i]._id); }
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, id));
    } else {
      setChecked(union(checked, id));
    }
  };


  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={numberOfChecked(items) + '/' + items.length + ' ' +
        (language === 'english'? 'selected': language === 'chinese'? '已選擇': '已选择')}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-all-item-${item._id}-label`;
          return (
            <ListItem key={item._id} role="listitem" button onClick={handleToggle(item._id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item._id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(language === 'english'? 'Choices': language === 'chinese'? '選項': '选项', choices)}</Grid>
    </Grid>
  );
}

export default MultipleList;
