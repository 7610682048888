//import * as reducer from '../reducer';
import reducer from '../reducer';

const groupsReducer = (
  state = {
    groups: [],
    viewingGroup: {},
    data: {},
    code : [],
    createdByTeacher: []
  }, action)=>{
  var data = state.data;
  switch (action.type) {
    case 'viewGroup':
      return {...state, viewingGroup: action.payload}
    case 'updateGroupData':
      data[action.payload.group] = action.payload;
      return {...state, data: data}
    case 'updateGroups':
        return {...state, groups: reducer.updateElements(state.groups, action.payload)};
    case 'updateGroupCode':
      return {...state, code: action.payload};
    case 'findCreatedByTeacher':
      return {...state, createdByTeacher: action.payload};
    default:
      return state;
  }
}

export default groupsReducer;
