import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchLabel = (props) => {
  const [state, setState] = React.useState(props.data);

  useEffect(() => {
      setState(props.data);
  },[props])

  const handleChange = (event) => {
    setState(event.target.checked);
    props.onSwitch(event.target.checked);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={state}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
      />
    </FormGroup>
  );
}

export default SwitchLabel;