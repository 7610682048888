export const setWebSocket = (ws) => {
  return {
    type: 'setWebSocket',
    payload: ws
  }
}

export const setPrefabPicker = (state) =>{
  return {
    type: 'setPrefabPicker',
    payload: state
  }
}


export const setDefaultImagePicker = (state) =>{
  return {
    type: 'setDefaultImagePicker',
    payload: state
  }
}

export const setChosenTag = (state) =>{
  return {
    type: 'setChosenTag',
    payload: state
  }
}

export const enlargeText = (text) =>{
  return {
    type: 'enlargeText',
    payload: text
  }
}

export const enlargeImage = (image, card) =>{
  return {
    type: 'enlargeImage',
    payload: {image: image, card: card}
  }
}

export const closeEnlarger = () =>{
  return {
    type: 'closeEnlarger'
  }
}

export const openCanvas = (image, card) =>{
  return {
    type: 'openCanvas',
    payload: {image: image, card: card}
  }
}

export const closeCanvas = () =>{
  return {
    type: 'closeCanvas'
  }
}

export const openColorPicker = (status) =>{
  return {
    type: 'openColorPicker',
    payload: status
  }
}

export const setColorPicker = (color) =>{
  return {
    type: 'setColorPicker',
    payload: color
  }
}

export const closeColorPicker = () =>{
  return {
    type: 'closeColorPicker'
  }
}

export const setAudioRecorder = (record) =>{
  return {
    type: 'setAudioRecorder',
    payload: record
  }
}


export const setPhoto = (photo) =>{
  return {
    type: 'setPhoto',
    payload: photo
  }
}

export const setPhotoAttr = (rotation) =>{
  return {
    type: 'setPhotoAttr',
    payload: {rotation: rotation}
  }
}

export const setStatus = (status) =>{
  return {
    type: 'setStatus',
    payload: status
  }
}

export const setLanguage = (lang) =>{
  return {
    type: 'setLanguage',
    payload: lang
  }
}

export const setModal = (modal) =>{
  return {
    type: 'setModal',
    payload: modal
  }
}
