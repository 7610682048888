import React from "react";
import Konva from "react-konva";

const Cross = ({ editMode, shapeProps, isSelected, onSelect, onChange }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  React.useEffect(() => {
    if (isSelected && editMode) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
    shapeRef.current.getSelfRect = () => {
      const ret = { x: 0, y: 0, width: 40 , height: 40  };
      return ret;
    }
    // eslint-disable-next-line
  }, [isSelected]);
  return (
    <React.Fragment>
      <Konva.Shape
        sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(0, 0);
            context.lineTo(40, 40);
            context.moveTo(0, 40);
            context.lineTo(40, 0);
            context.closePath();
            context.fillStrokeShape(shape);
        }}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={editMode}
        {...shapeProps}
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={e => {
          // transformer is changing scale
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY,
            rotation: node.rotation()
          });
        }}
      />
      {isSelected && editMode && <Konva.Transformer ref={trRef} />}
    </React.Fragment>
  );
};
export default Cross;