import React from 'react';
import UI from 'components/UI';
import FileCell from 'components/main/items/cells/FileCell';

class Files extends UI {

  isSelectedToDel(deletedIcons, icon){
    var isSelectedDel = false;
    deletedIcons.map((deletedIcon, i) =>{
      if(deletedIcon === icon){isSelectedDel = true;}
      return null;
    })
    return isSelectedDel;
  }

  render(){
    this.init(this.props);
    if(!this.props.data){ return null;}
    const data = this.props.data.slice(0);

    const fileStyle = {
      width: '95%',
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexFlow: 'row wrap',
      alignContent: 'flex-start',
      paddingLeft: '2%'
    }

    const fileContainerStyle = {...this.ui.styles.container, ...{
      width: this.bs.height * 0.275,
      height: this.bs.height * 0.375
    }}

    var chooseIconToDel = null;
    const view = this.store.content.view;

    if(view === 'projectIcons'){
      chooseIconToDel = this.store.projects.deleteIconAction;
    }
    else if(view === 'subjectIcons'){
      chooseIconToDel = this.store.subjects.deleteIconAction;
    }

    return(
      <div style={fileStyle}>
        {data.map((file,i)=>{
          if(file === 'add' && !this.store.projects.viewingProject.mlanghku){
            return(
              <div key={i} style={fileContainerStyle}>
                {this.props.onAdd && this.props.onAdd()}
              </div>
            )
          }

          var isSelectedDel = false;
          if(view === 'projectIcons'){
            if(this.store.projects.deletedIcons.length > 0){
              isSelectedDel = this.isSelectedToDel(this.store.projects.deletedIcons, file.filename);
            }
          }
          else if(view === 'subjectIcons'){
            if(this.store.subjects.deletedIcons.length > 0){
              isSelectedDel = this.isSelectedToDel(this.store.subjects.deletedIcons, file.filename);
            }
          }

          return(
            <div key={i} style={fileContainerStyle}>
            <FileCell app={this.app} icon={file.icon?file.icon:null} data={{icon: file.filename?file.filename:null}} title={file.title}
              type={file.type} isSelectedDel={isSelectedDel}
              onClick={() => {
                if(chooseIconToDel){
                  if(view === 'projectIcons'){
                    if(isSelectedDel){ this.actions.projects.removeDeletedIcon(file.filename); }
                    else{ this.actions.projects.pushDeletedIcon(file.filename); }
                  }
                  else if(view === 'subjectIcons'){
                    if(isSelectedDel){ this.actions.subjects.removeDeletedIcon(file.filename); }
                    else{ this.actions.subjects.pushDeletedIcon(file.filename); }
                  }
                }
                else{ file.onClick(); }
              }} />
            </div>
          )
        })}
      </div>
    )
  }

}
export default Files;
