import React from 'react';
import Row from './Row';
import lamejs from 'lamejs';
import RecorderBar from 'components/audio/RecorderBar';

import icon_cross from 'resources/images/buttons/buttonIcons/cross_grey.png';

class LangEditRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.setStoredText();
  }

  async setStoredText(){
    if(this.props.editMode){ return; }
    const _text = await this.db.get('langText_' + this.props.index);
    if(_text){ this.actions.langs.setEditLang({index: this.props.index, editLang: {...this.props.editLang, text: _text}}); }
  }

  content = (style)=>{

    const audioBlob =
    this.props.editLang.audioBlob? this.props.editLang.audioBlob:
    this.url.blob;
    //const audioBlob = this.props.editLang.audioBlob;

    return (
      <div key={this.props.editLang.key} style={{...this.bs, ...{
        width: this.bs.width * 0.9,
        //height: style.height,
        height: style.height * 1.05,
        opacity: style.opacity,
        backgroundColor: 'white',
        borderBottom: '5px solid ' + this.ui.colors.ultraLightGrey,
        flexShrink: 0
      }}}>
        {this.langBar(this.props.index, this.props.editLang)}
        {<RecorderBar app={this.app} type={'langAudio'} scale={['97.5%', /*style.height * 0.35*/ '100%']}
          defaultAudio={this.props.editLang.defaultAudio} audioBlob={audioBlob}
          onStopRecording={this.onStopRecording.bind(this)} canRemove={false} page={"card"}
          recordingText={this.props.editLang.text}/>}
        {this.gap(style.height * 0.041)}
        {this.inputs.textArea('langText' + this.props.index, '', this.props.editLang.text, this.onTextChange.bind(this),  [this.bs.width * 0.87, this.bs.height * 0.1])}
        {this.gap('1%')}
      </div>
    )
  }

  langBar(i, lang){
    const barStyle = {...this.ui.styles.area, ...{
      width: this.bs.width * 0.9,
      //height: this.bs.height * 0.065,
      height: '50%',
      alignItems: 'center',
    }}

    /*const audioBlob =
    this.props.editLang.audioBlob? this.props.editLang.audioBlob:
    this.url.blob;*/

    //console.log(audioBlob)
    return(
      <div style={barStyle}>
        {this.verGap('1%')}
        {this.inputs.optionBar('langKey' + i, ['30%','65%'], this.langKeyOptions(), this.langKeyDefault(i), this.onOptionChange.bind(this))}
        {/*<RecorderBar app={this.app} type={'langAudio'} scale={['65%','100%']}
        defaultAudio={this.props.editLang.defaultAudio} audioBlob={audioBlob}
        onStopRecording={this.onStopRecording.bind(this)} canRemove={false}
        recordingText={this.props.editLang.text}/>*/}
        {this.verGap('62%')}
        {i > 0 && this.buttons.langBar(icon_cross, 0.1, [this.bs.width * 0.05,this.bs.width * 0.05],()=>{this.actions.langs.killEditLangsItem(i)})}
        {this.verGap('1%')}
      </div>
    )
  }

  encodeStereo(mp3Encoder, pcm){
    var leftChunk = pcm.filter(function (element, index, array) {
      return index % 2 === 0;
    });
    var rightChunk = pcm.filter(function (element, index, array) {
        return index % 2;
    });
    var mp3Buffer = mp3Encoder.encodeBuffer(leftChunk, rightChunk);
    return mp3Buffer;
  }

  compressHandler(channelNum, sampleRate, samples){
    var buffer = [];
    console.log("samples: ", samples)
    var mp3enc = new lamejs.Mp3Encoder(channelNum, sampleRate, 128);
    var remaining = samples.length;
    var maxSamples = 1152;
    if(channelNum === 1){
    for(var i=0; remaining >= maxSamples; i+= maxSamples){
        var mono = samples.subarray(i, i + maxSamples);
      // var mp3buf = this.encodeStereo(mp3enc, mono);
        var mp3buf =  mp3enc.encodeBuffer(mono); 
        if (mp3buf.length > 0) {
            buffer.push(mp3buf);
        }
        remaining -= maxSamples; 
      }

      var flushData = mp3enc.flush();
      if(flushData.length > 0){
        buffer.push(flushData);
      }

      var blob = new Blob(buffer, {type: 'audio/mpeg'});
      this.actions.modal.hideModal();
      this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
    }
    else if (channelNum === 2){
      let left = [], right = [], sampleBlockSize = 1152; 
      let mp3buf=[];
      for(let i = 0; i < samples.length; i += 2){
        left.push(samples[i]);
        right.push(samples[i + 1]);
      }
      let leftArray = new Int16Array(left), rightArray = new Int16Array(right);
      for (let i = 0; i < samples.length; i += sampleBlockSize) {
          let leftChunk = leftArray.subarray(i, i + sampleBlockSize);
          let rightChunk = rightArray.subarray(i, i + sampleBlockSize);
          mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk);
          if (mp3buf.length > 0) {
              buffer.push(mp3buf);
          }
      }
      flushData = mp3enc.flush();
      if(flushData.length > 0){
        buffer.push(flushData);
      }

      blob = new Blob(buffer, {type: 'audio/mpeg'});
      this.actions.modal.hideModal();
      this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
    }
    else{
      this.actions.modal.message(['Sorry , only mono audio and stereo are supported.', '抱歉，我們只接受單聲道和雙聲道音頻。' , '抱歉，我们只接受单声道和双声道音频。' ]);
      this.actions.modal.hideModal();
    }
  
  }
  wavToMP3(blob){
    const fr = new FileReader();
    fr.onload = async (event) => {
      try{
        const dataView = new DataView(fr.result);
        var wav = lamejs.WavHeader.readHeader(dataView);
        if(wav.dataLen === 0)
        {
          this.actions.modal.hideModal();
          this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
          return ;
        }
        var samples = new Int16Array(fr.result, wav.dataOffset, wav.dataLen / 2);
        this.compressHandler(wav.channels, wav.sampleRate, samples);
      }
      catch(error){
        console.log(error);
        this.actions.modal.hideModal();
        this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
        return ;
      }
    }
    fr.readAsArrayBuffer(blob);
  }


  onStopRecording(blob){
    const fileExtension = blob.type.split('/').pop();
    this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
    if(fileExtension === 'wav'){ 
      this.actions.modal.loadingMessage(['File is converting to mp3...', '檔案正在轉換為MP3...', '档案正在转换为MP3 ...']);
      setTimeout(() => {
        this.wavToMP3(blob); 
      }, 500);
    }
    else{
      this.actions.langs.setLangAudio({index: this.props.index, blob: blob});
    }
  }

  onOptionChange(event){
    //console.log(event.target.value)
    const langName = event.target.value;
    const _key = this.func.langNameToLangKey(langName);
    this.actions.langs.setEditLang({index: this.props.index, editLang: {...this.props.editLang, key: _key}});
  }

  onTextChange(event){
    const _text = event.target.value;
    this.actions.langs.setEditLang({index: this.props.index, editLang: {...this.props.editLang, text: _text}});
    this.db.set('langText_' + this.props.index, _text);
  }

  langKeyOptions(){
    var options = [];
    const langKeys = this.store.langs.langKeys;
    for(var i=langKeys.length - 1;i>=0;i--){
      if(langKeys[i].use){
        options.splice(0,0,this.func.langKeyToLangName(langKeys[i].key));
      }
    }
    return options;
  }

  langKeyDefault(i){
    return this.func.langKeyToLangName(this.store.langs.editLangs[i].key);
  }

  render() {
    this.init(this.props);
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.285, this.props.editLang.killed? ()=>{this.actions.langs.removeEditLangsItem(this.props.index)}: null)
  }

}

export default LangEditRow;
