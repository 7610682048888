import React from 'react';
import View from 'components/main/pages/home/views/View';
import ImagePicker from 'components/main/items/ImagePicker';

class AddIcon extends View {

  componentWillUnmount(){
    this.actions.main.setPhotoAttr(0);
  }

  render() {
    this.init(this.props);

    return(
      <div style={this.viewStyle()}>
        {this.gap('4%')}

        {this.subTitle(['Icon','照片','照片'])}
        {this.sep()}
        <ImagePicker type={'card'} defaultUrl={this.state.url} app={this.app}/>
        {this.sep()}
        {this.gap('2%')}

        {this.buttons.rectGreen(['Submit','提交','提交'], ()=>{this.addIcon()})}
        {this.gap('8%')}
      </div>
    )
  }

  addIcon(){
    const editMode = this.props.editMode;
    const icon = this.store.main.photoBlob;
    const iconAttr = this.store.main.photoAttr;
    const view = this.store.content.view;

    if(!editMode && icon === null){
      return this.failedMessage(['Failed to submit! Icon is missing!', '提交失敗! 未有照片!','提交失败! 未有照片!'])
    }

    if(view === 'addProjectIcon'){
      if(!editMode){
        this.actions.projects.addDefaultIcon({
          project: this.store.projects.viewingProject,
          icon: icon,
          iconAttr: {rotation: iconAttr.rotation},
        });
      }
    }
    else if(view === 'addSubjectIcon'){
      if(!editMode){
        this.actions.subjects.addDefaultIcon({
          subject: this.store.subjects.viewingSubject,
          icon: icon,
          iconAttr: {rotation: iconAttr.rotation},
        });
      }
    }
  }

}

export default AddIcon;
