import React from 'react';
import Graph from './Graph';
import {Pie} from 'react-chartjs-2';

class ReactPieChart extends Graph {

  datasetKeyProvider(){ return Math.random(); }

  createGraphDataSet(_data){
    var dataSet = [], labels;

    if(_data.type === 'matchGame'){
      labels = [this.store.main.language === 'english'?'correct':'答對', this.store.main.language === 'english'?'incorrect':'答錯'];

      dataSet[0] = {
        backgroundColor: ['rgba(145,195,59,1)', 'rgba(20,138,99,1)'],
        hoverBackgroundColor: ['rgba(145,195,59,0.4)', 'rgba(20,138,99,0.4)'],
        data: [_data.correct, _data.incorrect]
      }
    }

    const data = {
        labels:  labels,
        datasets: dataSet
    };

    return data;
  }

  render(){
    this.init(this.props);

    //console.log(this.props.data);

    if(!this.props.data){ return null; }

    var data = this.createGraphDataSet(this.props.data);
    var options = {
      maintainAspectRatio: false,
    }

    return(
      <div>
        {data ?
          <Pie data={data} datasetKeyProvider={this.datasetKeyProvider}  width={this.props.width} height={this.props.height} options={options} />:
          null
        }
      </div>
    )
  }

}

export default ReactPieChart;
