import React from 'react';
import View from 'components/main/pages/home/views/View';

import SubNav from 'components/main/items/SubNav';
import MatchGameStart from './subviews/MatchGameStart';
import MatchGamePlay from './subviews/MatchGamePlay';
import MatchGameResult from './subviews/MatchGameResult';
import MatchRanking from './subviews/MatchRanking';
import MatchGameDetail from './subviews/MatchGameDetail';

class MatchGame extends View {

  componentDidMount(){
    this.init(this.props);
    const subView = this.store.content.subView;

    if(subView.includes('matchRank')){
      this.actions.content.setSubView('matchRank');
    }
    else{
      this.actions.content.setSubView('matchGameDetail');
    }
  }

  subView(subView, animatedStyle){
    const app = {...this.app, ...{ animatedStyle: animatedStyle}}
    switch (subView) {

      case 'matchGameStart':
        return <MatchGameStart app={app}/>;
      case 'matchGamePlay':
        return <MatchGamePlay app={app}/>;
      case 'matchGameResult':
        return <MatchGameResult app={app}/>;
      case 'matchRank':
        return <MatchRanking app={app}/>;
      case 'matchGameDetail':
        return <MatchGameDetail app={app}/>;

      default:
        return null;
    }
  }

  matchGameSubNav(){
    const options = [
      {
        tag:['Game','遊戲','游戏'],
        subView: 'matchGameStart'
      },
      {
        tag:['Ranking','排行榜','排行榜'],
        subView: 'matchRank'
      },
      {
        tag:['Detail','詳細資訊','详细资讯'],
        subView: 'matchGameDetail'
      },
    ]
    return <SubNav app={this.app} options={options} />
  }

  render(){
    this.init(this.props);
    const deadView = this.state.deadView;
    const view = this.state.view;
    return(
      <div style={this.viewStyle()}>
        {this.tabBar(['Match game', '配對遊戲', '配对游戏'])}
        {this.matchGameSubNav()}
        {this.sep()}
        {this.animatedSubView(this.subView.bind(this), deadView? deadView: view, deadView? false: true)}
      </div>
    )
  }

}

export default MatchGame;
