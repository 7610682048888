import axios from 'axios';
import * as actions from '../actions';
import to from '../to';
var api = actions.api();

export const setSetting = (setting) =>{
  return {
    type: 'setSetting',
    payload: setting
  }
}


export function get(){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;

    [err, res] = await to(axios.post(api + '/setting/get', {}));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      dispatch({type: 'hideModal'})
      dispatch({type: 'setSetting', payload: res.data.setting});
    }else{
      dispatch({type: 'hideModal'})
    }
  }
}


export function update(data){
  return async function (dispatch) {
    actions.connecting(dispatch);

    let err, res;

    [err, res] = await to(axios.post(api + '/setting/update', {data: data}));
    if(err){actions.connectionError(dispatch); return;}

    if(res.data.result === 'success'){
      actions.updateSuccess(dispatch);
      dispatch({type: 'setSetting', payload: res.data.updatedSetting});
    }else{
      actions.updateFailed(dispatch);
    }
  }
}

