import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';

import MatchGameRow from 'components/main/items/rows/MatchGameRow';

class MemoryGameList extends SubView {

  componentDidMount(){
    this.init(this.props);
    this.setListScroll('memoryGameList');
  }

  getCards(projectsId){
    var cardsToGet = [], cardsToShow = [];

    for(var i=0; i<projectsId.length; i++){
      const project = this.func.getById.project(projectsId[i], this.store);
      if(!project){ continue; }

      for(var j=0; j<project.studentProjects.length; j++){
        const studentProject = this.func.getById.studentProject(project.studentProjects[j], this.store);
        if(!studentProject){ continue; }
        
        cardsToShow = [...cardsToShow, ...studentProject.cards];
      }
    }

    for(var k=0; k<cardsToShow.length; k++){
      if(this.func.getById.card(cardsToShow[k], this.store) === null){
        cardsToGet.splice(0,0, cardsToShow[k]);
      }
    }
    if(cardsToGet.length > 0){
      this.actions.cards.getCards(cardsToGet);
    }
  }

  findSubmitByPublish(submits, publish){
    var submit = {};
    for(var i=0; i<submits.length; i++){
      if(submits[i].memoryGamePublish === publish._id){ submit = submits[i]; break; }
    }
    return submit;
  }

  findBattleSubmitByPublish(battleSubmits, publish){
    var battleSubmit = {};
    for(var i=0; i<battleSubmits.length; i++){
      if(battleSubmits[i].memoryGamePublish === publish._id){ battleSubmit = battleSubmits[i]; break; }
    }
    return battleSubmit;
  }


  memoryGameList(){
    const memoryGames = this.store.memoryGames.memoryGamePublishes;
    const user = this.store.user;
    if(memoryGames.length === 0){
      return this.subTitle(['No Match Games','尚未有配對遊戲','尚未有配对游戏'])
    }
    
    return memoryGames.slice(0).reverse().map((memoryGame, i)=>{
      if(!memoryGame){ return null; }
      if(user.type === 'student' && memoryGame.hide){ return null; }
      return <MatchGameRow 
      onClick={()=>{
        this.actions.memoryGames.viewMemoryGamePublish(memoryGame); 
        this.actions.memoryGames.viewMemoryGameSubmit(this.findSubmitByPublish(this.store.memoryGames.memoryGameSubmits, memoryGame));
        this.actions.memoryGames.viewMemoryGameBattleSubmit(this.findBattleSubmitByPublish(this.store.memoryGames.memoryGameBattleSubmits, memoryGame)); 
        this.actions.content.pushView('memoryGame');
        this.getCards(memoryGame.projects);
      }} 
      app={this.app} matchGame={memoryGame} key={i}/>
    })
  }

  render() {
    this.init(this.props);
    return(
      <div style={this.subViewStyle()}>
        <div id={'matchGameList'} onScroll={()=>{ this.onScroll('memoryGameList'); }} style={{...this.bs, ...this.ui.styles.list}}>
          {this.memoryGameList()}
        </div>
      </div>
    )
  }

}

export default MemoryGameList;
