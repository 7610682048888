const agreementReducer = (
  state = {
    _id : "5b44750c80463c4de838bea9",
    belogTo: "5b44750c80463c4de838bef9",
    term: "I shall be responsible for the content I created on this platform.The data I provided on this application, including my name and e-mail, are correct.I agree to let m-Chinese Solution Limited use my data and the contents I created for analytic and promotion purposes.  I shall inform m-Chinese Solution Limited if I do not want to give such consent.",
    key: "english",
    createdAt : new Date("2018-07-10 08:57:27.687Z"),
    __v : 0
  }, action)=>{
  switch (action.type) {
    case 'setAgreement':
      return action.payload;
    default:
      return state;
  }
}

export default agreementReducer;
