
const allowedCommentReducer = (
  state = {
    editAllowedComments: [],
    allowedComments: [],
    viewingAllowedComments: {},
    createdAllowedComments: [],
    createdSubmits: [],
  }, action)=>{
  const newEditAllowedComments = state.editAllowedComments.slice(0);
  switch (action.type) {
    case 'viewAllowedComment':
      return {...state, viewAllowedComment: action.payload};
    case 'setEditAllowedComment':
      newEditAllowedComments[action.payload.index] = action.payload.editAllowedComment;
      //newEditQuestions[action.payload.index]['edited'] = true;
      return {...state, editAllowedComments: newEditAllowedComments};
    case 'setEditAllowedComments':
      return {...state, editAllowedComments: action.payload};
    case 'killEditAllowedCommentsItem':
      newEditAllowedComments[action.payload].killed = true;
      return {...state, editAllowedComments: newEditAllowedComments};
    case 'removeEditAllowedCommentsItem':
      newEditAllowedComments.splice(action.payload,1);
      return {...state, editAllowedComments: newEditAllowedComments};
    case 'pushEditAllowedComments':
      return {...state, editAllowedComments: [...state.editAllowedComments, action.payload]};
    default:
      return state;
  }
}

export default allowedCommentReducer;
