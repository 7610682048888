import React from 'react';
import View from 'components/main/pages/home/views/View';
import MultipleSelect from 'components/main/items/ui/MultipleSelect';
import BasicTextField from 'components/main/items/ui/BasicTextField';
import MaterialButton from 'components/main/items/ui/MaterialButton';
import * as TagTranslator from 'components/main/pages/home/helpers/TagTranslator.js';

class AddTag extends View {

    constructor(props){
        super(props);
        this.init(props);
        const editMode = this.props.editMode;
        this.viewingSchoolTag = this.store.schoolTags.viewingSchoolTag;
        this.state = {
            title: editMode? this.viewingSchoolTag.title: '',
            selectedSchools: editMode? this.getSelectedSchools(): [],
            courseTagSelection: editMode? this.viewingSchoolTag.courseTags: [],
            subjectTagSelection: editMode? this.viewingSchoolTag.subjectTags: [],
            projectTagSelection: editMode? this.viewingSchoolTag.projectTags: [],
        }
    }

    getSelectedSchools(){
        const schools = this.store.schools.allSchools;
        var defaultValue = [];
        for(var i=0; i<schools.length; i++){
            for(var j=0; j<this.viewingSchoolTag.schools.length; j++){
                if(this.viewingSchoolTag.schools[j] === schools[i]._id){ defaultValue.push(schools[i].name + ' - ' + schools[i].code); }
            }
        }
        return defaultValue;
    }
    /*
    handleSchoolSelectionChange(e){
        this.setState({selectedSchools: e.target.value});
    }
    */
    handleAddTagSelection(e, type){
        const text = document.getElementById('textFiled-tag-' + type).value;
        if(type === 'subject'){ this.setState({subjectTagSelection: [...this.state.subjectTagSelection, text]}); }
        else if(type === 'project'){ this.setState({projectTagSelection: [...this.state.projectTagSelection, text]}); }
    }

    handleTagSelectionChange(e, type){
        if(type === 'school'){ this.setState({selectedSchools: e.target.value}); }
        else if(type === 'course'){ this.setState({courseTagSelection: e.target.value}); }
        else if(type === 'subject'){ this.setState({subjectTagSelection: e.target.value}); }
        else if(type === 'project'){ this.setState({projectTagSelection: e.target.value}); }
    }

    setTitle(){
        return(
            <BasicTextField app={this.app} type={'title'} defaultValue={this.state.title}/>
        )
     }

    schoolsSelection(type){
        const schools = this.store.schools.allSchools;
        var choices = [];
        for(var i=0; i<schools.length; i++){ choices.push(schools[i].name + ' - ' + schools[i].code); }
        return(
            <MultipleSelect app={this.app} choices={choices} defaultTag={this.state.selectedSchools} openStatus={null} onChange={(e)=>this.handleTagSelectionChange(e, type)} type={'school'}/>
        )
    }

    courseTagsSelection(type){
        const tagsChi = ['中文', '英文', '數學', '常識'];
        const tagsSimChi = ['中文', '英文', '数学', '常识'];
        const tagsEng = ['Chinese', 'English', 'Mathematics', 'General Studies'];
        const choices = this.store.main.language === 'chinese'? tagsChi: this.store.main.language === 'simplified_chinese'? tagsSimChi: tagsEng;
        var defaultTag = this.state.courseTagSelection?this.state.courseTagSelection:[];
        defaultTag = TagTranslator.convert(defaultTag, 'english', this.store.main.language);
        return(
            <MultipleSelect app={this.app} choices={choices} defaultTag={defaultTag} openStatus={null} onChange={(e)=>this.handleTagSelectionChange(e, type)} type={'course'}/>
        )
    }

    addTagSelection(type, choices){
        const container = {
            display: 'flex',
            flex: '1 0 auto',
            flexDirection: 'column',
        }
        const top = {
            display: 'flex',
            flexDirection: 'row',
        }
        const bottom = {
            display: 'flex',
            justifyContent: 'center'
        }
        return(
            <div style={container}> 
                <div style={top}>
                    {<BasicTextField app={this.app} type={'tag-'+type}/>}
                    {<MaterialButton app={this.app} name={'Add'} icon={'Add'} type={type} onClick={(e)=>this.handleAddTagSelection(e, type)}/>}
                </div>
                <div style={bottom}>
                    {this.tagsSelection(type, choices)}
                </div>
            </div>
        )
    }

    tagsSelection(type, choices){
        return(
            <div>
                <MultipleSelect app={this.app} choices={choices} defaultTag={choices} openStatus={null} onChange={(e)=>{this.handleTagSelectionChange(e, type);}} type={'tag-' + type}/>
            </div>
        )
    }


    render() {
        this.init(this.props);

        const subjectTagChoices = this.state.subjectTagSelection;
        const projectTagChoices = this.state.projectTagSelection;

        return(
        <div style={this.viewStyle()}>
            {this.gap('4%')}

            {this.subTitle(['Title','標題','标题'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.setTitle()}
            {this.gap('4%')}

            {this.subTitle(['Select schools for adding tags (multiple selection)','選擇學校以添加標籤（多選）','选择学校以添加标签（多选）'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.schoolsSelection('school')}
            {this.gap('4%')}

            {this.subTitle(['Select course tags (multiple selection)','選擇班別標籤（多選）','选择班别标签（多选）'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.courseTagsSelection('course')}
            {this.gap('4%')}

            {this.subTitle(['Add subject tags (multiple selection)','添加單元標籤（多選）','添加單元标签（多选）'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.addTagSelection('subject', subjectTagChoices)}
            {this.gap('0.5%')}

            {this.subTitle(['Add project tags (multiple selection)','添加專題研習標籤（多選）','添加专题研习标签（多选）'])}
            {this.sep()}
            {this.gap('0.5%')}
            {this.addTagSelection('project', projectTagChoices)}
            {this.gap('0.5%')}

            {this.buttons.rectGreen(['Confirm','確定','确定'], ()=>{this.addTag()})}
            {this.gap('3%')}
        </div>
        )
    }

    splitToCode(selectedSchool){
        return selectedSchool.split(" - ")[1];
    }

    codeToId(code){
        const schools = this.store.schools.allSchools;
        for(var i=0; i<schools.length; i++){
            if(code === schools[i].code){ return schools[i]._id; }
        }
        return null;
    }

    addTag(){
        const editMode = this.props.editMode;
        const title = document.getElementById('textFiled-title').value;
        const selectedSchools = this.state.selectedSchools;
        const courseTagSelection = TagTranslator.convert(this.state.courseTagSelection, this.store.main.language, 'english');
        const subjectTagSelection = this.state.subjectTagSelection;
        const projectTagSelection = this.state.projectTagSelection;
        var selectedSchoolsId = [];
        
        if(!title){
            return this.failedMessage(['Failed to submit! Need to have title !', '提交失敗! 需要填寫標題 !','提交失败! 需要填写标题 !']);
        }
        if(selectedSchools.length <= 0){
            return this.failedMessage(['Failed to submit! Need to select school !', '提交失敗! 需要選擇學校 !','提交失败! 需要选择学校!']);
        }
        if(courseTagSelection.length <= 0){
            return this.failedMessage(['Failed to submit! Need to select course tag !', '提交失敗! 需要選擇班別標籤 !','提交失败! 需要选择班别标籤!']);  
        }
        if(subjectTagSelection.length <= 0){
            return this.failedMessage(['Failed to submit! Need to add subject tag !', '提交失敗! 需要添加單元標籤 !','提交失败! 需要添加单元标签!']);
        }
        if(projectTagSelection.length <= 0){
            return this.failedMessage(['Failed to submit! Need to add project tag !', '提交失敗! 需要添加專題研習標籤 !','提交失败! 需要添加专题研习标签!']);
        }

        for(var i=0; i<selectedSchools.length; i++){
            const code = this.splitToCode(selectedSchools[i]);
            const id = this.codeToId(code);
            if(id){ selectedSchoolsId.push(id); }
        }

        if(!editMode){
            this.actions.schoolTags.add({
                schools: selectedSchoolsId,
                courseTags: courseTagSelection,
                subjectTags: subjectTagSelection,
                projectTags: projectTagSelection,
                title: title
             });
        }
        else{
            this.actions.schoolTags.edit({
                viewingSchoolTag: this.viewingSchoolTag,
                schools: selectedSchoolsId,
                courseTags: courseTagSelection,
                subjectTags: subjectTagSelection,
                projectTags: projectTagSelection,
                title: title 
            })
        }

    }

}

export default AddTag;
