import React from 'react';
import SubView from 'components/main/pages/home/views/SubView';


class MatchGameStart extends SubView {

  constructor(props){
    super(props);
    this.init(props);
    this.matchGame = this.store.matchGames.viewingMatchGamePublish;
  }

  board(){
    const outerStyle = {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
    const innerStyle = {
      width: this.bs.width * 0.25,
      height:  this.bs.height * 0.2,
      marginBottom: '10%',
      borderRadius: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      backgroundColor: 'rgba(145, 195, 59, 0.8)',
    }

    return(
      <div style={outerStyle}>
        <div style={innerStyle}>
          {this.title()}
          {this.buttonArea()}
        </div>
      </div>
    )
  }

  title(){
    const style = {
      width: '100%',
      height: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '120%',
      color: 'white',
      fontWeight: 'bold',
    }

    return (
      <div style={style}>
        {this.func.multiLang('Select mode', '選擇模式', '选择模式')}
      </div>
    )
  }

  buttonArea(){
    const areaStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 1
    }

    return (
      <div style={areaStyle}>
        {this.button(['Practice','練習','练习'], ()=>{ this.matchGamePlay('practice', 4) })}
        {this.button(['Test','測驗','测验'], ()=>{ this.matchGamePlay('test', this.store.matchGames.viewingMatchGamePublish.size) })}
      </div>
    )
  }

  changeBackground(e){
    e.target.style.background = 'rgba(255, 255, 255, 0.4)';
  }

  removeBackground(e){
    e.target.style.background = '';
  }


  button(text, onClick){
    const style = {
      width: '100%',
      height: '100%',
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      fontSize: '110%',
      color: 'white',
    }
    return(
      <div style={style} onMouseOver={(e)=>this.changeBackground(e)} onMouseLeave={(e)=>this.removeBackground(e)} onClick={onClick}>
        {this.func.multiLang(text[0], text[1], text[2])}
      </div>
    )
  }


  matchGamePlay(mode, size){
    const outDated = this.func.outDated(this.matchGame.endDate);
    if(outDated){ this.actions.modal.message(['The game is closed!', '遊戲已經關閉!', '游戏已经关闭!']); return; }

    if(mode === 'practice'){
      const cards = this.getCardsByProjects(this.store.matchGames.viewingMatchGamePublish.projects);
      if(cards.length < size){ this.actions.modal.message(['No enough featured cards!!! ', '沒有足夠數量的精選卡片!!! ', '没有足够数量的精选卡片!!! ']) }
      else{
        const featuredCards = this.getFeaturedCards(cards);
        const randomCards = this.randomCards(featuredCards, size);
        const viewingCards = this.addFinalTag(randomCards);
        this.actions.matchGames.viewMatchGameCards(viewingCards);
        this.actions.matchGames.viewMatchGameCard(viewingCards[0]);
        this.pushRandomLangs(featuredCards, randomCards);
        this.initAnswers(size);
        this.actions.matchGames.setMatchGameMode(mode);
        this.actions.content.setSubView('matchGamePlay'); 
      }
    }
    else if(mode === 'test'){
      const cards = this.getCardsByProjects(this.store.matchGames.viewingMatchGamePublish.projects);
      if(cards.length < size){ this.actions.modal.message(['No enough featured cards!!! ', '沒有足夠數量的精選卡片!!! ', '没有足够数量的精选卡片!!! ']) }
      const featuredCards = this.getFeaturedCards(cards);
      const randomCards = this.randomCards(featuredCards, size);
      const viewingCards = this.addFinalTag(randomCards);
      this.actions.matchGames.viewMatchGameCards(viewingCards);
      this.actions.matchGames.viewMatchGameCard(viewingCards[0]);
      this.pushRandomLangs(featuredCards, randomCards);
      this.initAnswers(size);
      this.actions.matchGames.setMatchGameMode(mode);
      this.actions.content.setSubView('matchGamePlay');
    }
  }

  /*
  getUserCards(cards, id){
    var userCards = [];
    for(var i=0;i < cards.length;i++){
      if(cards[i].author === id){ userCards.push(cards[i]); }
    }
    return userCards;
  }
  */

  getCardsByProjects(projects){
    var studentProjects = [], cardsId = [], cards = [];
    for(var i=0;i < projects.length;i++){
      const project = this.func.getById.project(projects[i], this.store);
      studentProjects = [...studentProjects, ...project.studentProjects];
    }
    for(var j=0;j < studentProjects.length;j++){
      const studentProject = this.func.getById.studentProject(studentProjects[j], this.store);
      cardsId = [...cardsId, ...studentProject.cards];
    }
    for(var k=0;k < cardsId.length;k++){
      const card = this.func.getById.card(cardsId[k], this.store);
      cards.push(card);
    }
    return cards;
  }

  getFeaturedCards(cards){
    var featuredCards = [];
    for(var i=0;i < cards.length;i++){
      if(cards[i].grade === 'featured'){ featuredCards.push(cards[i]); }
    }
    return featuredCards;
  }

  randomCards(cards, max){
    var randomCards = [];
    var numbers = [];
    if(cards.length < max){ max = cards.length; }
    while(max > 0){
      const rand = Math.floor(Math.random() * cards.length);
      if(!numbers.includes(rand)){
        numbers.push(rand);
        randomCards.push(cards[rand]);
        max -= 1;
      }
    }
    return randomCards;
  }

  addFinalTag(cards){
    const newCards = [...cards];
    newCards.push('submit');
    return newCards;
  }

  randomChoices(cards, selectedCard, max){
    var choices = [];
    var numbers = [];
    while(numbers.length < max){
      var rand = Math.floor(Math.random() * cards.length);
      if(!numbers.includes(rand) && selectedCard._id !== cards[rand]._id){
        numbers.push(rand);
        choices.push(cards[rand]);
      }
    }
    return choices;
  }

  pushRandomLangs(userCards, randomCards){
    if(this.store.matchGames.langChoices.length > 0){ this.actions.matchGames.setLangChoices([]);}
    for(var i=0;i<randomCards.length;i++){
      var choices = this.randomChoices(userCards, randomCards[i], 3);
      var cards = [...choices, randomCards[i]];
      var _langs = [], numbers = [];
      var max = cards.length;

      while(max > 0){
        const randForCard = Math.floor(Math.random() * cards.length);
        const card = cards[randForCard];
        const randForLang = Math.floor(Math.random() * card.langs.length);
        const lang = card.langs[randForLang];

        if(!numbers.includes(randForCard)){
          numbers.push(randForCard);
          _langs.push(lang);
          max -= 1;
        }
      }
      this.actions.matchGames.pushLangChoices(_langs);
    }
  }

  initAnswers(max){
    var answers = [];
    for(var i=0;i<max;i++){ answers[i] = {audio: null, text: null}; }
    this.actions.matchGames.initAnswers(answers);
  }


  render() {
    this.init(this.props);
    
    return(
      <div style={this.subViewStyle()}>
        {this.board()}
      </div>
    )
  }

}

export default MatchGameStart;
