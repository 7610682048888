import React from 'react';
import Row from './Row';


class StudentInfoRow extends Row {

  constructor(props){
    super(props);
    this.init(props);
    this.handleCheck = this.props.handleCheck;
    this.handleLeaderCheck = this.props.handleLeaderCheck;
    this.checked = false;
    this.creatingGroup = this.props.creatingGroup;
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.init(newProps);
    if(newProps.profile && !this.url.url){
      this.setState({
        filename: newProps.profile.icon,
        type: 'profileIcon'
      })
    }
    this.checkUrl();
  }

  rowInfo(){

    const rowStyle = {...this.ui.styles.area, ...{
      width: '100%',
      height: this.bs.height * 0.06,
      alignItems: 'center'
    }}
    return(
      <div style={rowStyle}>

      </div>
    )
  }

  render(){
    const profile = this.props.profile;
    if(profile === null){ return null; }
    if(profile.name === ''){ return null; }
    return this.animatedRow(this.content.bind(this), this.bs.height * 0.15)
  }

  content = (style)=>{
    const language = this.store.main.language;
    const name = this.props.profile? 
    language === 'english' && this.props.profile.lastNameEng && this.props.profile.firstNameEng? this.props.profile.lastNameEng + ' ' + this.props.profile.firstNameEng + ' (' + this.props.profile.name + ') ':
    (language === 'chinese' || language === 'simplified_chinese') && this.props.profile.lastNameChi && this.props.profile.firstNameChi? this.props.profile.lastNameChi + ' ' + this.props.profile.firstNameChi + ' (' + this.props.profile.name + ') ':
    this.props.profile.name:
    '';

    return(
      <button key={this.url.url} onClick={this.props.onClick} style={{...this.rowStyle(), ...{
        height: style.height,
        opacity: style.opacity
      }}}>
        {this.verGap('3%')}
        { this.rowIcon()}
        {this.rowContent(name,  this.rowInfo.bind(this))}
        {this.creatingGroup && 
          <label style={{fontSize: "1.3em"}}> 
          {this.func.multiLang('Member', '組員', '组员')}
          <input id={"membercheckbox" + this.props.student} type="checkbox" style={{height: "auto", transform: "scale(2.3)", marginTop: "30%"}} onChange={() => this.handleCheck(this.props.student, this.creatingGroup)}/>
          </label>
        }
         {!this.creatingGroup && 
          <input id={"membercheckbox" + this.props.student} type="checkbox" style={{height: "auto", transform: "scale(2.3)"}} onChange={() => this.handleCheck(this.props.student, this.creatingGroup)}/>
        }
          {this.verGap('10%')}
        {this.creatingGroup && 
          <label style={{fontSize: "1.3em"}}>
          {this.func.multiLang('Leader', '組長', '组长')}
          <input id={"leadercheckbox" + this.props.student} type="checkbox" disabled={false} style={{height: "auto", transform: "scale(2.3)", marginTop: "30%"}} onChange={() => this.handleLeaderCheck(this.props.student )}/>
          </label>
        }
      </button>
    )
  }
}

export default StudentInfoRow;