import React from 'react';
import View from 'components/main/pages/home/views/View';
import Files from 'components/main/pages/home/views/home/contents/Files';
import icon_tag from 'resources/images/icons/tag.png';

class ImportTags extends View {

    schoolTagCell(){
        const schoolTags = this.store.schoolTags.schoolTags;
        var data = [];
        for(let i=0;i < schoolTags.length;i++){
            data.push({
                icon: icon_tag,
                title: schoolTags[i].title,
                type: '',
                onClick: async ()=>{ this.actions.content.pushView('editTag'); this.actions.schoolTags.viewSchoolTag(schoolTags[i]); }
            });
        }
        data.push('add');
        return <Files app={this.app} data={data} onAdd={this.onAdd.bind(this)}/>
    }

    onAdd(){
        return this.buttons.cellAdd(()=>{ this.actions.content.pushView('addTag'); })
    }


    render() {
        this.init(this.props);
        return(
        <div style={this.viewStyle()}>
            {this.schoolTagCell()}
        </div>
        )
    }

}

export default ImportTags;
